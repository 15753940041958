import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Progress,
  Space,
  Button,
  Breadcrumb,
} from "antd";
import { PlusOutlined, TableOutlined } from "@ant-design/icons";
import api from "../../util/api";
import Box from "@mui/material/Box";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";
const { Title, Text } = Typography;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const rederColRight = (key, keypage, textAlign = "right") => {
  return (_, data) => {
    return {
      props: {
        style: { textAlign: "right" },
      },
      children: (
        <a href={`/research/table/${data.key}/${keypage}/${data.year}`}>
          {numberWithCommas(data[key])}
        </a>
      ),
    };
  };
};

const MAP_TABLE = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
};

const MAP_GROUP = {
  คณะพยาบาลศาสตร์: "all",
  กลุ่มวิชาการพยาบาลมารดาและทารก: "mother_and_infant",
  กลุ่มวิชาการพยาบาลเด็ก: "child",
  กลุ่มวิชาการพยาบาลผู้ใหญ่: "adult",
  กลุ่มวิชาการพยาบาลผู้สูงอายุ: "geriatric",
  กลุ่มวิชาการพยาบาลชุมชน: "community",
  กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช: "psychiatric",
  กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ: "administartion_professional",
};

const onCreateDataTable = (res, year) => {
  let result = {};

  Object.keys(MAP_TABLE).map((x) => {
    result[MAP_TABLE[x]] = {
      key: x,
      year: year,
      name: MAP_TABLE[x],
      income_budget: 0,
      national_budget: 0,
      external_budget: 0,
      personal_budget: 0,
      sum_budget: 0,

      income_amount: 0,
      national_amount: 0,
      external_amount: 0,
      personal_amount: 0,
      sum_amount: 0,
    };
  });

  let sum = {
    key: "all",
    year: year,
    name: "รวม",
    income_budget: 0,
    national_budget: 0,
    external_budget: 0,
    personal_budget: 0,
    sum_budget: 0,
    income_amount: 0,
    national_amount: 0,
    external_amount: 0,
    personal_amount: 0,
    sum_amount: 0,
  };

  console.log("res", res);
  res.forEach((element) => {
    // year
    if (result[element?._id?.group]) {
      result[element?._id?.group].income_budget = element.income_budget;
      result[element?._id?.group].national_budget = element.national_budget;
      result[element?._id?.group].external_budget = element.external_budget;
      result[element?._id?.group].personal_budget = element.personal_budget;
      result[element?._id?.group].sum_budget =
        element.income_budget +
        element.national_budget +
        element.external_budget +
        element.personal_budget;

      result[element?._id?.group].income_amount = element.income_amount;
      result[element?._id?.group].national_amount = element.national_amount;
      result[element?._id?.group].external_amount = element.external_amount;
      result[element?._id?.group].personal_amount = element.personal_amount;
      result[element?._id?.group].sum_amount =
        element.income_amount +
        element.national_amount +
        element.external_amount +
        element.personal_amount;

      sum.income_budget += element.income_budget;
      sum.national_budget += element.national_budget;
      sum.external_budget += element.external_budget;
      sum.personal_budget += element.personal_budget;
      sum.sum_budget =
        sum.income_budget +
        sum.national_budget +
        sum.external_budget +
        sum.personal_budget;

      sum.income_amount += element.income_amount;
      sum.national_amount += element.national_amount;
      sum.external_amount += element.external_amount;
      sum.personal_amount += element.personal_amount;
      sum.sum_amount =
        sum.income_amount +
        sum.national_amount +
        sum.external_amount +
        sum.personal_amount;
    }
  });
  let results = Object.keys(result).map((x) => result[x]);
  results.push(sum);
  console.log("results", results);
  return results;
};

const onCreateChartBudgetType = (data) => {
  let external_budget = [0, 0, 0];
  let income_budget = [0, 0, 0];
  let national_budget = [0, 0, 0];
  let personal_budget = [0, 0, 0];

  data.forEach((x) => {
    let index = null;
    if (x._id.year == "2566") index = 0;
    if (x._id.year == "2565") index = 1;
    if (x._id.year == "2564") index = 2;

    if ([0, 1, 2].includes(index)) {
      external_budget[index] += x.external_budget;
      income_budget[index] += x.income_budget;
      national_budget[index] += x.national_budget;
      personal_budget[index] += x.personal_budget;
    }
  });

  return [
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2564",
      data: [
        income_budget[2],
        national_budget[2],
        external_budget[2],
        personal_budget[2],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2565",
      data: [
        income_budget[1],
        national_budget[1],
        external_budget[1],
        personal_budget[1],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2566",
      data: [
        income_budget[0],
        national_budget[0],
        external_budget[0],
        personal_budget[0],
      ],
    },
  ];
};

const onCreateChartAmountType = (data) => {
  let external_amount = [0, 0, 0];
  let income_amount = [0, 0, 0];
  let national_amount = [0, 0, 0];
  let personal_amount = [0, 0, 0];

  data.forEach((x) => {
    let index = null;
    if (x._id.year == "2566") index = 2;
    if (x._id.year == "2565") index = 1;
    if (x._id.year == "2564") index = 0;

    if ([0, 1, 2].includes(index)) {
      external_amount[index] += x.external_amount;
      income_amount[index] += x.income_amount;
      national_amount[index] += x.national_amount;
      personal_amount[index] += x.personal_amount;
    }
  });

  return [
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2564",
      data: [
        income_amount[0],
        national_amount[0],
        external_amount[0],
        personal_amount[0],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2565",
      data: [
        income_amount[1],
        national_amount[1],
        external_amount[1],
        personal_amount[1],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2566",
      data: [
        income_amount[2],
        national_amount[2],
        external_amount[2],
        personal_amount[2],
      ],
    },
  ];
};

const onCreateChartBudgetTypeByGroup = (data) => {
  let KEY_DAYA = {
    mother_and_infant: [0, 0, 0],
    child: [0, 0, 0],
    adult: [0, 0, 0],
    geriatric: [0, 0, 0],
    community: [0, 0, 0],
    psychiatric: [0, 0, 0],
    administartion_professional: [0, 0, 0],
  };

  data.forEach((x) => {
    let index = null;
    if (x?._id?.year == "2566") index = 2;
    if (x?._id?.year == "2565") index = 1;
    if (x?._id?.year == "2564") index = 0;
    console.log(x?._id?.group);
    console.log(MAP_GROUP[x?._id?.group]);
    if (MAP_GROUP[x?._id?.group]) {
      console.log(
        " KEY_DAYA[MAP_GROUP[x?._id?.group]]",
        KEY_DAYA[MAP_GROUP[x?._id?.group]]
      );
      KEY_DAYA[MAP_GROUP[x?._id?.group]][index] +=
        x.external_budget +
        x.income_budget +
        x.national_budget +
        x.personal_budget;
    }
  });

  return [
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลมารดาและทารก",
      // data: [1, 1, 1],
      data: KEY_DAYA.mother_and_infant,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลเด็ก",
      data: KEY_DAYA.child,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
      data: KEY_DAYA.adult,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
      data: KEY_DAYA.geriatric,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลชุมชน",
      data: KEY_DAYA.community,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
      data: KEY_DAYA.psychiatric,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
      data: KEY_DAYA.administartion_professional,
    },
  ];
};

const onCreateChartAmountTypeByGroup = (data) => {
  let KEY_DAYA = {
    mother_and_infant: [0, 0, 0],
    child: [0, 0, 0],
    adult: [0, 0, 0],
    geriatric: [0, 0, 0],
    community: [0, 0, 0],
    psychiatric: [0, 0, 0],
    administartion_professional: [0, 0, 0],
  };

  data.forEach((x) => {
    let index = null;
    if (x?._id?.year == "2566") index = 2;
    if (x?._id?.year == "2565") index = 1;
    if (x?._id?.year == "2564") index = 0;
    console.log(x?._id?.group);
    console.log(MAP_GROUP[x?._id?.group]);
    if (MAP_GROUP[x?._id?.group]) {
      console.log(
        " KEY_DAYA[MAP_GROUP[x?._id?.group]]",
        KEY_DAYA[MAP_GROUP[x?._id?.group]]
      );
      KEY_DAYA[MAP_GROUP[x?._id?.group]][index] +=
        x.external_amount +
        x.income_amount +
        x.national_amount +
        x.personal_amount;
    }
  });

  return [
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลมารดาและทารก",
      // data: [1, 1, 1],
      data: KEY_DAYA.mother_and_infant,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลเด็ก",
      data: KEY_DAYA.child,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
      data: KEY_DAYA.adult,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
      data: KEY_DAYA.geriatric,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลชุมชน",
      data: KEY_DAYA.community,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
      data: KEY_DAYA.psychiatric,
    },
    {
      showInLegend: true,
      name: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
      data: KEY_DAYA.administartion_professional,
    },
  ];
};

const fetchData = async () => {
  try {
    let aggregate = [
      // {
      //   $match: { year: `${year}` }
      // },
      {
        $project: {
          item: 1,
          year: 1,
          group: 1,

          income_budget: {
            $cond: {
              if: { $eq: ["$budget_source", "งบประมาณรายได้"] },
              then: "$total_budget_received",
              else: 0,
            },
          },
          national_budget: {
            $cond: {
              if: { $eq: ["$budget_source", "งบประมาณแผ่นดิน"] },
              then: "$total_budget_received",
              else: 0,
            },
          },
          external_budget: {
            $cond: {
              if: { $eq: ["$budget_source", "งบประมาณภายนอก"] },
              then: "$total_budget_received",
              else: 0,
            },
          },
          personal_budget: {
            $cond: {
              if: { $eq: ["$budget_source", "งบประมาณส่วนตัว"] },
              then: "$total_budget_received",
              else: 0,
            },
          },

          income_amount: {
            $cond: {
              if: { $eq: ["$budget_source", "งบประมาณรายได้"] },
              then: 1,
              else: 0,
            },
          },
          national_amount: {
            $cond: {
              if: { $eq: ["$budget_source", "งบประมาณแผ่นดิน"] },
              then: 1,
              else: 0,
            },
          },
          external_amount: {
            $cond: {
              if: { $eq: ["$budget_source", "งบประมาณภายนอก"] },
              then: 1,
              else: 0,
            },
          },
          personal_amount: {
            $cond: {
              if: { $eq: ["$budget_source", "งบประมาณส่วนตัว"] },
              then: 1,
              else: 0,
            },
          },
        },
      },
      {
        $group: {
          _id: { year: "$year", group: "$group" },

          income_budget: { $sum: "$income_budget" },
          national_budget: { $sum: "$national_budget" },
          external_budget: { $sum: "$external_budget" },
          personal_budget: { $sum: "$personal_budget" },

          income_amount: { $sum: "$income_amount" },
          national_amount: { $sum: "$national_amount" },
          external_amount: { $sum: "$external_amount" },
          personal_amount: { $sum: "$personal_amount" },
        },
      },
    ];

    let _data = await api.query("db/nunurse/form_research/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

export default function Research(props) {
  let history = useHistory();
  // let typeyear = props?.match?.params?.typeyear;
  let year = props?.match?.params?.year;
  let [dataTable, setDataTable] = useState([]);
  let [data, setData] = useState([]);
  let [user, setUser] = useState([]);
  let [selectData, setSelectData] = useState(year);

  let [isLogin, setLogin] = useState(false);
  let [chartBudgetType, setChartBudgetType] = useState([]);
  let [chartAmountType, setChartAmountType] = useState([]);
  let [chartBudgetTypeByGroup, setChartBudgetTypeByGroup] = useState([]);
  let [chartAmountTypeByGroup, setChartAmountTypeByGroup] = useState([]);
  // onCreateChartBudgetTypeByGroup

  React.useEffect(() => {
    let user = localStorage.getItem("user_nurse");
    let userdata = localStorage.getItem("user_data");
    let role = localStorage.getItem("role");
    if (user && userdata) {
      setLogin(true);
    }
  }, []);

  React.useEffect(() => {
    fetchData(year).then((_data) => {
      setDataTable(
        onCreateDataTable(
          _data.filter((x) => `${x._id.year}` === `${year}`),
          year
        )
      );
      setData(_data);
      setChartBudgetType(onCreateChartBudgetType(_data));
      setChartAmountType(onCreateChartAmountType(_data));
      setChartAmountTypeByGroup(onCreateChartAmountTypeByGroup(_data));
      setChartBudgetTypeByGroup(onCreateChartBudgetTypeByGroup(_data));
    });
  }, []);

  React.useEffect(() => {
    console.log("selectData", selectData);
    setDataTable(
      onCreateDataTable(
        data.filter((x) => `${x._id.year}` === `${selectData}`),
        selectData
      )
    );
  }, [selectData]);

  return (
    <Layout>
      <div
        style={{
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          width: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>วิจัย</Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            โครงการวิจัย
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 24,
            display: "flex",
            width: "1350px",
            alignSelf: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{ flexDirection: "column", display: "flex", width: "100%" }}
          >
            <div className="flex-row flex justify-between mb-4">
              {/* space-between */}
              <p class="text-center text-3xl">โครงการวิจัย</p>
              <div>
                <Button
                  shape="round"
                  style={{ backgroundColor: "#1677FF", color: "white" }}
                  icon={<TableOutlined />}
                  onClick={() => history.push("/research/table/all/all/2566")}
                  size="large"
                >
                  ดูตาราง
                </Button>
                {
                  isLogin &&
                  <Button
                    shape="round"
                    style={{ backgroundColor: "#1677FF", color: "white" }}
                    icon={<PlusOutlined />}
                    onClick={() => history.push("/research/create")}
                    size="large"
                  >
                    เพื่มโครงการวิจัย
                  </Button>
                }
              </div>
            </div>
            <Divider />
            <div className="flex-col flex">
              <p className="text-2xl mt-8 mb-2">
                จำนวนโครงการวิจัยแยกตามประเภทงบประมาณ
              </p>
            </div>

            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  align: "left",
                  // text: 'Browser market shares. January, 2022',
                  text: null,
                },
                subtitle: {
                  // align: 'left',
                  // text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
                  text: null,
                },
                accessibility: {
                  announceNewData: {
                    enabled: true,
                  },
                },
                // xAxis: {
                //   type: 'category'
                // },
                xAxis: {
                  categories: [
                    "งบประมาณรายได้",
                    "งบประมาณแผ่นดิน",
                    "งบประมาณภายนอก",
                    "งบประมาณส่วนตัว",
                  ],
                  title: {
                    text: null,
                  },
                },
                legend: {
                  layout: "vertical",
                  align: "right",
                  verticalAlign: "top",
                  shadow: true,
                },
                yAxis: {
                  title: {
                    text: "จำนวนโครงการวิจัย",
                  },
                },
                plotOptions: {
                  series: {
                    borderWidth: 0,
                    dataLabels: {
                      enabled: true,
                      format: "{point.y:.f}",
                    },
                  },
                },

                tooltip: {
                  headerFormat:
                    '<span style="font-size:11px">{series.name}</span><br>',
                  pointFormat:
                    '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b>โครงการ<br/>',
                  // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                  // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },
                series: chartAmountType,
              }}
            />
            <div style={{ marginTop: 32 }}></div>
            <p className="text-2xl mt-8 mb-2">จำนวนเงินแยกตามประเภทงบประมาณ</p>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  align: "left",
                  // text: 'Browser market shares. January, 2022',
                  text: null,
                },
                subtitle: {
                  // align: 'left',
                  // text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
                  text: null,
                },
                accessibility: {
                  announceNewData: {
                    enabled: true,
                  },
                },
                // xAxis: {
                //   type: 'category'
                // },
                xAxis: {
                  categories: [
                    "งบประมาณรายได้",
                    "งบประมาณแผ่นดิน",
                    "งบประมาณภายนอก",
                    "งบประมาณส่วนตัว",
                  ],
                  title: {
                    text: null,
                  },
                },
                legend: {
                  layout: "vertical",
                  align: "right",
                  verticalAlign: "top",
                  shadow: true,
                },
                yAxis: {
                  title: {
                    text: "จำนวนเงิน",
                  },
                },
                plotOptions: {
                  series: {
                    borderWidth: 0,
                    dataLabels: {
                      enabled: true,
                      formatter: function () {
                        return this.y ? numberWithCommas(this.y) : 0;
                      },
                    },
                  },
                },

                tooltip: {
                  formatter: function () {
                    console.log("this", this);
                    return (
                      // this.key +
                      this.series.name +
                      "<br/>: " +
                      (this.y ? numberWithCommas(this.y) : 0) +
                      " บาท"
                    );
                  },
                  headerFormat:
                    '<span style="font-size:11px">{series.name}</span><br>',
                  // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                  // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },
                series: chartBudgetType,
              }}
            />
            <div style={{ marginTop: 32 }}></div>
            <Text style={{ fontSize: 24, marginTop: 18 }}>
              จำนวนโครงการวิจัยแยกตามกลุ่มวิชา
            </Text>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  align: "left",
                  // text: 'Browser market shares. January, 2022',
                  text: null,
                },
                subtitle: {
                  // align: 'left',
                  // text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
                  text: null,
                },
                accessibility: {
                  announceNewData: {
                    enabled: true,
                  },
                },
                // xAxis: {
                //   type: 'category'
                // },
                xAxis: {
                  categories: [
                    "ปีงบประมาณ 2564",
                    "ปีงบประมาณ 2565",
                    "ปีงบประมาณ 2566",
                  ],
                  title: {
                    text: null,
                  },
                },
                yAxis: {
                  title: {
                    text: "จำนวนโครงการวิจัย",
                  },
                },
                // legend: {
                //   layout: 'vertical',
                //   align: 'right',
                //   verticalAlign: 'top',
                //   x: -40,
                //   y: 80,
                //   floating: true,
                //   borderWidth: 1,
                //   backgroundColor: '#FFFFFF',
                //   shadow: true
                // },
                plotOptions: {
                  series: {
                    borderWidth: 0,
                    dataLabels: {
                      enabled: true,
                      format: "{point.y:.f}",
                    },
                  },
                },

                tooltip: {
                  headerFormat:
                    '<span style="font-size:11px">{series.name}</span><br>',
                  pointFormat:
                    '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b>โครงการ<br/>',
                  // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                  // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },
                series: chartAmountTypeByGroup,
              }}
            />

            <div style={{ marginTop: 32 }}></div>
            <Text style={{ fontSize: 24, marginTop: 18 }}>
              จำนวนเงินงบประมาณโครงการแยกตามกลุ่มวิชา
            </Text>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  align: "left",
                  // text: 'Browser market shares. January, 2022',
                  text: null,
                },
                subtitle: {
                  // align: 'left',
                  // text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
                  text: null,
                },
                accessibility: {
                  announceNewData: {
                    enabled: true,
                  },
                },
                // xAxis: {
                //   type: 'category'
                // },
                xAxis: {
                  categories: [
                    "ปีงบประมาณ 2564",
                    "ปีงบประมาณ 2565",
                    "ปีงบประมาณ 2566",
                  ],
                  title: {
                    text: null,
                  },
                },
                yAxis: {
                  title: {
                    text: "จำนวนเงิน",
                  },
                },
                plotOptions: {
                  series: {
                    borderWidth: 0,
                    dataLabels: {
                      enabled: true,
                      formatter: function () {
                        return this.y ? numberWithCommas(this.y) : 0;
                      },
                      // format: "{point.y:.f}",
                    },
                  },
                },

                // formatter: function () {
                //   return this.key + " " + (this.y ? numberWithCommas(this.y) : 0) + " คน";
                // },
                tooltip: {
                  formatter: function () {
                    return (
                      this.series.name +
                      "<br/>: " +
                      (this.y ? numberWithCommas(this.y) : 0) +
                      " บาท"
                    );
                  },
                  headerFormat:
                    '<span style="font-size:11px">{series.name}</span><br>',
                  pointFormat:
                    '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b>โครงการวิจัย<br/>',
                  // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                  // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },
                series: chartBudgetTypeByGroup,
              }}
            />

            <div class="w-full mt-8 ">
              <div className="flex flex-row w-full mb-4">
                <p className="text-2xl">
                  ตารางแสดงจำนวนโครงการวิจัย (จำแนกตามประเภทงบประมาณ)
                </p>
                <div style={{ flex: 1 }}></div>
                <Space wrap>
                  {/* <Select
                    defaultValue={"ปีงบประมาณ"}
                    options={[
                      { label: "ปีงบประมาณ", value: "ปีงบประมาณ" },
                      { label: "ปีปฏิทิน", value: "ปีปฏิทิน" },
                    ]}
                  /> */}
                  <Select
                    value={selectData}
                    onChange={(value, id) => {
                      setSelectData(value);
                    }}
                    options={[
                      { label: "2566", value: "2566" },
                      { label: "2565", value: "2565" },
                      { label: "2564", value: "2564" },
                      // { label: '2563', value: '2563' },
                      // { label: '2562', value: '2562' },
                    ]}
                    style={{ width: 120 }}
                  />
                </Space>
              </div>

              <Table
                pagination={false}
                bordered
                size="small"
                dataSource={dataTable}
                columns={[
                  {
                    title: "กลุ่มวิชาการ",
                    dataIndex: "name",
                    key: "name",
                    width: 200,
                  },
                  {
                    title: "งบประมาณรายได้",
                    key: "income_amount",
                    render: rederColRight("income_amount", "income_budget"),
                    width: 80,
                    align: "center",
                  },
                  {
                    title: "งบประมาณแผ่นดิน",
                    render: rederColRight("national_amount", "national_budget"),
                    key: "national_amount",
                    align: "center",
                    width: 80,
                  },
                  {
                    title: "งบประมาณภายนอก",
                    // dataIndex: 'external_amount',
                    render: rederColRight("external_amount", "external_budget"),
                    key: "external_amount",
                    align: "center",
                    width: 80,
                  },
                  {
                    title: "งบประมาณส่วนตัว",
                    // dataIndex: 'personal_amount',
                    render: rederColRight("personal_amount", "personal_budget"),
                    key: "personal_amount",
                    align: "center",
                    width: 80,
                  },
                  {
                    title: "รวม",
                    render: rederColRight("sum_amount", "all"),
                    key: "sum_amount",
                    align: "center",
                    width: 80,
                  },
                ]}
                line
              />
              {/* data.table1 */}
            </div>

            <div class="w-full mt-12 ">
              <div className="flex flex-row w-full mb-4">
                <p className="text-2xl">ตารางแสดงงบประมาณโครงการวิจัย</p>
                <div style={{ flex: 1 }}></div>
                <Space wrap>
                  {/* <Select
                    defaultValue={"ปีงบประมาณ"}
                    options={[
                      { label: "ปีงบประมาณ", value: "ปีงบประมาณ" },
                      { label: "ปีปฏิทิน", value: "ปีปฏิทิน" },
                    ]}
                  /> */}
                  <Select
                    value={selectData}
                    onChange={(value, id) => {
                      setSelectData(value);
                    }}
                    options={[
                      { label: "2566", value: "2566" },
                      { label: "2565", value: "2565" },
                      { label: "2564", value: "2564" },
                      // { label: '2563', value: '2563' },
                      // { label: '2562', value: '2562' },
                    ]}
                    style={{ width: 120 }}
                  />
                </Space>
              </div>
              <Table
                pagination={false}
                bordered
                size="middle"
                dataSource={dataTable}
                columns={[
                  {
                    title: "กลุ่มวิชาการ",
                    dataIndex: "name",
                    key: "name",
                    width: 200,
                  },
                  {
                    title: "งบประมาณรายได้",
                    align: "center",
                    render: rederColRight("income_budget", "income_budget"),
                    align: "center",
                    width: 80,
                  },
                  {
                    title: "งบประมาณแผ่นดิน",
                    render: rederColRight("national_budget", "national_budget"),
                    align: "center",
                    width: 80,
                  },
                  {
                    title: "งบประมาณภายนอก",
                    render: rederColRight("external_budget", "external_budget"),
                    align: "center",
                    width: 80,
                  },
                  {
                    title: "งบประมาณส่วนตัว",
                    render: rederColRight("personal_budget", "personal_budget"),
                    align: "center",
                    width: 80,
                  },
                  {
                    title: "รวม",
                    render: rederColRight("sum_budget", "all"),
                    align: "center",
                    width: 80,
                  },
                ]}
                line
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
