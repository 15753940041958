import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Progress,
  Space,
  Button,
  Breadcrumb,
} from "antd";
import { PlusOutlined, TableOutlined } from "@ant-design/icons";
import AddressData from "../../util/AddressData";
import axios from "axios";
import api from "../../util/api";
import Box from "@mui/material/Box";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";
const { Title, Text } = Typography;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const rederColRight = (key, keypage, textAlign = "right") => {
  return (_, data) => {
    return {
      props: {
        style: { textAlign: "right" },
      },
      children: (
        <a href={`/AcademicService/table/${data.key}/${keypage}/${data.year}`}>
          {data[key]}
        </a>
      ),
    };
  };
};

const MAP_TABLE = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
};

const MAP_GROUP = {
  คณะพยาบาลศาสตร์: "all",
  กลุ่มวิชาการพยาบาลมารดาและทารก: "mother_and_infant",
  กลุ่มวิชาการพยาบาลเด็ก: "child",
  กลุ่มวิชาการพยาบาลผู้ใหญ่: "adult",
  กลุ่มวิชาการพยาบาลผู้สูงอายุ: "geriatric",
  กลุ่มวิชาการพยาบาลชุมชน: "community",
  กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช: "psychiatric",
  กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ: "administartion_professional",
};

const onCreateDataTable = (res, year) => {
  let result = {};

  Object.keys(MAP_TABLE).map((x) => {
    result[MAP_TABLE[x]] = {
      key: x,
      year: year,
      name: MAP_TABLE[x],
      speaker: 0,
      article_th: 0,
      article_en: 0,
      check_tools: 0,
      director_thesis: 0,
      director: 0,
      service: 0,
      other: 0,
      sum_all: 0,
    };
  });

  let sum = {
    key: "all",
    year: year,
    name: "รวม",
    speaker: 0,
    article_th: 0,
    article_en: 0,
    check_tools: 0,
    director_thesis: 0,
    director: 0,
    service: 0,
    other: 0,
    sum_all: 0,
  };

  res.forEach((element) => {
    // year
    if (result[element?._id?.group]) {
      result[element?._id?.group].speaker = element.speaker;
      result[element?._id?.group].article_th = element.article_th;
      result[element?._id?.group].article_en = element.article_en;
      result[element?._id?.group].check_tools = element.check_tools;
      result[element?._id?.group].director_thesis = element.director_thesis;
      result[element?._id?.group].director = element.director;
      result[element?._id?.group].service = element.service;
      result[element?._id?.group].other = element.other;
      result[element?._id?.group].sum_all =
        element.speaker +
        element.article_th +
        element.article_en +
        element.check_tools +
        element.director_thesis +
        element.director +
        element.service +
        element.other;

      sum.speaker += element.speaker;
      sum.article_th += element.article_th;
      sum.article_en += element.article_en;
      sum.check_tools += element.check_tools;
      sum.director_thesis += element.director_thesis;
      sum.director += element.director;
      sum.service += element.service;
      sum.other += element.other;
      sum.sum_all +=
        element.speaker +
        element.article_th +
        element.article_en +
        element.check_tools +
        element.director_thesis +
        element.director +
        element.service +
        element.other;
    }
  });
  // return Object.keys(result).map((x) => result[x]);
  let results = Object.keys(result).map((x) => result[x]);
  results.push(sum);
  return results;
};

const fetchDataCountProfile = async () => {
  try {
    let aggregate = [
      {
        $match: { del: { $ne: true } }
      },
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $project: {
          item: 1,
          year: 1,
          group: 1,
          list_person: 1,
          speaker: {
            $cond: {
              if: { $eq: ["$service_type", "วิทยากร"] },
              then: 1,
              else: 0,
            },
          },
          article_th: {
            $cond: {
              if: { $eq: ["$service_type", "อ่านบทความ (ไทย)"] },
              then: 1,
              else: 0,
            },
          },
          article_en: {
            $cond: {
              if: { $eq: ["$service_type", "อ่านบทความ (อังกฤษ)"] },
              then: 1,
              else: 0,
            },
          },
          check_tools: {
            $cond: {
              if: { $eq: ["$service_type", "ตรวจเครื่องมือวิจัย"] },
              then: 1,
              else: 0,
            },
          },
          director_thesis: {
            $cond: {
              if: { $eq: ["$service_type", "กรรมการสอบวิทยานิพนธ์ภายนอก"] },
              then: 1,
              else: 0,
            },
          },

          director: {
            $cond: {
              if: { $eq: ["$service_type", "กรรมการหน่วยงานภายนอก"] },
              then: 1,
              else: 0,
            },
          },
          service: {
            $cond: {
              if: { $eq: ["$service_type", "บริการวิชาชีพ"] },
              then: 1,
              else: 0,
            },
          },
          other: {
            $cond: {
              if: { $eq: ["$service_type", "อื่นๆ"] },
              then: 1,
              else: 0,
            },
          },
        },
      },
      {
        $group: {
          _id: { year: "$year", id: "$list_person.id" },
          speaker: { $sum: "$speaker" },
          article_th: { $sum: "$article_th" },
          article_en: { $sum: "$article_en" },
          check_tools: { $sum: "$check_tools" },
          director_thesis: { $sum: "$director_thesis" },
          director: { $sum: "$director" },
          service: { $sum: "$service" },
          other: { $sum: "$other" },
        },
      },
      {
        $project: {
          year: 1,
          id: "$list_person.id",
          _id: 1,
          speaker: {
            $cond: { if: { $gte: ["$speaker", 1] }, then: 1, else: 0 },
          },
          article_th: {
            $cond: { if: { $gte: ["$article_th", 1] }, then: 1, else: 0 },
          },
          article_en: {
            $cond: { if: { $gte: ["$article_en", 1] }, then: 1, else: 0 },
          },
          check_tools: {
            $cond: { if: { $gte: ["$check_tools", 1] }, then: 1, else: 0 },
          },
          director_thesis: {
            $cond: { if: { $gte: ["$director_thesis", 1] }, then: 1, else: 0 },
          },
          director: {
            $cond: { if: { $gte: ["$director", 1] }, then: 1, else: 0 },
          },
          service: {
            $cond: { if: { $gte: ["$service", 1] }, then: 1, else: 0 },
          },
          other: { $cond: { if: { $gte: ["$other", 1] }, then: 1, else: 0 } },
        },
      },
      {
        $group: {
          _id: { year: "$_id.year" },
          speaker: { $sum: "$speaker" },
          article_th: { $sum: "$article_th" },
          article_en: { $sum: "$article_en" },
          check_tools: { $sum: "$check_tools" },
          director_thesis: { $sum: "$director_thesis" },
          director: { $sum: "$director" },
          service: { $sum: "$service" },
          other: { $sum: "$other" },
        },
      },
    ];

    let _data = await api.query("db/nunurse/form_academic_service/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const fetchData = async () => {
  try {
    let aggregate = [
      {
        $match: { del: { $ne: true } }
      },
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $project: {
          item: 1,
          year: 1,
          "list_person.group": 1,

          speaker: {
            $cond: {
              if: { $eq: ["$service_type", "วิทยากร"] },
              then: 1,
              else: 0,
            },
          },
          article_th: {
            $cond: {
              if: { $eq: ["$service_type", "อ่านบทความ (ไทย)"] },
              then: 1,
              else: 0,
            },
          },
          article_en: {
            $cond: {
              if: { $eq: ["$service_type", "อ่านบทความ (อังกฤษ)"] },
              then: 1,
              else: 0,
            },
          },
          check_tools: {
            $cond: {
              if: { $eq: ["$service_type", "ตรวจเครื่องมือวิจัย"] },
              then: 1,
              else: 0,
            },
          },
          director_thesis: {
            $cond: {
              if: { $eq: ["$service_type", "กรรมการสอบวิทยานิพนธ์ภายนอก"] },
              then: 1,
              else: 0,
            },
          },

          director: {
            $cond: {
              if: { $eq: ["$service_type", "กรรมการหน่วยงานภายนอก"] },
              then: 1,
              else: 0,
            },
          },
          service: {
            $cond: {
              if: { $eq: ["$service_type", "บริการวิชาชีพ"] },
              then: 1,
              else: 0,
            },
          },
          other: {
            $cond: {
              if: { $eq: ["$service_type", "อื่นๆ"] },
              then: 1,
              else: 0,
            },
          },
        },
      },
      {
        $group: {
          _id: { year: "$year", group: "$list_person.group" },

          speaker: { $sum: "$speaker" },
          article_th: { $sum: "$article_th" },
          article_en: { $sum: "$article_en" },
          check_tools: { $sum: "$check_tools" },
          director_thesis: { $sum: "$director_thesis" },
          director: { $sum: "$director" },
          service: { $sum: "$service" },
          other: { $sum: "$other" },
        },
      },
    ];

    let _data = await api.query("db/nunurse/form_academic_service/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const fetchDataProfile = async () => {
  try {
    let aggregate = [];

    let _data = await api.query("db/nunurse/profile/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const onCreateChartService = (data) => {
  let KEY_DAYA = {
    speaker: [0, 0, 0, 0],
    article_th: [0, 0, 0, 0],
    article_en: [0, 0, 0, 0],
    check_tools: [0, 0, 0, 0],
    director_thesis: [0, 0, 0, 0],
    director: [0, 0, 0, 0],
    service: [0, 0, 0, 0],
    other: [0, 0, 0, 0],
  };

  data.forEach((x) => {
    let index = null;
    if (x?._id?.year == "2566") index = 3;
    if (x?._id?.year == "2565") index = 2;
    if (x?._id?.year == "2564") index = 1;
    if (x?._id?.year == "2563") index = 0;
    KEY_DAYA.speaker[index] += x.speaker;
    KEY_DAYA.article_th[index] += x.article_th;
    KEY_DAYA.article_en[index] += x.article_en;
    KEY_DAYA.check_tools[index] += x.check_tools;
    KEY_DAYA.director_thesis[index] += x.director_thesis;
    KEY_DAYA.director[index] += x.director;
    KEY_DAYA.service[index] += x.service;
    KEY_DAYA.other[index] += x.other;
  });


  return [
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2563	",
      data: [
        KEY_DAYA.speaker[0],
        KEY_DAYA.article_th[0],
        KEY_DAYA.article_en[0],
        KEY_DAYA.check_tools[0],
        KEY_DAYA.director_thesis[0],
        KEY_DAYA.director[0],
        KEY_DAYA.service[0],
        KEY_DAYA.other[0],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2564	",
      data: [
        KEY_DAYA.speaker[1],
        KEY_DAYA.article_th[1],
        KEY_DAYA.article_en[1],
        KEY_DAYA.check_tools[1],
        KEY_DAYA.director_thesis[1],
        KEY_DAYA.director[1],
        KEY_DAYA.service[1],
        KEY_DAYA.other[1],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2565	",
      data: [
        KEY_DAYA.speaker[2],
        KEY_DAYA.article_th[2],
        KEY_DAYA.article_en[2],
        KEY_DAYA.check_tools[2],
        KEY_DAYA.director_thesis[2],
        KEY_DAYA.director[2],
        KEY_DAYA.service[2],
        KEY_DAYA.other[2],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2566",
      data: [
        KEY_DAYA.speaker[3],
        KEY_DAYA.article_th[3],
        KEY_DAYA.article_en[3],
        KEY_DAYA.check_tools[3],
        KEY_DAYA.director_thesis[3],
        KEY_DAYA.director[3],
        KEY_DAYA.service[3],
        KEY_DAYA.other[3],
      ],
    },
  ];
};

const onCreateChartServiceByPercentage = (data, profile) => {
  let total = profile.length;
  let KEY_DAYA = {
    speaker: [0, 0, 0, 0],
    article_th: [0, 0, 0, 0],
    article_en: [0, 0, 0, 0],
    check_tools: [0, 0, 0, 0],
    director_thesis: [0, 0, 0, 0],
    director: [0, 0, 0, 0],
    service: [0, 0, 0, 0],
    other: [0, 0, 0, 0],
  };

  data.forEach((x) => {
    let index = null;
    if (x?._id?.year == "2566") index = 3;
    if (x?._id?.year == "2565") index = 2;
    if (x?._id?.year == "2564") index = 1;
    if (x?._id?.year == "2563") index = 0;
    KEY_DAYA.speaker[index] += x.speaker;
    KEY_DAYA.article_th[index] += x.article_th;
    KEY_DAYA.article_en[index] += x.article_en;
    KEY_DAYA.check_tools[index] += x.check_tools;
    KEY_DAYA.director_thesis[index] += x.director_thesis;
    KEY_DAYA.director[index] += x.director;
    KEY_DAYA.service[index] += x.service;
    KEY_DAYA.other[index] += x.other;
  });

  console.log("KEY_DAYA", KEY_DAYA);

  const percentage = (n, t) => {
    return Number((n * 100).toFixed(2));
  };

  let total_63 = 48
  let total_64 = 52
  let total_65 = 52
  let total_66 = 49

  return [
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2563",
      data: [
        percentage(KEY_DAYA.speaker[0] / total_63),
        percentage(KEY_DAYA.article_th[0] / total_63),
        percentage(KEY_DAYA.article_en[0] / total_63),
        percentage(KEY_DAYA.check_tools[0] / total_63),
        percentage(KEY_DAYA.director_thesis[0] / total_63),
        percentage(KEY_DAYA.director[0] / total_63),
        percentage(KEY_DAYA.service[0] / total_63),
        percentage(KEY_DAYA.other[0] / total_63),
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2564",
      data: [
        percentage(KEY_DAYA.speaker[1] / total_64),
        percentage(KEY_DAYA.article_th[1] / total_64),
        percentage(KEY_DAYA.article_en[1] / total_64),
        percentage(KEY_DAYA.check_tools[1] / total_64),
        percentage(KEY_DAYA.director_thesis[1] / total_64),
        percentage(KEY_DAYA.director[1] / total_64),
        percentage(KEY_DAYA.service[1] / total_64),
        percentage(KEY_DAYA.other[1] / total_64),
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2565",
      data: [
        percentage(KEY_DAYA.speaker[2] / total_65),
        percentage(KEY_DAYA.article_th[2] / total_65),
        percentage(KEY_DAYA.article_en[2] / total_65),
        percentage(KEY_DAYA.check_tools[2] / total_65),
        percentage(KEY_DAYA.director_thesis[2] / total_65),
        percentage(KEY_DAYA.director[2] / total_65),
        percentage(KEY_DAYA.service[2] / total_65),
        percentage(KEY_DAYA.other[2] / total_65),
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2566",
      data: [
        percentage(KEY_DAYA.speaker[3] / total_66),
        percentage(KEY_DAYA.article_th[3] / total_66),
        percentage(KEY_DAYA.article_en[3] / total_66),
        percentage(KEY_DAYA.check_tools[3] / total_66),
        percentage(KEY_DAYA.director_thesis[3] / total_66),
        percentage(KEY_DAYA.director[3] / total_66),
        percentage(KEY_DAYA.service[3] / total_66),
        percentage(KEY_DAYA.other[3] / total_66),
      ],
    }
  ];
};

export default function AcademicServiceProject(props) {
  let history = useHistory();
  let typeyear = props?.match?.params?.typeyear;
  let year = props?.match?.params?.year;
  let [dateTable, setDateTable] = useState([]);
  let [chartService, setChartService] = useState([]);
  let [chartServiceByPercentage, setChartServiceByPercentage] = useState([]);
  let [DataDB, setDataDB] = useState(null);

  let [selectYear, setSelectYear] = useState(year);
  let [isLogin, setLogin] = useState(false);

  React.useEffect(() => {
    Promise.all([
      fetchData(),
      fetchDataProfile(),
      fetchDataCountProfile(),
    ]).then(([_data, profile, CountProfile]) => {
      setDataDB({
        _data,
        profile,
        CountProfile,
      });
    });
  }, []);

  React.useEffect(() => {
    console.log("DataDB", DataDB);
    if (DataDB) {
      setDateTable(
        onCreateDataTable(
          DataDB._data.filter((x) => `${x._id.year}` === `${selectYear}`),
          selectYear
        )
      );
      setChartService(onCreateChartService(DataDB._data));
      setChartServiceByPercentage(
        onCreateChartServiceByPercentage(DataDB.CountProfile, DataDB.profile)
      );
    }
  }, [DataDB, selectYear]);

  React.useEffect(() => {
    let user = localStorage.getItem("user_nurse");
    let userdata = localStorage.getItem("user_data");
    let role = localStorage.getItem("role");
    if (user && userdata) {
      setLogin(true);
    }
  }, []);

  return (
    <Layout>
      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          padding: 12,
          // padding: "18px",
          display: "flex",
          maxWidth: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            การบริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            การให้บริการวิชาการ
          </Breadcrumb.Item>
        </Breadcrumb>

        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 24,
            display: "flex",
            width: "1350px",
            minHeight: "600px",
            alignSelf: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="flex-row flex justify-between mb-4">
            {/* space-between */}
            <p class="text-center text-3xl">การให้บริการวิชาการ</p>
            <div>
              <Button
                shape="round"
                style={{ backgroundColor: "#1677FF", color: "white" }}
                icon={<TableOutlined />}
                onClick={() =>
                  history.push("/AcademicService/table/all/all/2566")
                }
                size="large"
              >
                ดูตาราง
              </Button>
              {
                isLogin &&
                <Button
                  shape="round"
                  style={{ backgroundColor: "#1677FF", color: "white" }}
                  icon={<PlusOutlined />}
                  onClick={() => history.push("/AcademicService/create")}
                  size="large"
                >
                  เพื่มการให้บริการวิชาการ
                </Button>
              }

            </div>
          </div>
          <Divider />

          <Divider />

          <div className="flex-col flex">
            <p className="text-2xl mt-8 mb-2">
              จำนวนการให้บริการวิชาการ
            </p>
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              title: {
                align: "left",
                // text: 'Browser market shares. January, 2022',
                text: null,
              },
              subtitle: {
                // align: 'left',
                // text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
                text: null,
              },
              accessibility: {
                announceNewData: {
                  enabled: true,
                },
              },
              // xAxis: {
              //   type: 'category'
              // },
              xAxis: {
                categories: [
                  "วิทยากร",
                  "อ่านบทความ (ไทย)",
                  "อ่านบทความ (อังกฤษ)",
                  "ตรวจเครื่องมือวิจัย",
                  "กรรมการสอบวิทยานิพนธ์ภายนอก",
                  "กรรมการหน่วยงานภายนอก",
                  "บริการวิชาชีพ",
                  "อื่นๆ",
                ],
                title: {
                  text: null,
                },
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "top",
                shadow: true,
              },
              yAxis: {
                title: {
                  text: "จำนวนการให้บริการวิชาการ (ครั้ง)",
                },
              },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    enabled: true,
                    formatter: function () {
                      return this.y ? numberWithCommas(this.y) : 0;
                    },
                  },
                },
              },

              tooltip: {
                formatter: function () {
                  return (
                    this.series.name +
                    ": " +
                    (this.y ? numberWithCommas(this.y) : 0) +
                    " ครั้ง"
                  );
                },
                headerFormat:
                  '<span style="font-size:11px">{series.name}</span><br>',
                // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
              },
              series: chartService,
            }}
          />
          <div className="flex-col flex">
            <p className="text-2xl mt-8 mb-2">
              ร้อยละให้การบริการวิชาการ: บุคลากร
            </p>
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              title: {
                align: "left",
                // text: 'Browser market shares. January, 2022',
                text: null,
              },
              subtitle: {
                // align: 'left',
                // text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
                text: null,
              },
              accessibility: {
                announceNewData: {
                  enabled: true,
                },
              },
              // xAxis: {
              //   type: 'category'
              // },
              xAxis: {
                categories: [
                  "วิทยากร",
                  "อ่านบทความ (ไทย)",
                  "อ่านบทความ (อังกฤษ)",
                  "ตรวจเครื่องมือวิจัย",
                  "กรรมการสอบวิทยานิพนธ์ภายนอก",
                  "กรรมการหน่วยงานภายนอก",
                  "บริการวิชาชีพ",
                  "อื่นๆ",
                ],
                title: {
                  text: null,
                },
              },
              legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "top",
                shadow: true,
              },
              yAxis: {
                title: {
                  text: "ร้อยละ",
                },
              },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    enabled: true,
                    formatter: function () {
                      return this.y ? numberWithCommas(this.y) : 0;
                    },
                  },
                },
              },

              tooltip: {
                formatter: function () {
                  return (
                    "" +
                    this.key +
                    "คิดเป็นร้อยละ " +
                    (this.y ? numberWithCommas(this.y) : 0) +
                    "<br/>จากบุคลากรทั้งหมด"
                  );
                },
                headerFormat:
                  '<span style="font-size:11px">{series.name}</span><br>',
                // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
              },
              series: chartServiceByPercentage,
            }}
          />

          <div className="flex mt-8 flex-col">
            <div className="flex mb-2 flex-row">
              <p className="text-2xl">จำนวนการให้บริการวิชาการ</p>
              <div style={{ flex: 1 }}></div>
              <Space wrap>
                <p>ปีงบประมาณ</p>
                <Select
                  value={selectYear}
                  onChange={(value) => {
                    setSelectYear(value);
                  }}
                  options={[
                    { label: "2566", value: "2566" },
                    { label: "2565", value: "2565" },
                    { label: "2564", value: "2564" },
                    { label: '2563', value: '2563' },
                    // { label: '2562', value: '2562' },
                  ]}
                  style={{ width: 120 }}
                />
              </Space>
            </div>

            <Table
              he
              bordered
              size="small"
              dataSource={dateTable}
              columns={[
                {
                  title: "กลุ่มวิชาการ",
                  dataIndex: "name",
                  key: "name",
                  width: 170,
                },
                {
                  title: "วิทยากร",
                  dataIndex: "speaker",
                  key: "speaker",
                  render: rederColRight("speaker", "speaker"),
                  width: 120,
                },

                {
                  title: "อ่านบทความ (ไทย)",
                  dataIndex: "article_th",
                  key: "article_th",
                  render: rederColRight("article_th", "article_th"),
                  width: 120,
                },

                {
                  title: "อ่านบทความ (อังกฤษ)",
                  dataIndex: "article_en",
                  key: "article_en",
                  render: rederColRight("article_en", "article_en"),
                  width: 120,
                },

                {
                  title: "ตรวจเครื่องมือวิจัย",
                  dataIndex: "check_tools",
                  key: "check_tools",
                  render: rederColRight("check_tools", "check_tools"),
                  width: 120,
                },
                {
                  title: "กรรมการสอบวิทยานิพนธ์ภายนอก",
                  dataIndex: "director_thesis",
                  key: "director_thesis",
                  render: rederColRight("director_thesis", "director_thesis"),
                  width: 120,
                },
                {
                  title: "กรรมการหน่วยงานภายนอก",
                  dataIndex: "director",
                  key: "director",
                  render: rederColRight("director", "director"),
                  width: 120,
                },
                {
                  title: "บริการวิชาชีพ",
                  dataIndex: "service",
                  key: "service",
                  render: rederColRight("service", "service"),
                  width: 120,
                },
                {
                  title: "อื่นๆ",
                  dataIndex: "other",
                  key: "other",
                  render: rederColRight("other", "other"),
                  width: 100,
                },
                {
                  title: "รวม",
                  dataIndex: "sum_all",
                  key: "sum_all",
                  render: rederColRight("sum_all", "all"),
                  width: 100,
                },
              ]}
              line
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
