import React from 'react';
import ReactDOM from 'react-dom';
// import 'antd/dist/reset.css';
import 'antd/dist/antd.min.css'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './reducers/rootReducer'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Kanit',
        },
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
