import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Progress,
  Space,
  Button,
  Breadcrumb,
} from "antd";
import { PlusOutlined, TableOutlined } from "@ant-design/icons";
import axios from "axios";
import api from "../../util/api";
import Box from "@mui/material/Box";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";
const { Title, Text } = Typography;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const rederColRight = (key, keypage, textAlign = "right") => {
  return (_, data) => {
    return {
      props: {
        style: { textAlign: "right" },
      },
      children: (
        <a href={`/ResearchResults/table/${data.key}/${keypage}/${data.year}`}>
          {data[key]}
        </a>
      ),
    };
  };
};

const MAP_TABLE = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
};

const onCreateChartResearch = (data = []) => {
  let KEY_DAYA = {
    journal_international: [0, 0, 0],
    journal_thai: [0, 0, 0],
    proceeding_international: [0, 0, 0],
    proceeding_thai: [0, 0, 0],
  };

  console.log("data", data);

  data.forEach((x) => {
    let index = null;
    if (x?._id?.year == "2566") index = 2;
    if (x?._id?.year == "2565") index = 1;
    if (x?._id?.year == "2564") index = 0;
    KEY_DAYA.journal_international[index] += x.journal_international;
    KEY_DAYA.journal_thai[index] += x.journal_thai;
    KEY_DAYA.proceeding_international[index] += x.proceeding_international;
    KEY_DAYA.proceeding_thai[index] += x.proceeding_thai;
  });

  console.log("KEY_DAYA", KEY_DAYA);

  return [
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2563	",
      data: [
        KEY_DAYA.journal_international[0],
        KEY_DAYA.journal_thai[0],
        KEY_DAYA.proceeding_international[0],
        KEY_DAYA.proceeding_thai[0],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2564	",
      data: [
        KEY_DAYA.journal_international[1],
        KEY_DAYA.journal_thai[1],
        KEY_DAYA.proceeding_international[1],
        KEY_DAYA.proceeding_thai[1],
      ],
    },
    {
      showInLegend: true,
      name: "ปีงบประมาณ 2565",
      data: [
        KEY_DAYA.journal_international[2],
        KEY_DAYA.journal_thai[2],
        KEY_DAYA.proceeding_international[2],
        KEY_DAYA.proceeding_thai[2],
      ],
    },
  ];
};

const onCreateDataTable = (res, year) => {
  let result = {};

  Object.keys(MAP_TABLE).map((x) => {
    result[MAP_TABLE[x]] = {
      key: x,
      year: year,
      name: MAP_TABLE[x],
      journal_international: 0,
      journal_thai: 0,
      proceeding_international: 0,
      proceeding_thai: 0,
      sum_all: 0,
    };
  });

  let sum = {
    key: "all",
    year: year,
    name: "รวม",
    journal_international: 0,
    journal_thai: 0,
    proceeding_international: 0,
    proceeding_thai: 0,
    sum_all: 0,
  };

  console.log("res", res);
  res.forEach((element) => {
    if (result[element?._id?.group]) {
      result[element?._id?.group].journal_international =
        element.journal_international;
      result[element?._id?.group].journal_thai = element.journal_thai;
      result[element?._id?.group].proceeding_international =
        element.proceeding_international;
      result[element?._id?.group].proceeding_thai = element.proceeding_thai;

      result[element?._id?.group].sum_all =
        element.journal_international +
        element.journal_thai +
        element.proceeding_international +
        element.proceeding_thai;

      sum.journal_international += element.journal_international;
      sum.journal_thai += element.journal_thai;
      sum.proceeding_international += element.proceeding_international;
      sum.proceeding_thai += element.proceeding_thai;
      sum.sum_all +=
        element.journal_international +
        element.journal_thai +
        element.proceeding_international +
        element.proceeding_thai;
    }
  });
  return [sum];
  // Object.keys(result).map((x) => result[x]);
};

const fetchData = async () => {
  try {
    let aggregate = [
      {
        $project: {
          item: 1,
          year: 1,
          group: 1,

          journal_international: {
            $cond: {
              if: {
                $and: [
                  { $eq: ["$research_type", "journal"] },
                  { $eq: ["$research_class", "thai"] },
                ],
              },
              then: 1,
              else: 0,
            },
          },
          journal_thai: {
            $cond: {
              if: {
                $and: [
                  { $eq: ["$research_type", "journal"] },
                  { $eq: ["$research_class", "international"] },
                ],
              },
              then: 1,
              else: 0,
            },
          },
          proceeding_international: {
            $cond: {
              if: {
                $and: [
                  { $eq: ["$research_type", "proceeding"] },
                  { $eq: ["$research_class", "thai"] },
                ],
              },
              then: 1,
              else: 0,
            },
          },
          proceeding_thai: {
            $cond: {
              if: {
                $and: [
                  { $eq: ["$research_type", "proceeding"] },
                  { $eq: ["$research_class", "international"] },
                ],
              },
              then: 1,
              else: 0,
            },
          },
        },
      },
      {
        $group: {
          _id: { year: "$year", group: "$group" },

          journal_international: { $sum: "$journal_international" },
          journal_thai: { $sum: "$journal_thai" },
          proceeding_international: { $sum: "$proceeding_international" },
          proceeding_thai: { $sum: "$proceeding_thai" },
        },
      },
    ];

    let _data = await api.query("db/nunurse/form_research_result/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

export default function Research(props) {
  let history = useHistory();
  let typeyear = props?.match?.params?.typeyear;
  let year = props?.match?.params?.year;
  let [dateTable, setDateTable] = useState([]);
  let [data, setData] = useState([]);
  let [selectData, setSelectData] = useState("คณะพยาบาลศาสตร์");
  let [chartResearch, setChartResearch] = useState([]);
  let [selectYear, setSelectYear] = useState(year);
  let [isLogin, setLogin] = useState(false);

  React.useEffect(() => {
    fetchData(year).then((res) => {
      setData(res);
    });
  }, []);

  React.useEffect(() => {
    setDateTable(
      onCreateDataTable(
        data.filter((x) => `${x._id.year}` === `${selectYear}`)
      )
    );
    setChartResearch(onCreateChartResearch(data));
  }, [data, selectYear]);

  React.useEffect(() => {
    let user = localStorage.getItem("user_nurse");
    let userdata = localStorage.getItem("user_data");
    let role = localStorage.getItem("role");
    if (user && userdata) {
      setLogin(true);
    }
  }, []);


  console.log("dateTable", dateTable);

  return (
    <Layout>
      <div
        style={{
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          maxWidth: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>วิจัย</Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            [ผลงานวิจัย/ตีพิมพ์/นำเสนอ]
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 12,
            padding: "18px",
            display: "flex",
            width: "1350px",
            alignSelf: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{ flexDirection: "column", display: "flex", width: "100%" }}
          >
            <div className="flex-row flex justify-between mb-4">
              {/* space-between */}
              <p class="text-center text-3xl"> ผลงานวิจัย/ตีพิมพ์/นำเสนอ</p>
              <div>
                <Button
                  shape="round"
                  style={{ backgroundColor: "#1677FF", color: "white" }}
                  icon={<TableOutlined />}
                  onClick={() =>
                    history.push("/ResearchResults/table/all/all/2566")
                  }
                  size="large"
                >
                  ดูตาราง
                </Button>

                {
                  isLogin &&
                  <Button
                    shape="round"
                    style={{ backgroundColor: "#1677FF", color: "white" }}
                    icon={<PlusOutlined />}
                    onClick={() => history.push("/ResearchResults/create")}
                    size="large"
                  >
                    เพื่มผลงานวิจัย/ตีพิมพ์/นำเสนอ
                  </Button>
                }

              </div>
            </div>

            <Divider />

            <div className="flex-col flex">
              <p className="text-2xl mt-8 mb-2">
                จำนวนโครงการวิจัยแยกตามประเภทงบประมาณ
              </p>
            </div>

            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  align: "left",
                  // text: 'Browser market shares. January, 2022',
                  text: null,
                },
                subtitle: {
                  // align: 'left',
                  // text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
                  text: null,
                },
                accessibility: {
                  announceNewData: {
                    enabled: true,
                  },
                },
                // xAxis: {
                //   type: 'category'
                // },
                xAxis: {
                  categories: [
                    "วารสาร ระดับชาติ",
                    "วารสาร ระดับนานาชาติ",
                    "ผลงานที่นำเสนอในที่ประชุมวิชาการ ระดับชาติ",
                    "ผลงานที่นำเสนอในที่ประชุมวิชาการ ระดับนานาชาติ",
                  ],
                  title: {
                    text: null,
                  },
                },
                legend: {
                  layout: "vertical",
                  align: "right",
                  verticalAlign: "top",
                  shadow: true,
                },
                yAxis: {
                  title: {
                    text: "จำนวนผลงานวิจัย/ตีพิมพ์/นำเสนอ",
                  },
                },
                plotOptions: {
                  series: {
                    borderWidth: 0,
                    dataLabels: {
                      enabled: true,
                      format: "{point.y:.f}",
                    },
                  },
                },

                tooltip: {
                  headerFormat:
                    '<span style="font-size:11px">{series.name}</span><br>',
                  pointFormat:
                    '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b>โครงการวิจัย<br/>',
                  // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                  // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },
                series: chartResearch,
              }}
            />

            <div class="w-full mt-20">
              <div class="w-full mt-8 ">
                <div className="flex flex-row w-full mb-4">
                  <p className="text-2xl">
                    ตารางแสดงจำนวน ผลงานวิจัย/ตีพิมพ์/นำเสนอ
                  </p>
                  <div style={{ flex: 1 }}></div>
                  <Space wrap>
                    {/* <Select
                    defaultValue={"ปีงบประมาณ"}
                    options={[
                      { label: "ปีงบประมาณ", value: "ปีงบประมาณ" },
                      { label: "ปีปฏิทิน", value: "ปีปฏิทิน" },
                    ]}
                  /> */}
                    <Select
                      value={selectYear}
                      onChange={(value, id) => {
                        setSelectYear(value);
                      }}
                      options={[
                        { label: "2566", value: "2566" },
                        { label: "2565", value: "2565" },
                        { label: "2564", value: "2564" },
                      ]}
                      style={{ width: 120 }}
                    />
                  </Space>
                </div>
              </div>

              <Table
                pagination={false}
                bordered
                size="small"
                dataSource={dateTable}
                columns={[
                  {
                    title: "วารสาร (Journal)",
                    align: "center",
                    children: [
                      {
                        title: "ระดับชาติ",
                        align: "center",
                        dataIndex: "journal_international",
                        key: "journal_international",
                        render: rederColRight(
                          "journal_international",
                          "journal_international"
                        ),
                        width: 30,
                      },
                      {
                        title: "ระดับนานาชาติ",
                        align: "center",
                        dataIndex: "journal_thai",
                        key: "journal_thai",
                        render: rederColRight("journal_thai", "journal_thai"),
                        width: 30,
                      },
                    ],
                  },
                  {
                    title: "ผลงานที่นำเสนอในที่ประชุมวิชาการ (Proceeding)",
                    children: [
                      {
                        title: "ระดับชาติ",
                        align: "center",
                        dataIndex: "proceeding_international",
                        key: "proceeding_international",
                        render: rederColRight(
                          "proceeding_international",
                          "proceeding_international"
                        ),
                        width: 30,
                      },
                      {
                        title: "ระดับนานาชาติ",
                        align: "center",
                        dataIndex: "proceeding_thai",
                        render: rederColRight(
                          "proceeding_thai",
                          "proceeding_thai"
                        ),
                        key: "proceeding_thai",
                        width: 30,
                      },
                    ],
                  },
                  {
                    title: "รวม",
                    align: "center",
                    render: rederColRight("sum_all", "all"),
                    width: 40,
                  },
                ]}
                line
              />
              <div className="h-[100px]"></div>
            </div>

            <Divider class="m-4" />
          </div>
        </div>
        <div className="h-[200px]"></div>
      </div>
    </Layout>
  );
}
