import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import { v4 } from "uuid";
import {
  Select,
  Table,
  Typography,
  Button,
  Breadcrumb,
  Input,
  Modal,
  Switch,
  Upload
} from "antd";

import ImgCrop from 'antd-img-crop';
import axios from "axios";
import api from "../../util/api";
import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import SweetAlert from 'sweetalert2-react';

import img_default from '../../images/img_default.png';

import "moment/locale/th";
const { Title, Text } = Typography;
const { Option } = Select;
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const getBase64 = (img, callback) => {
  try {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  } catch (err) {
    console.log("err: ", err)
  }
};

const POST = {

}

const fetchData = async () => {
  try {
    let aggregate = [];
    let _data = await api.query("db/nunurse/post/query", {
      aggregate: aggregate,
    });
    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

export default function AcademicServiceProject(props) {
  let [imagePreview, setImagePreview] = useState(img_default);
  let [fileImage, setFileImage] = useState([]);

  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [ModalData, setModalData] = useState({
    visible: false,
    title: '',
  });

  const [Post, setPost] = useState({ ...POST });

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }

  React.useEffect(() => {
    Promise.all([
      fetchData(),
    ]).then(([_data]) => {
      console.log("_data", _data)
      setData([..._data]);
    });
  }, []);

  return (
    <Layout>
      <div
        style={{
          borderRadius: 12,
          padding: 12,
          display: "flex",
          maxWidth: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <SweetAlert
          show={show.show}
          title={show.title}
          text={show.message}
          onConfirm={() => {
            setShow({ ...show, ...{ show: false } })
          }}
        />

        <Modal
          title={ModalData.title}
          centered
          visible={ModalData.visible}
          onOk={async () => {
            if (!Post._id) Post._id = v4().toString().replace(/-/g, "");
            for (let i = 0; i < fileImage.length; i++) {
              let url = 'https://nursenu.pro/minio/presignedUrl/' + 'nurse-profile' + '?name=' + Post._id + '.png'
              let urlForPut = await axios.get(url)
              let res = await axios.put(urlForPut.data, fileImage[i])
            }
            Post.name_image = Post._id
            await api.insert(`db/nunurse/post/data/${Post._id}`, { ...Post })
            let index = data.findIndex((item) => item._id === Post._id)
            if (index) data[index] = Post
            else data.push(Post)
            setData([...data])
            setPost({ ...POST })
            setModalData({
              visible: false,
              title: 'โพสต์',
            })
          }}
          onCancel={() => {
            setPost({ ...POST })
            setModalData({
              visible: false,
              title: 'โพสต์',
            })
          }}
          width={800}
          okText={'บันทึก'}
          okButtonProps={{ style: { background: 'green' } }}
          cancelText={'ยกเลิก'}
        >

          <div className="flex flex-col">
            <div className="mt-2 flex-col">
              {imagePreview ? <img
                src={imagePreview}
                onError={event => {
                  event.target.src = img_default
                  event.onerror = null
                }}
                alt="avatar" style={{ width: 600, height: 400, margin: 4, borderRadius: '4px' }} /> : null}
              <div className="w-full">
                <ImgCrop
                  style={{ width: '100%' }}
                  modalTitle='เลือกรูปภาพ'
                  quality={1}
                  aspect={6 / 4}
                  modalOk='ตกลง'
                  modalCancel="ยกเลิก"
                  modalProps={{ okButtonProps: { style: { background: 'green' } } }}
                >
                  <Upload
                    name="avatar"
                    style={{ width: '100%' }}
                    className="avatar-uploader"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    showUploadList={false}
                    fileList={fileImage}
                    beforeUpload={(file) => {
                      getBase64(file, (url) => {
                        setImagePreview(url)
                        setFileImage([file])
                      });
                      return false;
                    }}
                  >
                    <div className="w-full p-2 flex justify-center flex-col">
                      <Button style={{ backgroundColor: '#038967', color: '#fff', width: 100, fontSize: '12px', marginLeft: 4 }} shape="round"  > เปลี่ยนรูป </Button>
                    </div>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div className="flex-col">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p>ชื่อโพสต์: * </p>
                  <Input
                    name="username"
                    placeholder="username" style={{ width: 600, marginBottom: 12 }}
                    value={Post.title}
                    onChange={(event) => {
                      setPost({ ...Post, ...{ title: `${event.target.value}` } })
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p>UrlLink: * </p>
                  <Input
                    name="username"
                    placeholder="username" style={{ width: 600, marginBottom: 12 }}
                    value={Post.url}
                    onChange={(event) => {
                      setPost({ ...Post, ...{ url: `${event.target.value}` } })
                    }}
                  />
                </div>
              </div>

            </div>
          </div>

        </Modal>

        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 24,
            display: "flex",
            width: "1350px",
            minHeight: "600px",
            alignSelf: "center",
            flexDirection: "column",
            // justifyContent: "center",
          }}
        >
          <div className="flex row">
            <p className="text-2xl">ข้อมูลข่าวสาร</p>
            <div className="flex flex-1"></div>
            <Button
              onClick={() => {
                setModalData({
                  visible: true,
                  title: 'โพสต์',
                })
                setImagePreview(img_default)
              }}
              shape='round'
              style={{
                width: 150,
                margin: 2,
                backgroundColor: "#308867",
                color: "#fff",
              }}
            >
              + เพื่มโพต์
            </Button>

          </div>

          <div className="flex-row flex justify-between mb-4">
            <div class="w-full">
              <Table
                style={{ fontFamily: "Kanit" }}
                // pagination={false}
                bordered
                size="middle"
                dataSource={data}
                columns={[
                  {
                    title: "ชื่อโพสต์",
                    dataIndex: "title",
                    key: "title",
                    width: 300,
                  },
                  {
                    title: "url link",
                    align: "center",
                    dataIndex: "url",
                    key: "url",
                    width: 10,
                    render: (_, data) => (
                      <a href={data.url} id="my_link" class="abc" target="_blank">Link</a>
                    ),
                  },
                  {
                    title: "แก้ข้อมูล",
                    align: "center",
                    dataIndex: "budget_source",
                    key: "budget_source",
                    width: 10,
                    render: (_, data) => (
                      <Button
                        shape='round'
                        onClick={async (type) => {
                          let filename = `${data.name_image}.png`
                          let url = `https://nursenu.pro/minio/getUrl/nurse-profile/${filename}`
                          let resUrlImage = await axios.get(url)
                          setModalData({
                            visible: true,
                            title: 'โพสต์',
                          })
                          setImagePreview(resUrlImage.data.url ?? img_default)
                          // setVisible(true)
                          setPost({ ...data })
                        }}
                        style={{
                          width: 150,
                          margin: 2,
                          backgroundColor: "#308867",
                          color: "#fff",
                        }}
                      >
                        แก้ไข
                      </Button>
                    ),
                  },

                ]}
                line
              />
            </div>
          </div>
        </div>
      </div >
    </Layout >
  );
}
