import React, { useEffect, useState, useCallback } from "react";
// import Head from "next/head";
// import Image from "next/image";
// import { Inter } from "next/font/google";
// import styles from "@/styles/Home.module.css";
import api from "../../util/api";
import Layout from '../../components/Layout';
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Progress,
  Space,
  Divider,
  Button,
} from "antd";
// import Link from "next/link";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
// import { useRouter } from "next/router";
// import nunurse32 from "../../images/logo-nurse.jpg";


import img_default from '../../images/img_default.png';

const rederButton = (key) => {
  return (_, data) => (
    <Space size="middle">{data.url && <a href={`${data.url}`}>Link</a>}</Space>
  );
};


const rederColRight = (key, keypage, textAlign = "right") => {
  return (_, data) => {
    return {
      props: {
        style: { textAlign: "right" },
      },
      children: (
        <p >
          {data[key]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p >
      ),
    };
  };
};

const MAP = {
  journal: "วารสาร (Journal)",
  proceeding: "ผลงานที่นำเสนอในที่ประชุมวิชาการ (Proceeding)",
  thai: "ระดับชาติ",
  international: "ระดับนานาชาติ",
};



const loadData = async (id) => {
  // let detail = await axios.post("/api/getProfileById", { _id: id });
  // let res = await axios.post("/api/getProfileAll", { staff_id: id });
  let profile = await api.query("db/nunurse/profile/query", {
    aggregate: [{
      $match: { _id: `${id}`, del: { $ne: true } },
    }],
  });


  let research = await api.query("db/nunurse/form_research/query", {
    aggregate: [
      {
        $match: { del: { $ne: true } },
      },
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $match: { 'list_person.id': `${id}` }
      }
    ],
  });

  let research_result = await api.query("db/nunurse/form_research_result/query", {
    aggregate: [
      {
        $match: { del: { $ne: true } },
      },
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $match: { 'list_person.id': `${id}` }
      }
    ],
  });


  let academic_service_project = await api.query("db/nunurse/form_academic_service_project/query", {
    aggregate: [
      {
        $match: { del: { $ne: true } },
      },
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $match: { 'list_person.id': `${id}` }
      }
    ]
  });

  let form_academic_service = await api.query("db/nunurse/form_academic_service/query", {
    aggregate: [
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $match: { 'list_person.id': `${id}` }
      }
    ]
  });


  let filename = `${id}.png`
  let url = `https://nursenu.pro/minio/getUrl/nurse-profile/${filename}`
  let resUrlImage = await axios.get(url)

  return {
    profile: profile[0],
    research: research,
    academic_service_project: academic_service_project,
    research_result: research_result,
    form_academic_service: form_academic_service,
    urlImage: resUrlImage.data.url
  };
};

const onCreateDataTable = (data = []) => {
  try {
    let result = data.map((x, i) => {
      let name = "";
      let ref = "";
      if (x.research_type === "journal") {
        if (x.year) ref = `${ref}(${x.year})`;
        // if (x.journal_title) ref = `${ref}. ${x.journal_title}`;
        if (x.journal_name) ref = `${ref}. ${x.journal_name}`;
        if (x.issue_number) ref = `${ref}, ${x.volume_number}(${x.issue_number})`;
        if (x.pages) ref = `${ref}, ${x.pages}.`;
      } else if (x.research_type === "proceeding") {
        if (x.year) ref = `${ref}(${x.year})`;
        // if (x.journal_title) ref = `${ref}. ${x.journal_title}`;
        if (x.journal_name) ref = `${ref}. ${x.journal_name}`;
        if (x.issue_number) ref = `${ref}, ${x.volume_number}(${x.issue_number})`;
        if (x.pages) ref = `${ref}, ${x.pages}.`;
      }

      return {
        ...x,
        ref,
        index: 1 + i,
        nameHead: name,
      };
    });
    return result
  } catch (err) {
    console.log('err', err)
  }
};

const ResearchDetail = (props) => {
  let id = props?.match?.params?.pid
  let [data, setData] = useState(null);
  let [dataTable, setDateTable] = useState([]);
  let [dataTableAcademicServiceProject, setDataTableAcademicServiceProject] = useState([]);
  let [dataTableResearch, setDataTableResearch] = useState([]);
  let [dataTableResearchResult, setDataTableResearchResult] = useState([]);
  let [dataTableAcademicService, setDataTableAcademicService] = useState([]);
  let [image, setImage] = useState(img_default);
  // const router = useRouter();
  // const { id } = router.query;
  useEffect(() => {
    if (id) {

      loadData(id).then(({ profile, research, academic_service_project, research_result, form_academic_service, urlImage }) => {
        setData(profile);
        // setDateTable(research);
        setDataTableResearch([...research]);
        setDataTableAcademicServiceProject([...academic_service_project]);
        setDataTableResearchResult(onCreateDataTable(research_result));
        setDataTableAcademicService([...form_academic_service]);
        setImage(urlImage)

      });
    }
  }, []);


  return (
    <Layout name={"academic_service"}>
      <div className=" bordered-lx p-12 felx w-[1350px] self-center">
        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
          }}
        >
          <Row>
            <Col span={4}>

              <img
                style={{ borderRadius: 8 }}
                alt={`description`}
                src={image}
                onError={event => {
                  event.target.src = img_default
                  event.onerror = null
                }}
                width="150px"
                height="90px"
              />
              {/* {mapImage[id] && (
            <Image
              src={mapImage[id]}
              alt="A logo"
              width={150}
              height={200}
              style={{ borderRadius: "4px" }}
              placeholder="blur"
            />
          )} */}
            </Col>
            <Col span={186}>
              {/* <div style={{ fontSize: 20 }} level={2}>
                รหัสอาจารย์/เจ้าหน้าที่ :{` ${data?._id}`}
              </div> */}
              <div style={{ fontSize: 20 }} level={2}>
                ชื่อ - นามสกุล :
                {` ${data?.title}${data?.firstName} ${data?.lastName}`}
              </div>
              <div style={{ fontSize: 20 }} level={2}>
                สังกัด :{`${data?.group}`}
              </div>
              <div style={{ fontSize: 20 }} level={2}>
                ตำแหน่ง :{`${data?.position}`}
              </div>
              <div style={{ fontSize: 20 }} level={2}>
                โทรศัพท์ :{`${data?.tel}`}
              </div>
              <div style={{ fontSize: 20 }} level={2}>
                Email :{`${data?.email}`}
              </div>
            </Col>
          </Row>
          <div style={{ flex: 1, fontSize: 24, marginTop: 32 }}>โครงการวิจัย</div>
          <div
            style={{
              height: "4px",
              marginBottom: 4,
              width: "40%",
              backgroundColor: "#FF6600",
            }}
          ></div>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={dataTableResearch.
              sort(
                (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0)
              .map((x, i) => ({ ...x, index: i + 1 }))}
            columns={[
              {
                title: "ลำดับ",
                dataIndex: "index",
                key: "index",
                width: 20,
              },
              {
                title: "ปี",
                dataIndex: "year",
                key: "year",
                width: 20,
              },
              {
                dataIndex: "project_name_th",
                key: "project_name_th",
                title: "ชื่อโครงการวิจัย",
                width: 200,
              },
              // {
              //   title: "ประธาน",
              //   dataIndex: "nameHead",
              //   key: "nameHead",
              //   width: 80,
              // },
              {
                title: "งบประมาณ",
                render: rederColRight('total_budget_received'),
                // dataIndex: "total_budget_received",
                // key: "total_budget_received",
                width: 60,
              },
              {
                title: "แหล่งทุน",
                dataIndex: "budget_source",
                key: "budget_source",
                width: 100,
              },
            ]}
            line
          />
          <div style={{ flex: 1, fontSize: 24, marginTop: 48 }}>
            ผลงานวิจัย/ตีพิมพ์/นำเสนอ
          </div>
          <div
            style={{
              height: "4px",
              marginBottom: 4,
              width: "40%",
              backgroundColor: "#FF6600",
            }}
          ></div>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={dataTableResearchResult.
              sort(
                (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0)
              .map((x, i) => ({ ...x, index: i + 1 }))}
            columns={[
              {
                title: "ลำดับ",
                dataIndex: "index",
                key: "index",
                align: 'center',
                width: 60,
              },
              {
                title: "ปี",
                dataIndex: "year",
                key: "year",
                width: 20,
              },
              {
                title: "ประเภทผลงาน",
                // dataIndex: "research_type",
                // key: "research_type",
                render: (_, { research_type, research_class }) => {
                  return (
                    <div>
                      <p>{`${research_type} ${research_class ? "(" + MAP[research_class] + ")" : ''}`}</p>
                    </div>
                  )
                },
                width: 180,
              },
              // {
              //   title: "ชื่อผู้แต่ง",
              //   render: (_, { list_person }) => (
              //     <div>
              //       {
              //         // http://localhost:3000/profile_position/detail/nunurse4
              //         Array.isArray(list_person)
              //           ? list_person?.map((x) => {
              //             return (
              //               <a
              //                 href={`/profile_position/detail/${x.id}`}
              //                 key={x.id}
              //               >{`${x.name}, `}</a>
              //             );
              //           })
              //           : null
              //       }
              //     </div>
              //   ),
              //   width: 230,
              // },
              {
                title: "ชื่อวารสาร/ชื่อการประชุมวิชาการ",
                dataIndex: "journal_title",
                key: "journal_title",
                // render: rederColRight("journal_title"),
              },
              {
                title: "อ้างอิง",
                dataIndex: "ref",
                key: "ref",
                width: 300,
              },
              {
                title: "ฐานข้อมูล",
                dataIndex: "db",
                key: "db",
                width: 120,
              },
              {
                title: "บทคัดย่อ",
                render: rederButton("url"),
                width: 40,
              },
            ]}
            line
          />
          <div style={{ flex: 1, fontSize: 24, marginTop: 48 }}>
            โครงการบริการวิชาการ
          </div>
          <div
            style={{
              height: "4px",
              marginBottom: 4,
              width: "40%",
              backgroundColor: "#FF6600",
            }}
          ></div>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={dataTableAcademicServiceProject.
              sort(
                (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0)
              .map((x, i) => ({ ...x, index: i + 1 }))}
            columns={[
              {
                title: "ลำดับ",
                dataIndex: "index",
                key: "index",
                width: 20,
              },
              {
                title: "ปี",
                dataIndex: "year",
                key: "year",
                width: 20,
              },
              {
                dataIndex: "project_name_th",
                key: "project_name_th",
                title: "ชื่อโครงการบริการวิชาการ",
                width: 200,
              },
              // {
              //   title: "ประธาน",
              //   dataIndex: "nameHead",
              //   key: "nameHead",
              //   width: 80,
              // },
              {
                title: "งบประมาณ",
                render: rederColRight('total_budget_received'),
                // dataIndex: "total_budget_received",
                // key: "total_budget_received",
                width: 60,
              },
              {
                title: "แหล่งทุน",
                dataIndex: "budget_source",
                key: "budget_source",
                width: 100,
              },
            ]}
            line
          />
          <div style={{ flex: 1, fontSize: 24, marginTop: 48 }}>
            การให้บริการวิชาการ
          </div>
          <div
            style={{
              height: "4px",
              marginBottom: 4,
              width: "40%",
              backgroundColor: "#FF6600",
            }}
          ></div>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={dataTableAcademicService.
              sort(
                (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0)
              .map((x, i) => ({ ...x, index: i + 1 }))}
            columns={[
              {
                title: "No.",
                dataIndex: "index",
                key: "index",
                width: 20,
              },
              {
                title: "หัวข้อเรื่อง",
                dataIndex: "topic",
                key: "topic",
                // render: rederColRight("topic"),
                width: 200,
              },
              {
                title: "ชื่อหน่วยงานที่จัด",
                dataIndex: "location",
                key: "location",
                width: 80,
              },
              {
                title: "ประเภทของผู้ให้บริการ",
                dataIndex: "service_type",
                key: "service_type",
                width: 60,
              },
            ]}
            line
          />
        </div>
      </div>
    </Layout>
  );
};
export default ResearchDetail;
