import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import dayjs from 'dayjs';
import SweetAlert from "sweetalert2-react";
import {
  DatePicker,
  Divider,
  Input,
  Select,
  Typography,
  Button,
  InputNumber,
  Modal,
  Space,
  Alert,
  Row,
  Table,
  Breadcrumb,
} from "antd";
import api from "../../util/api";
import { v4 } from "uuid";
import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";
const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const fecthData = async (id = null) => {
  try {
    let profileform = await api.query("db/nunurse/profile/query", {
      aggregate: [],
    });

    let agency = await api.query("db/nunurse/agency/query", {
      aggregate: [],
    });

    let form = null

    if (id) {
      form = await api.query("db/nunurse/form_academic_service/query", {
        aggregate: [
          {
            $match: { _id: `${id}` },
          },
        ],
      });

    }

    return {
      agency,
      profileform,
      form
    };
  } catch (err) {
    console.log("error: " + err);
  }
};


const columnsProfile = [
  {
    title: "ลำดับ",
    dataIndex: "key",
    key: "key",
    align: "center",
    width: 100,
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "จำนวนชั่วโมงที่ให้บริการ",
    align: "center",
    dataIndex: "hour",
    key: "hour",
    width: 200,
  },
  {
    title: "ลบ",
    align: "center",
    dataIndex: "budget_source",
    key: "budget_source",
    render: (_, data) => (

      <Button
        onClick={data.onRemove}
        style={{
          width: 100,
          margin: 2,
          backgroundColor: "red",
          color: "white",
        }}
      >
        ลบ
      </Button>
    ),
    width: 100,
  },
];

const default_modal_person = {
  name: null,
  title: null,
  firstName: null,
  lastName: null,
  id: null,
  positon: null,
  percent: null,
  hour: 0,
  group: null,
}

const default_form = {
  _id: null,
  year: null,
  list_person: [],
  group: null,
  location: null,
  location_type: null,
  service_type: null,
  start_date: null,
  end_date: null,
  topic: null,
  create_date: null,
  times: null,
  timestamp: null,
  create_by: null,
}

export default function Research(props) {
  let history = useHistory();
  let form_id = props?.match?.params?.id;
  let [profiles, setProfiles] = useState([]);
  let [addModelPersonInTabel, setAddModelPersonInTabel] = useState({ ...default_modal_person });
  let [dataTableListProfile, setDateTableListProfile] = useState([]);
  let [errMessagesModal1, setErrorsModal1] = useState(null);
  let [form, setForm] = useState({ ...default_form });
  let [isModalOpen3, setIsModalOpen3] = useState(false);

  const [show, setShow] = useState({
    show: false,
    title: "",
    message: "",
  });

  const onChangeSelect = (value, id) => {
    setForm({
      ...form,
      [`${id}`]: value,
    });
  };

  const onChangeTextInput = (e) => {
    let { id, value } = e.target;
    if (id !== undefined && value !== undefined) {
      setForm({
        ...form,
        [`${id}`]: value,
      });
    }
  };

  const onFinish = async (message) => {
    try {

      if (!form.year)
        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณากรอบข้อมูลปีงบประมาณ' } });

      if (dataTableListProfile.length === 0)
        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ผู้ให้บริการวิชาการอย่างน้อย 1 คน' } });

      if (!form.topic)
        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่หัวข้อเรื่อง' } });


      if (!form?._id) {
        form._id = v4().toString().replace(/-/g, "");
      }
      form.list_person = [...dataTableListProfile];
      await api.insert(
        `db/nunurse/form_academic_service/data/${form._id}`,
        form
      );
      message(form._id);
    } catch (err) {
      console.log(">> err", err);
    }
  };

  const [ModalMessage, setModalMessage] = useState({
    show: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    fecthData(form_id).then(({ agency, profileform, form }) => {
      // setJointAgency(agency);
      setProfiles(profileform);
      if (form.length > 0) {
        setForm({ ...form[0] });
        setDateTableListProfile([...form[0].list_person])
      }
    });
  }, []);

  return (
    <Layout>
      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } });
        }}
      />

      {/*  เพิ่มรายชื่อคณะผู้วิจัย */}
      <Modal
        title="เพิ่มรายชื่อผู้ให้บริการวิชาการ"
        centered
        width={800}
        okText={"บันทึก"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpen3}
        onOk={async () => {
          try {

            let person = { ...addModelPersonInTabel }
            if (person.hour < 1) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่จำนวนชั่วโมงที่ให้บริการอย่างน้อย 1 ชั่วโมง' } });
            }

            if (
              person.hour === 0 ||
              person.title === null ||
              person.firstName === null ||
              person.lastName === null
            ) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณากรอบข้อมูลให้ครบ' } });
            }


            setDateTableListProfile([
              ...dataTableListProfile,
              addModelPersonInTabel,
            ]);
            setAddModelPersonInTabel({ ...default_modal_person });
            setIsModalOpen3(false);
          } catch (err) {
            console.log("err", err);
          }
        }}
        onCancel={() => {
          setAddModelPersonInTabel({ ...default_modal_person });
          setIsModalOpen3(false);
        }}
      >
        <div className="flex flex-col">
          <Space style={{ marginBottom: 12 }}>
            <p>ชื่อ-นามสกุล :</p>
            <Select
              style={{ width: 440 }}
              value={addModelPersonInTabel.name}
              placeholder=""
              showSearch
              onChange={(value) => {
                let item = profiles.find(
                  (x) => `${x.title}${x.firstName} ${x.lastName}` === value
                );
                if (item) {
                  setAddModelPersonInTabel({
                    ...addModelPersonInTabel,
                    name: `${item.title}${item.firstName} ${item.lastName}`,
                    title: item.title,
                    firstName: item.firstName,
                    group: item.group,
                    lastName: item.lastName,
                    id: item._id,
                  });
                }
              }}
            >
              {profiles.map((x) => (
                <Option
                  key={`${x.title}${x.firstName} ${x.lastName}`}
                  value={`${x.title}${x.firstName} ${x.lastName}`}
                >{`${x.title}${x.firstName} ${x.lastName}`}</Option>
              ))}
            </Select>
          </Space>

          <Space>
            <p>จำนวนชั่วโมงที่ให้บริการ :</p>
            <InputNumber
              style={{ width: 200 }}
              value={addModelPersonInTabel.hour}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(value) => {
                setAddModelPersonInTabel({
                  ...addModelPersonInTabel,
                  hour: value >= 0 ? value : 0,
                });
              }}
            />
          </Space>
        </div>

        {/* addAgency, setAddAgenc */}
        {errMessagesModal1 && (
          <Alert
            message={errMessagesModal1}
            type="error"
            style={{ marginTop: 12 }}
          />
        )}
        <Divider style={{ margin: 12 }} />
      </Modal>

      <Modal
        title={null}
        centered
        width={400}
        okText={"บันทึก"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={ModalMessage.show}
        onOk={() => {
          onFinish((_id) => {
            history.push("/AcademicService/detail/" + _id);
          });
          setModalMessage({ ...ModalMessage, show: false, title: "" });
        }}
        onCancel={() => {
          setModalMessage({ ...ModalMessage, show: false, title: "" });
        }}
      >
        <div
          style={{ width: "100%", flex: 1, display: "flex", marginBottom: 12 }}
        >
          <p className="text-2xl">คุณต้องการบันทึกข้อมูลหรือไม่</p>
        </div>
      </Modal>

      <div className=" bordered-lx p-12 felx w-[1350px] self-center">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            การบริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            แบบฟอร์มบันทึกการให้บริการวิชาการ
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
          }}
        >
          <Text style={{ fontSize: 24, fontWeight: "normal" }}>
            แบบฟอร์มบันทึกการให้บริการวิชาการ
          </Text>
          <Divider />
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>*ปีงบประมาณ :</Text>
              <Select
                style={{ width: 400 }}
                value={form.year}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "year")}
              >
                <Option value="2566">2566</Option>
                <Option value="2565">2565</Option>
                <Option value="2564">2564</Option>
                <Option value="2563">2563</Option>
                <Option value="2562">2562</Option>
              </Select>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>ชื่อหน่วยงานที่จัด :</Text>
              <Input
                value={form.location}
                placeholder=""
                id="location"
                maxLength={50}
                style={{ width: 400 }}
                onChange={onChangeTextInput}
              />
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>ประเภทหน่วยงานที่จัด :</Text>
              <Select
                style={{ width: 400 }}
                value={form.location_type}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "location_type")}
              >
                <Option value="ภายนอก">ภายนอก</Option>
                <Option value="ภายใน">ภายใน</Option>
              </Select>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>ประเภทของผู้ให้บริการ :</Text>
              <Select
                style={{ width: 400 }}
                value={form.service_type}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "service_type")}
              >
                <Option value="วิทยากร">วิทยากร</Option>
                <Option value="อ่านบทความ (ไทย)">อ่านบทความ (ไทย)</Option>
                <Option value="อ่านบทความ (อังกฤษ)">อ่านบทความ (อังกฤษ)</Option>
                <Option value="ตรวจเครื่องมือวิจัย">ตรวจเครื่องมือวิจัย</Option>
                <Option value="กรรมการสอบวิทยานิพนธ์ภายนอก">
                  กรรมการสอบวิทยานิพนธ์ภายนอก
                </Option>
                <Option value="บริการวิชาชีพ">บริการวิชาชีพ</Option>
                <Option value="กรรมการหน่วยงานภายนอก">
                  กรรมการหน่วยงานภายนอก
                </Option>
                <Option value=">อื่น ๆ">อื่น ๆ</Option>
              </Select>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>วันเวลาที่เชิญ :</Text>
              <RangePicker
                // value={[moment(form?.start_date, 'x'), moment(form?.end_date, 'x')]}
                onChange={(_date, dateString) => {
                  if (Array.isArray(_date) && _date.length >= 2) {
                    _date[0].set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    });
                    _date[1].set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    });
                    _date[0].toISOString();
                    _date[1].toISOString();
                    setForm({
                      ...form,
                      end_date: Number(_date[0].format("x")),
                      start_date: Number(_date[1].format("x")),
                    });
                  } else {
                    setForm({ ...form, end_date: null, start_date: null });
                    // setDate(null)
                    // setDate2(null)
                  }
                }}
              />
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>หัวข้อเรื่อง :</Text>
              <Input
                value={form.topic}
                placeholder=""
                id="topic"
                style={{ width: 800 }}
                onChange={onChangeTextInput}
              />
            </div>
          </Row>

          <div style={{ marginTop: 24 }}>
            <Table
              footer={() => {
                return (
                  <Button
                    onClick={() => setIsModalOpen3(true)}
                    style={{ backgroundColor: "green", color: "#fff" }}
                  >
                    เพิ่มรายชื่อผู้ให้บริการวิชาการ
                  </Button>
                );
              }}
              bordered
              size="middle"
              dataSource={dataTableListProfile.map((x, i) => ({
                ...x,
                key: i + 1,
                onRemove: () => {
                  let _new = dataTableListProfile.splice(i + 1, 1)
                  setDateTableListProfile([..._new])
                },
              }))}
              columns={columnsProfile}
              line
            />
          </div>
        </div>

        <div className="flex justify-end">
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              height: 50,
              marginTop: 24,
              width: 200,
              fontSize: 24,
            }}
            size="large"
            onClick={() =>
              setModalMessage({ ...ModalMessage, show: true, title: "xxxxx2" })
            }
          >
            บันทึกข้อมูล
          </Button>
        </div>
      </div>
    </Layout>
  );
}
