import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Progress,
  Space,
  Button,
  Breadcrumb,
} from "antd";
import api from "../../util/api";
import Box from "@mui/material/Box";
import "moment/locale/th";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const MAP_GROUP = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
};

const MAP_TYPE = {
  speaker: "วิทยากร",
  article_th: "อ่านบทความ (ไทย)",
  article_en: "อ่านบทความ (อังกฤษ)",
  check_tools: "ตรวจเครื่องมือวิจัย",
  director_thesis: "กรรมการสอบวิทยานิพนธ์ภายนอก",
  director: "บริการวิชาชีพ",
  service: "กรรมการหน่วยงานภายนอก",
  other: "อื่น ๆ",
};

const rederColRight = (key) => {
  return (_, data) => (
    <Space size="middle">
      <a href={`/AcademicService/detail/${data._id}`}>{data[key]}</a>
    </Space>
  );
};

const fetchData = async () => {
  try {
    let aggregate = [
      {
        $match: { del: { $ne: true } }
      },
    ];

    let _data = await api.query("db/nunurse/form_academic_service/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const onCreateDataTable = (data = [], year) => {
  return data.map((x, i) => {
    let name = "";
    let item = x?.list_person?.find((x) => x.positon === "ประธาน");
    if (item) {
      name = `${item.title}${item.firstName} ${item.lastName}`;
    }
    return {
      ...x,
      key: 1 + i,
      nameHead: name,
    };
  });
};

export default function Home(props) {
  let history = useHistory();

  let year = props?.match?.params?.year;
  let group = props?.match?.params?.group;
  let type = props?.match?.params?.type;

  let [data, setData] = useState([]);
  let [selectYear, setSelectYear] = useState(year);
  let [selectGroup, setSelectGroup] = useState(group);
  let [selectServiceType, setSelectServiceType] = useState(type);
  let [dateTable, setDateTable] = useState();

  React.useEffect(() => {

    let functionfilter = () => { };
    if (selectGroup === "all" && selectServiceType === "all") {
      functionfilter = (item) => item.year === selectYear;
    } else if (selectServiceType === "all") {
      functionfilter = (item) =>
        item.year === selectYear && item.group === MAP_GROUP[selectGroup];
    } else if (selectGroup === "all") {
      functionfilter = (item) =>
        item.year === selectYear &&
        item.service_type === MAP_TYPE[selectServiceType];
    } else {
      functionfilter = (item) =>
        item.year === selectYear &&
        item.budget_source === MAP_TYPE[selectServiceType] &&
        item.group === MAP_GROUP[selectGroup];
    }

    console.log("selectYear", selectYear)
    console.log("selectServiceType", selectServiceType)
    console.log("selectGroup", selectGroup)
    console.log("data", data)
    let _data = data.filter(functionfilter);
    setDateTable(onCreateDataTable(_data, selectYear));
  }, [data, selectYear, selectServiceType, selectGroup]);

  React.useEffect(() => {
    fetchData().then((res) => {
      setData(res);
    });
  }, []);

  console.log("dateTable", dateTable);
  console.log("data", data);

  return (
    <Layout>
      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          maxWidth: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            การบริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            ตารางแสดงการให้บริการวิชาการ
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 24,
            minHeight: "600px",
            display: "flex",
            width: "1350px",
          }}
        >
          {/* detail */}

          <div
            style={{ flexDirection: "column", display: "flex", width: "100%" }}
          >
            <div className="flex-row flex justify-between ">
              {/* space-between */}
              <p class="text-center text-3xl"> ตารางแสดงการให้บริการวิชาการ</p>
            </div>

            <Space wrap style={{ marginTop: 16, marginBottom: 16 }}>
              {/* <Select
                value={selectGroup}
                style={{ width: 300 }}
                options={[
                  { value: "all", label: "คณะพยาบาลศาสตร์" },
                  {
                    value: "mother_and_infant",
                    label: "กลุ่มวิชาการพยาบาลมารดาและทารก",
                  },
                  { value: "child", label: "กลุ่มวิชาการพยาบาลเด็ก" },
                  { value: "adult", label: "กลุ่มวิชาการพยาบาลผู้ใหญ่" },
                  {
                    value: "geriatric",
                    label: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
                  },
                  { value: "community", label: "กลุ่มวิชาการพยาบาลชุมชน" },
                  {
                    value: "psychiatric",
                    label: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
                  },
                  {
                    value: "administartion_professional",
                    label: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
                  },
                ]}
                onChange={(value) => {
                  setSelectGroup(value);
                }}
              /> */}

              {/* <Select
                style={{ width: 120 }}
                defaultValue={"ปีงบประมาณ"}
                options={[
                  { label: "ปีงบประมาณ", value: "ปีงบประมาณ" },
                  { label: "ปีปฏิทิน", value: "ปีปฏิทิน" },
                ]}
              /> */}
              <Select
                style={{ width: 200 }}
                value={selectServiceType}
                options={[
                  { label: "บริการวิชาการทั้งหมด", value: "all" },
                  { label: "วิทยากร", value: "speaker" },
                  { label: "อ่านบทความ (ไทย)", value: "article_th" },
                  { label: "อ่านบทความ (อังกฤษ)", value: "article_en" },
                  { label: "ตรวจเครื่องมือวิจัย", value: "check_tools" },
                  {
                    label: "กรรมการสอบวิทยานิพนธ์ภายนอก",
                    value: "director_thesis",
                  },
                  { label: "บริการวิชาชีพ", value: "director" },
                  { label: "กรรมการหน่วยงานภายนอก", value: "service" },
                  { label: "อื่น ๆ", value: "other" },
                ]}
                onChange={(value) => {
                  setSelectServiceType(value);
                }}
              />

              <Select
                style={{ width: 80 }}
                value={selectYear}
                options={[
                  { label: "2566", value: "2566" },
                  { label: "2565", value: "2565" },
                  { label: "2564", value: "2564" },
                  { label: "2563", value: "2563" },
                  { label: "2562", value: "2562" },
                ]}
                onChange={(value) => {
                  setSelectYear(value);
                }}
              />
            </Space>
            {/* ตำแหน่งทางวิชาการ */}
            <div className="flex flex-row ">
              <div class="w-full ... ">
                <Table
                  style={{ fontFamily: "Kanit" }}
                  pagination={false}
                  bordered
                  size="middle"
                  dataSource={dateTable}
                  columns={[
                    {
                      title: "No.",
                      dataIndex: "key",
                      key: "key",
                      width: 20,
                    },
                    {
                      title: "หัวข้อเรื่อง",
                      render: rederColRight("topic"),
                      width: 200,
                    },
                    {
                      title: "ชื่อผู้ให้บริการวิชาการ ",
                      dataIndex: "list_person",
                      key: "list_person",
                      render: (_, { list_person }) => (
                        <div>
                          {
                            // http://localhost:3000/profile_position/detail/nunurse4
                            Array.isArray(list_person)
                              ? list_person?.map((x) => {
                                return (
                                  <a
                                    href={`/profilePosition/detail/${x.id}`}
                                    key={x.id}
                                  >{`${x.name}, `}</a>
                                );
                              })
                              : null
                          }
                        </div>
                      ),
                      // reader("topic"),
                      width: 100,
                    },
                    {
                      title: "ชื่อหน่วยงานที่จัด",
                      dataIndex: "location",
                      key: "location",
                      width: 80,
                    },
                    {
                      title: "ประเภทของผู้ให้บริการ",
                      dataIndex: "service_type",
                      key: "service_type",
                      width: 60,
                    },
                  ]}
                  line
                />
                {/* data.table1 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
