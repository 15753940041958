import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  Button,
  Breadcrumb,
  Input,
  Modal,
  Switch,
  Upload
} from "antd";

import ImgCrop from 'antd-img-crop';
import axios from "axios";
import api from "../../util/api";
import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import SweetAlert from 'sweetalert2-react';

import img_default from '../../images/img_default.png';

import "moment/locale/th";
const { Title, Text } = Typography;
const { Option } = Select;
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const getBase64 = (img, callback) => {
  try {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  } catch (err) {
    console.log("err: ", err)
  }
};

const USER = {
  "title": "",
  "firstname": "",
  "lastname": "",
  "disable": false,
  "group": "",
  "position": "",
  "tel": "",
  "email": null,
  "room": null,
  "role": "",
}

const raderRole = (data) => {
  let _role = '-'
  if (Array.isArray(data?.roles) && data?.roles.length > 0) {
    if (data?.roles[0] === 'doctor') {
      _role = 'หมอ'
    } else if (data?.roles[0] === 'SHPH') {
      _role = 'SHPH'
    } else if (data?.roles[0] === 'patient') {
      _role = 'ผู้ป่วย'
    } else if (data?.roles[0] === 'admin') {
      _role = 'admin'
    }

    return _role
  }
}

export default function AcademicServiceProject(props) {
  let [isLogin, setLogin] = useState(false);
  let [image, setImage] = useState(img_default);
  let [imagePreview, setImagePreview] = useState(img_default);
  let [fileImage, setFileImage] = useState([]);

  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addUser, setAddUser] = useState({ ...USER });

  const [visibleEdit, setVisibleEdit] = useState(false);

  const [visiblePass, setVisiblePass] = useState(false);

  const [dataEdit, setDataEdit] = useState({
    user: ''
  });

  const [pass, setPass] = useState({
    user: '',
    pass: '',
    confirmPass: ''
  });

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }

  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    role = JSON.parse(role)
    let aggregate = []
    // "application" : "nunurse",
    // let _data = await api.query('db/cores/role/query', { query: { _id: { $ne: 'adminmaesot' } } })
    let _data = await api.query('db/cores/role/query', {
      "aggregate": [
        {
          $match: {
            roles: { $in: ['doctor', 'SHPH'] },
            application: "nunurse",
          }
        }
      ]
    })

    setData(_data.map(x => ({
      ...x,
      name: `${x?.profile?.title}${x?.profile?.firstname} ${x?.profile?.lastname}`,
      hospital: `${x?.profile?.hospital}`,
      disable: x.disable ? 'disable' : 'active',
      role: raderRole(x)
    })))
    // roles

  }, []);

  React.useEffect(() => {
    let user = localStorage.getItem("user_nurse");
    let userdata = localStorage.getItem("user_data");
    let role = localStorage.getItem("role");
    if (user && userdata) {
      setLogin(true);
    }
  }, []);


  return (
    <Layout>
      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          padding: 12,
          // padding: "18px",
          display: "flex",
          maxWidth: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <SweetAlert
          show={show.show}
          title={show.title}
          text={show.message}
          onConfirm={() => {
            setShow({ ...show, ...{ show: false } })
          }}
        />

        <Modal
          title="เพิ่มผู้ใช้งาน"
          centered
          visible={visible}
          onOk={async () => {
            try {
              if (!addUser.user || addUser.user.length < 8) return setAlert('โปรดใส่ username อย่างน้อย 6 ตัว')
              if (!addUser.pass || addUser.pass.length < 8) return setAlert('โปรดใส่ password อย่างน้อย 6 ตัว')
              if (!addUser.title) return setAlert('โปรดเลือกคำนำหน้า')
              if (!addUser.firstname) return setAlert('โปรดใส่ชื่อ')
              if (!addUser.lastname) return setAlert('โปรดใส่นามสกุล')
              if (!addUser.position) return setAlert('โปรดใส่ตำแหน่ง')
              if (!addUser.group) return setAlert('โปรดใส่กลุ่มวิชาการ')

              let _profile = {
                "_id": addUser.user,
                "title": addUser.title,
                "firstName": addUser.firstname,
                "lastName": addUser.lastname,
                "tel": addUser.tel,
                "email": addUser.email,
                "group": addUser.group,
                "position": addUser.position,
                "active": true,
                "room": addUser.room,
              }

              for (let i = 0; i < fileImage.length; i++) {
                let url = 'https://nursenu.pro/minio/presignedUrl/' + 'nurse-profile' + '?name=' + addUser.user + '.png'
                let urlForPut = await axios.get(url)
                let res = await axios.put(urlForPut.data, fileImage[i])
              }

              await api.insert(`db/nunurse/profile/data/${_profile._id}`, _profile)
              await api.register(
                {
                  "user": addUser.user,
                  "pass": addUser.pass,
                  "application": "nunurse",
                  "profile": {
                    "title": addUser.title,
                    "firstname": addUser.firstname,
                    "lastname": addUser.lastname,
                    "disable": false,
                    "group": addUser.group,
                    "position": addUser.position,
                    "tel": addUser.tel,
                    "email": addUser.email,
                    "room": addUser.room,
                  },
                  "roles": ["doctor"]
                },
                {
                  ...addUser,
                  // "hospital_code": _hospital.hospital_code,
                }
              )
              let _dataq = await api.query('db/cores/role/query', {
                "aggregate": [
                  {
                    $match: {
                      user: addUser.user,
                    }
                  }
                ]
              })

              _dataq = _dataq.map(x => ({
                ...x,
                name: `${x?.profile?.title}${x?.profile?.firstname} ${x?.profile?.lastname}`,
                hospital: `${x?.profile?.hospital}`,
                role: raderRole(x),
                disable: x.disable ? 'disable' : 'active',
              }))

              setData([
                ..._dataq,
                ...data
              ])
              setAddUser({ ...USER })
              setVisible(false)
              setImagePreview(img_default)
              setAlert('เพิ่มผู้ใช้งานสำเร็จ')
            } catch (err) {
              if (err.message) {
                setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: err.message } })
              } else {
                setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: err } })
              }
            }


          }}
          onCancel={() => {
            setAddUser({ ...USER })
            setVisible(false)
          }}
          width={800}
          okText={'บันทึก'}
          okButtonProps={{ style: { background: 'green' } }}
          cancelText={'ยกเลิก'}
        >

          <div className="flex flex-row">
            <div className="mt-2 flex-col">
              {imagePreview ? <img
                src={imagePreview}
                onError={event => {
                  event.target.src = img_default
                  event.onerror = null
                }}
                alt="avatar" style={{ width: 120, margin: 4, borderRadius: '4px' }} /> : null}
              <div className="w-full">
                <ImgCrop
                  style={{ width: '100%' }}
                  modalTitle='เลือกรูปภาพ'
                  quality={1}
                  aspect={6 / 9}
                  modalOk='ตกลง'
                  modalCancel="ยกเลิก"
                  modalProps={{ okButtonProps: { style: { background: 'green' } } }}
                >
                  <Upload
                    name="avatar"
                    style={{ width: '100%' }}
                    className="avatar-uploader"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    showUploadList={false}
                    fileList={fileImage}
                    beforeUpload={(file) => {
                      getBase64(file, (url) => {
                        setImagePreview(url)
                        setFileImage([file])
                      });
                      return false;
                    }}
                  >
                    <div className="w-full p-2 flex justify-center flex-col">
                      <Button style={{ backgroundColor: '#038967', color: '#fff', width: 100, fontSize: '12px', marginLeft: 4 }} shape="round"  > เปลี่ยนรูป </Button>
                    </div>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div className="flex-col">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p>username: * </p>
                  <Input
                    name="username"
                    placeholder="username" style={{ width: 200, marginBottom: 12 }}
                    value={addUser.user}
                    onChange={(event) => {
                      setAddUser({ ...addUser, ...{ user: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p>password: * </p>
                  <Input
                    name="password"
                    placeholder="password" style={{ width: 200, marginBottom: 12 }}
                    value={addUser.pass}
                    onChange={(event) => {
                      setAddUser({ ...addUser, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> คำนำหน้า: *</p>
                  <Select

                    placeholder="เลือกคำนำหน้า"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      setAddUser({ ...addUser, ...{ title: e } })
                    }}
                    value={addUser.title}
                  >
                    <Option value="ศ.">ศ.</Option>
                    <Option value="รศ.ดร.">รศ.ดร.</Option>
                    <Option value="ผศ.ดร.">ผศ.ดร.</Option>
                    <Option value="ผศ">ผศ.</Option>
                    <Option value="ดร.">ดร.</Option>
                    <Option value="อ.">อ.</Option>
                    <Option value="นาย">นาย</Option>
                    <Option value="นาง">นาง</Option>
                    <Option value="นางสาว">นางสาว</Option>
                  </Select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> ชื่อ: *</p>
                  <Input
                    placeholder="ชื่อ" style={{ width: 200, marginBottom: 12 }}
                    value={addUser.firstname}
                    onChange={(event) => {
                      setAddUser({ ...addUser, ...{ firstname: `${event.target.value}`.replace(/\s/g, '') } })
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> นามสกุล: *</p>
                  <Input
                    placeholder="นามสกุล"
                    style={{ width: 200, marginBottom: 12 }}
                    value={addUser.lastname}
                    onChange={(event) => {
                      setAddUser({ ...addUser, ...{ lastname: `${event.target.value}`.replace(/\s/g, '') } })
                    }}
                  />
                </div>
              </div>


              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  กลุ่มวิชาการ: *
                  <Select
                    placeholder="โปรดเลือกโรงพยาบาล/รพสต."
                    style={{ width: 300 }}
                    onChange={(e) => {
                      setAddUser({ ...addUser, ...{ group: e } })
                    }}
                    value={addUser.group}
                  >
                    <Option value={'กลุ่มวิชาการพยาบาลมารดาและทารก'}>{'กลุ่มวิชาการพยาบาลมารดาและทารก'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลเด็ก'}>{'กลุ่มวิชาการพยาบาลเด็ก'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลผู้ใหญ่'}>{'กลุ่มวิชาการพยาบาลผู้ใหญ่'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลผู้สูงอายุ'}>{'กลุ่มวิชาการพยาบาลผู้สูงอายุ'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลชุมชน'}>{'กลุ่มวิชาการพยาบาลชุมชน'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช'}>{'กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช'}</Option>
                    <Option value={'กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ'}>{'กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ'}</Option>
                  </Select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  ตำแหน่ง *
                  <Select
                    placeholder="โปรดเลือกโรงพยาบาล/รพสต."
                    style={{ width: 300 }}
                    onChange={(e) => {
                      setAddUser({ ...addUser, ...{ position: e } })
                    }}
                    value={addUser.position}
                  >
                    <Option value={'อาจารย์'}>{'อาจารย์'}</Option>
                    <Option value={'ผู้ช่วยศาสตราจารย์'}>{'ผู้ช่วยศาสตราจารย์'}</Option>
                    <Option value={'รองศาสตราจารย์'}>{'รองศาสตราจารย์'}</Option>
                    <Option value={'ศาสตราจารย์'}>{'ศาสตราจารย์'}</Option>
                  </Select>
                </div>

                {/* <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
              <p> เลขที่ใบอนุญาต: </p>
              <Input
                placeholder="ระบุเลขที่ใบอนุญาต" style={{ width: 200, marginBottom: 12 }}
                onChange={(event) => {
                  setAddUser({ ...addUser, ...{ certid: event.target.value } })
                }}
              />
            </div> */}
              </div>


              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> email: </p>
                  <Input
                    value={addUser.email}
                    placeholder="ระบุ email" style={{ width: 200, marginBottom: 12 }}
                    onChange={(event) => {
                      setAddUser({ ...addUser, ...{ email: event.target.value } })
                    }}
                  />
                </div>


                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> โทรศัพท์: </p>
                  <Input
                    value={addUser.tel}
                    placeholder="ระบุ โทรศัพท์" style={{ width: 200, marginBottom: 12 }}
                    onChange={(event) => {
                      setAddUser({ ...addUser, ...{ tel: event.target.value } })
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> ห้อง: </p>
                  <Input
                    value={addUser.room}
                    placeholder="ระบุห้อง" style={{ width: 200, marginBottom: 12 }}
                    onChange={(event) => {
                      setAddUser({ ...addUser, ...{ room: event.target.value } })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>


        </Modal>


        <Modal
          title="รีเซ็ตพาสเวิร์ด"
          centered
          visible={visiblePass}
          onOk={async () => {
            if (pass.pass.length < 8) return setAlert('โปรดใส่พาสเวิร์ดอย่างน้อย 8 ตัว')
            if (pass.pass !== pass.confirmPass) return setAlert('รหัสผ่านใหม่ไม่ตรงกัน')

            let _pass = await api.encryption(pass.pass)
            let pre_data = await api.query(`db/cores/user/query`, {
              query: { User: pass.user }
            })
            if (pre_data.length > 0) {
              pre_data[0].Pass_Salt = _pass.pass_salt
              pre_data[0].Pass_Hash = _pass.pass_hash
              console.log("pre_data", pre_data)
              await api.insert(`db/cores/user/data/${pre_data[0]._id}`, pre_data[0])
            }
            setVisiblePass(false)
            setPass({
              user: '',
              pass: '',
              confirmPass: ''
            })
            setAlert('รีเซ็ตพาสเวิร์ดสำเร็จ')
          }}
          onCancel={() => {
            setVisiblePass(false)
            setPass({
              user: '',
              pass: '',
              confirmPass: ''
            })
          }}
          width={800}
          okText={'บันทึก'}
          okButtonProps={{ style: { background: 'green' } }}
          cancelText={'ยกเลิก'}
        >
          <p>Username: {pass.user}</p>
          <p>Password: </p>
          <Input
            name="pass"
            type="password"
            placeholder="Password" style={{ width: 200, marginBottom: 12 }}
            value={pass.pass}
            onChange={(event) => {
              setPass({ ...pass, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />

          <p>Confirm Password: </p>
          <Input
            name="pass"
            type="password"
            placeholder="Password" style={{ width: 200, marginBottom: 12 }}
            value={pass.confirmPass}
            onChange={(event) => {
              setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
          <p></p>
        </Modal>


        <Modal
          title="แก้ไขผู้ใช้งาน"
          centered
          visible={visibleEdit}
          onOk={async () => {

            if (!dataEdit.title) return setAlert('โปรดเลือกคำนำหน้า')
            if (!dataEdit.firstname) return setAlert('โปรดใส่ชื่อ')
            if (!dataEdit.lastname) return setAlert('โปรดใส่นามสกุล')
            if (!dataEdit.position) return setAlert('โปรดใส่ตำแหน่ง')
            if (!dataEdit.group) return setAlert('โปรดใส่กลุ่มวิชาการ')

            let profile = await api.query("db/nunurse/profile/query", {
              aggregate: [{
                $match: { _id: `${dataEdit.user}`, del: { $ne: true } },
              }],
            });

            let _dataHome = await api.query('db/cores/role/query', {
              "aggregate": [
                {
                  $match: {
                    user: dataEdit.user,
                  }
                }
              ]
            })

            profile[0].group = dataEdit.group
            profile[0].title = dataEdit.title
            profile[0].firstName = dataEdit.firstname
            profile[0].lastName = dataEdit.lastname
            profile[0].position = dataEdit.position
            profile[0].tel = dataEdit.tel
            profile[0].email = dataEdit.email
            profile[0].room = dataEdit.room

            _dataHome[0].profile.group = dataEdit.group
            _dataHome[0].profile.title = dataEdit.title
            _dataHome[0].profile.firstname = dataEdit.firstname
            _dataHome[0].profile.lastname = dataEdit.lastname
            _dataHome[0].profile.position = dataEdit.position
            _dataHome[0].profile.tel = dataEdit.tel
            _dataHome[0].profile.email = dataEdit.email
            _dataHome[0].profile.room = dataEdit.room
            _dataHome[0].disable = dataEdit.disable

            for (let i = 0; i < fileImage.length; i++) {
              let url = 'https://nursenu.pro/minio/presignedUrl/' + 'nurse-profile' + '?name=' + dataEdit.user + '.png'
              let urlForPut = await axios.get(url)
              let res = await axios.put(urlForPut.data, fileImage[i])
            }

            await api.insert(`db/nunurse/profile/data/${profile[0]._id}`, profile[0])
            await api.insert(`db/cores/role/data/${_dataHome[0]._id}`, _dataHome[0])

            // updateChild(_data)
            setVisibleEdit(false)
            setDataEdit({ ...USER })
            let index = data.findIndex(x => x.user === dataEdit.user)
            data[index] = {
              ...data[index],
              ..._dataHome[0],
              name: `${_dataHome[0]?.profile?.title}${_dataHome[0]?.profile?.firstname} ${_dataHome[0]?.profile?.lastname}`,
              hospital: `${_dataHome[0]?.profile?.hospital}`,
              disable: _dataHome[0].disable ? 'disable' : 'active',
              role: raderRole(_dataHome[0])
            }
            setData([...data])
            setAlert('แก้ไขผู้ใช้งานสำเร็จ')
          }}

          onCancel={() => {
            setDataEdit({ ...USER })
            setVisibleEdit(false)
          }}
          width={800}
          okText={'บันทึก'}
          okButtonProps={{ style: { background: 'green' } }}
          cancelText={'ยกเลิก'}
        >
          <div className="flex flex-row">
            <div className="mt-2 flex-col">
              {imagePreview ? <img
                src={imagePreview}
                alt="avatar"
                style={{ width: 120, margin: 4, borderRadius: '4px' }}
                onError={event => {
                  event.target.src = img_default
                  event.onerror = null
                }}

              /> : null}
              <div className="w-full">
                <ImgCrop
                  style={{ width: '100%' }}
                  modalTitle='เลือกรูปภาพ'
                  quality={1}
                  aspect={6 / 9}
                  modalOk='ตกลง'
                  modalCancel="ยกเลิก"
                  modalProps={{ okButtonProps: { style: { background: 'green' } } }}
                >
                  <Upload
                    name="avatar"
                    style={{ width: '100%' }}
                    className="avatar-uploader"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    showUploadList={false}
                    fileList={fileImage}
                    beforeUpload={(file) => {
                      getBase64(file, (url) => {
                        setImagePreview(url)
                        setFileImage([file])
                      });
                      return false;
                    }}
                  >
                    <div className="w-full p-2 flex justify-center flex-col">
                      <Button style={{ backgroundColor: '#038967', color: '#fff', width: 100, fontSize: '12px', marginLeft: 4 }} shape="round"  > เปลี่ยนรูป </Button>
                    </div>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div className="flex-col">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p>username: {dataEdit.user} </p>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> คำนำหน้า: *</p>
                  <Select

                    placeholder="เลือกคำนำหน้า"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      setDataEdit({ ...dataEdit, ...{ title: e } })
                    }}
                    value={dataEdit?.title}
                  >
                    <Option value="ศ.">ศ.</Option>
                    <Option value="รศ.ดร.">รศ.ดร.</Option>
                    <Option value="ผศ.ดร.">ผศ.ดร.</Option>
                    <Option value="ผศ">ผศ.</Option>
                    <Option value="ดร.">ดร.</Option>
                    <Option value="อ.">อ.</Option>
                    <Option value="นาย">นาย</Option>
                    <Option value="นาง">นาง</Option>
                    <Option value="นางสาว">นางสาว</Option>
                  </Select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> ชื่อ: </p>
                  <Input
                    placeholder="ชื่อ" style={{ width: 200, marginBottom: 12 }}
                    value={dataEdit?.firstname}
                    onChange={(event) => {
                      setDataEdit({ ...dataEdit, ...{ firstname: `${event.target.value}`.replace(/\s/g, '') } })
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> นามสกุล: *</p>
                  <Input
                    placeholder="นามสกุล"
                    style={{ width: 200, marginBottom: 12 }}
                    value={dataEdit?.lastname}
                    onChange={(event) => {
                      setDataEdit({ ...dataEdit, ...{ lastname: `${event.target.value}`.replace(/\s/g, '') } })
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> กลุ่มวิชาการ *</p>
                  <Select
                    placeholder="โปรดเลือกกลุ่มวิชาการ"
                    style={{ width: 300 }}
                    onChange={(e) => {
                      setDataEdit({ ...dataEdit, ...{ group: e } })
                    }}
                    value={dataEdit?.group}
                  >
                    <Option value={'กลุ่มวิชาการพยาบาลมารดาและทารก'}>{'กลุ่มวิชาการพยาบาลมารดาและทารก'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลเด็ก'}>{'กลุ่มวิชาการพยาบาลเด็ก'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลผู้ใหญ่'}>{'กลุ่มวิชาการพยาบาลผู้ใหญ่'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลผู้สูงอายุ'}>{'กลุ่มวิชาการพยาบาลผู้สูงอายุ'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลชุมชน'}>{'กลุ่มวิชาการพยาบาลชุมชน'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช'}>{'กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช'}</Option>
                    <Option value={'กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ'}>{'กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ'}</Option>

                  </Select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> ตำแหน่ง *</p>
                  <Select
                    placeholder="โปรดเลือกตำแหน่ง"
                    style={{ width: 300 }}
                    onChange={(e) => {
                      setDataEdit({ ...dataEdit, ...{ position: e } })
                    }}
                    value={dataEdit?.position}
                  >
                    <Option value={'อาจารย์'}>{'อาจารย์'}</Option>
                    <Option value={'ผู้ช่วยศาสตราจารย์'}>{'ผู้ช่วยศาสตราจารย์'}</Option>
                    <Option value={'รองศาสตราจารย์'}>{'รองศาสตราจารย์'}</Option>
                    <Option value={'ศาสตราจารย์'}>{'ศาสตราจารย์'}</Option>

                  </Select>
                </div>
              </div>


              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> email: </p>
                  <Input
                    value={dataEdit.email}
                    placeholder="ระบุ email" style={{ width: 200, marginBottom: 12 }}
                    onChange={(event) => {
                      setDataEdit({ ...dataEdit, ...{ email: event.target.value } })
                    }}
                  />
                </div>


                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> โทรศัพท์: </p>
                  <Input
                    value={dataEdit.tel}
                    placeholder="ระบุ โทรศัพท์" style={{ width: 200, marginBottom: 12 }}
                    onChange={(event) => {
                      setDataEdit({ ...dataEdit, ...{ tel: event.target.value } })
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> ห้อง: </p>
                  <Input
                    value={dataEdit.room}
                    placeholder="ระบุห้อง" style={{ width: 200, marginBottom: 12 }}
                    onChange={(event) => {
                      setDataEdit({ ...dataEdit, ...{ room: event.target.value } })
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 4 }}>
                  <p className="mr-4"> Disable User:</p>
                  <div>
                    <Switch
                      style={{ backgroundColor: dataEdit?.disable ? 'red' : '#888' }}
                      onChange={(value) => {
                        setDataEdit({ ...dataEdit, ...{ disable: value } })
                      }}
                      checked={dataEdit?.disable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>


        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 24,
            display: "flex",
            width: "1350px",
            minHeight: "600px",
            alignSelf: "center",
            flexDirection: "column",
            // justifyContent: "center",
          }}
        >
          <div className="flex row">
            <p className="text-2xl">การจัดการผู้ใช้งาน</p>
            <div className="flex flex-1"></div>
            <Button
              onClick={() => {
                setImagePreview(img_default)
                setVisible(true)
              }}
              shape='round'
              style={{
                width: 150,
                margin: 2,
                backgroundColor: "#308867",
                color: "#fff",
              }}
            >
              + เพิ่มผู้ให้งาน
            </Button>

          </div>

          <div className="flex-row flex justify-between mb-4">
            <div class="w-full">
              <Table
                style={{ fontFamily: "Kanit" }}
                // pagination={false}
                bordered
                size="middle"
                dataSource={data}
                columns={[
                  {
                    title: "user",
                    dataIndex: "user",
                    key: "user",
                    width: 300,
                  },
                  {
                    title: "ชื่อ-นามสกุล",
                    dataIndex: "name",
                    key: "name",
                    width: 300,
                  },

                  {
                    title: "สถานะ",
                    dataIndex: "disable",
                    key: "disable",
                    width: 300,
                  },
                  {
                    title: "แก้ข้อมูล",
                    align: "center",
                    dataIndex: "budget_source",
                    key: "budget_source",
                    width: 10,
                    render: (_, data) => (
                      <Button
                        shape='round'
                        onClick={async (type) => {
                          let _data = await api.query('db/cores/role/query', {
                            "aggregate": [
                              {
                                $match: {
                                  user: data.user,
                                }
                              }
                            ]
                          })
                          let filename = `${_data[0].user}.png`
                          let url = `https://nursenu.pro/minio/getUrl/nurse-profile/${filename}`
                          let resUrlImage = await axios.get(url)
                          setImagePreview(resUrlImage.data.url)
                          setDataEdit({
                            "user": _data[0].user,
                            "title": _data[0].profile.title,
                            "firstname": _data[0].profile.firstname,
                            "lastname": _data[0].profile.lastname,
                            "disable": _data[0].disable,
                            "group": _data[0].profile.group,
                            "position": _data[0].profile.position,
                            "tel": _data[0].profile.tel,
                            "email": _data[0].profile.email,
                            "room": _data[0].profile.room,
                          })
                          setVisibleEdit(true)
                        }}
                        style={{
                          width: 150,
                          margin: 2,
                          backgroundColor: "#308867",
                          color: "#fff",
                        }}
                      >
                        แก้ไข
                      </Button>
                    ),
                  },

                  {
                    title: "รีเซ็ตพาสเวิร์ด",
                    align: "center",
                    dataIndex: "budget_source",
                    key: "budget_source",
                    width: 10,
                    render: (_, data) => (
                      <Button
                        onClick={() => {
                          setPass({
                            user: data.user,
                            pass: '',
                            confirmPass: ''
                          })
                          setVisiblePass(true)
                        }}
                        shape='round'
                        style={{
                          width: 150,
                          margin: 2,
                          backgroundColor: "#308867",
                          color: "#fff",
                        }}
                      >
                        รีเซ็ต
                      </Button>
                    ),
                  },
                ]}
                line
              />
              {/* data.table1 */}
            </div>

          </div>


        </div>
      </div>
    </Layout>
  );
}
