import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from '../../components/Layout';
import api from '../../util/api';
import { Button, Checkbox, Form, Input } from 'antd';

async function onLogin(form) {
  try {
    let user = await api.auth({ username: form.user, password: form.pass })
    if (user) {
      return user
      // let role = user.user.roles
      // if (role.includes(`doctor`)) return history.push("/main");
      // if (role.includes(`SHPH`)) return history.push("/main");
      // if (role.includes(`patient`)) return history.push("/home-patient");
      // if (role.includes(`admin`)) return history.push("/manageUser");
      // console.log("role", role)
    } else {
      alert('username หรือ Password ไม่ถูกต้อง')
    }
  } catch (err) {
    return Promise.reject(new Error('username หรือ Password ไม่ถูกต้อง'))
    // let _data = {
    //   _id: `${form.user}_${new Date().getTime()}`,
    //   R1: form.user,
    //   R2: md5(form.pass),
    //   status: 'fail'
    // }
    // await api.insert(`db/homecare/login/data/${_data._id}`, _data)
    // if (err.message === '"User Not Found"' || err.message === '"Incorrect Password"') {
    //   alert('username หรือ Password ไม่ถูกต้อง')
    // } else {
    //   alert(err.message)
    // }
  }
}



const App = () => {
  let history = useHistory();

  const onFinish = (values) => {
    onLogin({ user: values.username, pass: values.password })
      .then(user => {
        let role = user.user.roles
        if (role.includes(`doctor`)) return history.push("/profileDeatil");
        if (role.includes(`admin`)) return history.push("/manageUser");
      })
      .catch(err => {
        alert('username หรือ Password ไม่ถูกต้อง')
      })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    if (role === `["doctor"]`) history.push("/profileDeatil");
    if (role === `["admin"]`) history.push("/manageUser");
  }, []);

  return (<Layout >
    <div className=" bordered-lx p-12 felx w-[1350px] self-center">

      <div
        style={{
          background: "#fff",
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          width: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="w-[600px] text-center my-4">
          <p className="text-xl ">เข้าสู่ระบบ</p>
        </div>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button style={{ backgroundColor: '#1890FF', width: 400 }} type="primary" htmlType="submit">
              เข้าสู่ระบบ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  </Layout>
  );
}
export default App;