import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import {
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  Button,
  Upload,
  InputNumber,
  Modal,
  Checkbox,
  Space,
  Alert,
  Row,
  Table,
  Breadcrumb
} from "antd";
import AddressData from "../../util/AddressData";
import axios from "axios";
import api from "../../util/api";
import Box from "@mui/material/Box";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { v4 } from "uuid";
import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";
const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const fecthData = async (id) => {
  try {
    let profileform = await api.query("db/nunurse/profile/query", {
      aggregate: [],
    });

    let agency = await api.query("db/nunurse/agency/query", {
      aggregate: [],
    });
    let form = null
    if (id) {
      form = await api.query("db/nunurse/form_research_result/query", {
        aggregate: [
          {
            $match: { _id: `${id}` },
          },
        ],
      });

    }
    console.log("form", form)

    return {
      profileform,
      form
    };
  } catch (err) {
    console.log("error: " + err);
  }
};

const columnsProfile = [
  {
    title: "No.",
    dataIndex: "key",
    key: "key",
    align: "center",
    width: 10,
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "ตำแหน่ง",
    align: "center",
    dataIndex: "author",
    key: "author",
    width: 80,
  },
  {
    title: "แก้ไข/ลบ",
    align: "center",
    dataIndex: "budget_source",
    key: "budget_source",
    render: (_, data) => (
      <div
        style={{
          width: 30,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          onClick={data.onEdit}
          style={{
            width: 100,
            margin: 2,
            backgroundColor: "yellow",
            color: "black",
          }}
        >
          แก้ไข
        </Button>
        <Button
          onClick={data.onRemove}
          style={{
            width: 100,
            margin: 2,
            backgroundColor: "red",
            color: "white",
          }}
        >
          ลบ
        </Button>
      </div>
    ),
    width: 30,
  },
];

const default_modal_person = {
  name: null,
  title: null,
  firstName: null,
  lastName: null,
  id: null,
  positon: null,
  author: null,
  checkNoDB: false,
}

export default function ResearchResultsCreate(props) {
  let history = useHistory();
  let form_id = props?.match?.params?.id;
  let [profiles, setProfiles] = useState([]);
  let [dataTableListProfile, setDateTableListProfile] = useState([]);
  // const router = useRouter();
  const [ModalMessage, setModalMessage] = useState({
    show: false,
    title: "",
    message: "",
    ok: null,
    cancle: null,
  });

  let [form, setForm] = useState({
    _id: null,
    group: null,
    year: null,

    research_type: null,
    research_class: null,
    issue_number: null,
    volume_number: null,
    pages: null,
    url: null,
    db: null,
    journal_name: null,
    journal_title: null,

    create_date: null,
    times: null,
    timestamp: null,
    create_by: null,
  });

  const [Edit, setEdit] = useState({
    index: null,
    checked: false,
  });

  let [addModelPersonInTabel, setAddModelPersonInTabel] = useState({ ...default_modal_person });

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const showModal3 = () => {
    setIsModalOpen3(true);
  };

  useEffect(() => {
    fecthData(form_id).then(({ profileform, form }) => {
      // console.log("data: >", data)
      console.log("_form", form)
      setProfiles(profileform);
      if (form.length > 0) {
        setForm({ ...form[0] });
        setDateTableListProfile([...form[0].list_person])
      }
    });
  }, []);




  const onChangeSelect = (value, id) => {
    setForm({
      ...form,
      [`${id}`]: value,
    });
  };


  const onChangeTextInput = (e) => {
    console.log("e", e);
    let { id, value } = e.target;
    if (id !== undefined && value !== undefined) {
      setForm({
        ...form,
        [`${id}`]: value,
      });
    }
  };


  const [show, setShow] = useState({
    show: false,
    title: "",
    message: "",
  });

  const onFinish = async (message) => {
    try {

      // if (!form.year)
      //   return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณากรอบข้อมูลปีงบประมาณ' } });

      // if (dataTableListProfile.length === 0)
      //   return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ผู้ให้บริการวิชาการอย่างน้อย 1 คน' } });

      // if (!form.topic)
      //   return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่หัวข้อเรื่อง' } });


      if (!form?._id) {
        form._id = v4().toString().replace(/-/g, "");
      }

      form.list_person = [...dataTableListProfile];
      await api.insert(
        `db/nunurse/form_research_result/data/${form._id}`,
        form
      );
      message(form._id);

    } catch (err) {
      console.log(">> err", err);
    }
  };

  return (
    <Layout>

      <Modal
        title=" เพิ่มรายชื่อผู้แต่ง"
        centered
        width={800}
        okText={"บันทึก"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpen3}
        onOk={async () => {
          try {
            let person = { ...addModelPersonInTabel }

            if (
              person.title === null ||
              person.firstName === null ||
              person.lastName === null
            ) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ ชื่อ-นามสกุล' } });
            }


            if (person.author === null) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ตำแหน่ง' } });
            }



            if (person.checkNoDB) {
              person['name'] = `${person.title}${person.firstName} ${person.lastName} `
              person['id'] = null
              person['group'] = null
            }

            if (Edit.checked) {
              dataTableListProfile[Edit.index] = person
              setDateTableListProfile([...dataTableListProfile])
            } else {
              setDateTableListProfile([
                ...dataTableListProfile,
                person
              ]);
            }

            setAddModelPersonInTabel({ ...default_modal_person });
            setEdit({
              index: null,
              checked: false
            })
            setIsModalOpen3(false);
          } catch (err) {
            console.log("err", err);
          }
        }}
        onCancel={() => {
          setAddModelPersonInTabel({ ...default_modal_person });
          setIsModalOpen3(false);
          setEdit({
            index: null,
            checked: false
          })
        }}
      >
        <div className="flex flex-col">
          {
            false === addModelPersonInTabel.checkNoDB ?
              (
                <Space >
                  <p>ชื่อ-นามสกุล :</p>
                  <Select
                    style={{ width: 300 }}
                    showSearch
                    value={addModelPersonInTabel.name}
                    placeholder=""
                    onChange={(value) => {
                      let item = profiles.find(
                        (x) => `${x.title}${x.firstName} ${x.lastName} ` === value
                      );
                      if (item) {
                        setAddModelPersonInTabel({
                          ...addModelPersonInTabel,
                          name: `${item.title}${item.firstName} ${item.lastName} `,
                          title: item.title,
                          firstName: item.firstName,
                          lastName: item.lastName,
                          id: item._id,
                          group: item.group,
                        });
                      }
                    }}
                  >
                    {profiles.map((x) => (
                      <Option
                        key={`${x.title}${x.firstName} ${x.lastName} `}
                        value={`${x.title}${x.firstName} ${x.lastName} `}
                      >{`${x.title}${x.firstName} ${x.lastName} `}</Option>
                    ))}
                  </Select>
                </Space>
              ) :
              (
                <Space style={{ marginBottom: 12 }}>
                  <p>ชื่อ-นามสกุล</p>
                  <Select
                    style={{ width: 120 }}
                    showSearch
                    value={addModelPersonInTabel.title}
                    placeholder="คำนำหน้า"
                    onChange={(value) => {
                      setAddModelPersonInTabel({
                        ...addModelPersonInTabel,
                        name: ``,
                        title: value,
                        id: null,
                        group: null,
                      });
                    }}
                  >
                    <Option value="ศ.">ศ.</Option>
                    <Option value="รศ.ดร.">รศ.ดร.</Option>
                    <Option value="ผศ.ดร.">ผศ.ดร.</Option>
                    <Option value="ผศ">ผศ.</Option>
                    <Option value="ดร.">ดร.</Option>
                    <Option value="อ.">อ.</Option>
                    <Option value="นาย">นาย</Option>
                    <Option value="นาง">นาง</Option>
                    <Option value="นางสาว">นางสาว</Option>

                  </Select>
                  <Input
                    style={{ width: 200 }}
                    id="addAgency"
                    placeholder="ชื่อ"
                    maxLength={50}
                    value={addModelPersonInTabel.firstName}
                    onChange={(e) => {
                      let { id, value } = e.target;
                      setAddModelPersonInTabel({
                        ...addModelPersonInTabel,
                        firstName: value,
                        id: null,
                        group: null,
                      });
                    }}
                  />
                  <Input
                    style={{ width: 200 }}
                    id="addAgency"
                    placeholder="นามสกุล"
                    maxLength={50}
                    value={addModelPersonInTabel.lastName}
                    onChange={(e) => {
                      let { id, value } = e.target;
                      setAddModelPersonInTabel({
                        ...addModelPersonInTabel,
                        lastName: value,
                        id: null,
                        group: null,
                      });
                    }}
                  />

                </Space>
              )
          }
          <Space style={{ marginLeft: 84, marginBottom: 12 }}>
            <Checkbox checked={addModelPersonInTabel.checkNoDB}
              onChange={() => setAddModelPersonInTabel({
                name: null,
                title: null,
                firstName: null,
                lastName: null,
                id: null,
                positon: null,
                percent: null,
                hour: null,
                group: null,
                checkNoDB: !addModelPersonInTabel.checkNoDB
              })} >
              ไม่มีรายชื่อ-นามสกุลในฐานข้อมูล
            </Checkbox>
          </Space>
          <Space style={{ marginBottom: 12 }}>
            <p>ตำแหน่ง :</p>
            <Select
              style={{ width: 200 }}
              value={addModelPersonInTabel.author}
              placeholder="ตำแหน่ง"
              onChange={(value) => {
                setAddModelPersonInTabel({
                  ...addModelPersonInTabel,
                  author: value,
                });
              }}
            >
              <Option key={`First Author`}>First Author</Option>
              <Option key={`Co-Author`}>Co-Author</Option>
              <Option key={`Corresponding Author`}>Corresponding Author</Option>

              {/* <Option key={`กรรมการ`}>กรรมการ</Option> */}
            </Select>

          </Space>
        </div>
      </Modal>

      <Modal
        title={null}
        centered
        width={400}
        okText={"บันทึก"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={ModalMessage.show}
        onOk={() => {
          onFinish((_id) => {
            history.push("/ResearchResults/detail/" + _id);
          });
          setModalMessage({ ...ModalMessage, show: false, title: "" });
        }}
        onCancel={() => {
          setModalMessage({ ...ModalMessage, show: false, title: "" });
        }}
      >
        <div
          style={{ width: "100%", flex: 1, display: "flex", marginBottom: 12 }}
        >
          <p className="text-2xl">คุณต้องการบันทึกข้อมูลหรือไม่</p>
        </div>
      </Modal>

      <div className=" bordered-lx p-12 felx w-[1350px] self-center">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            การบริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            [แบบฟอร์มผลงานวิจัย/ตีพิมพ์/นำเสนอ]
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
          }}
        >
          <Text style={{ fontSize: 24 }}>
            แบบฟอร์มผลงานวิจัย/ตีพิมพ์/นำเสนอ
          </Text>
          <Divider />
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>ประเภทผลงาน :</Text>
              <Select
                style={{ width: 400 }}
                value={form.research_type}
                placeholder=""
                showSearch
                onChange={(value) => onChangeSelect(value, "research_type")}
              >
                <Option value="journal">วารสาร (Journal)</Option>
                <Option value="proceeding">
                  ผลงานที่นำเสนอในที่ประชุมวิชาการ (Proceeding)
                </Option>
              </Select>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>ระดับ :</Text>
              <Select
                style={{ width: 400 }}
                value={form.research_class}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "research_class")}
              >
                <Option value="thai">ระดับชาติ</Option>
                <Option value="international">ระดับนานาชาติ</Option>
              </Select>
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text Text>กลุ่มวิชาการ :</Text>
              <Select
                style={{ width: 400 }}
                value={form.group}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "group")}
              >
                <Option
                  key={`กลุ่มวิชาการพยาบาลมารดาและทารก`}
                  value={`กลุ่มวิชาการพยาบาลมารดาและทารก`}
                >
                  กลุ่มวิชาการพยาบาลมารดาและทารก
                </Option>
                <Option
                  key={`กลุ่มวิชาการพยาบาลเด็ก`}
                  value={`กลุ่มวิชาการพยาบาลเด็ก`}
                >
                  กลุ่มวิชาการพยาบาลเด็ก
                </Option>
                <Option
                  key={`กลุ่มวิชาการพยาบาลผู้ใหญ่`}
                  value={`กลุ่มวิชาการพยาบาลผู้ใหญ่`}
                >
                  กลุ่มวิชาการพยาบาลผู้ใหญ่
                </Option>
                <Option
                  key={`กลุ่มวิชาการพยาบาลผู้สูงอายุ`}
                  value={`กลุ่มวิชาการพยาบาลผู้สูงอายุ`}
                >
                  กลุ่มวิชาการพยาบาลผู้สูงอายุ
                </Option>
                <Option
                  key={`กลุ่มวิชาการพยาบาลชุมชน`}
                  value={`กลุ่มวิชาการพยาบาลชุมชน`}
                >
                  กลุ่มวิชาการพยาบาลชุมชน
                </Option>
                <Option
                  key={`กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช`}
                  value={`กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช`}
                >
                  กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช
                </Option>
                <Option
                  key={`กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ`}
                  value={`กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ`}
                >
                  กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ
                </Option>
              </Select>
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>ชื่อผลงาน (Title) :</Text>
              <Input
                style={{ width: 400 }}
                placeholder=""
                value={form.journal_title}
                id="journal_title"
                onChange={onChangeTextInput}
              />
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>
                ปีที่ตีพิมพ์ (Publication Date) :
              </Text>
              {/* <Input
                style={{ width: 140 }}
                placeholder=""
                id="year"
                value={form.year}
                onChange={onChangeTextInput}
              /> */}
              <Select
                style={{ width: 400 }}
                value={form.year}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "year")}
              >
                <Option value="2566">2566 </Option>
                <Option value="2565">2565</Option>
                <Option value="2564">2564</Option>
                <Option value="2563">2563</Option>
                <Option value="2562">2562</Option>
              </Select>
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>
                ชื่อวารสาร/ชื่อการประชุมวิชาการ :
              </Text>
              <Input
                style={{ width: 600 }}
                placeholder=""
                id="journal_name"
                value={form.journal_name}
                onChange={onChangeTextInput}
              />
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>
                เลขของปีที่ (Volume Number) :
              </Text>
              <Input
                style={{ width: 140 }}
                placeholder=""
                value={form.volume_number}
                id="volume_number"
                onChange={onChangeTextInput}
              />
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>ฉบับที่ (Issue Number) :</Text>
              <Input
                style={{ width: 140 }}
                placeholder=""
                value={form.issue_number}
                id="issue_number"
                onChange={onChangeTextInput}
              />
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>หน้าที่ตีพิมพ์ (Pages) :</Text>
              <Input
                style={{ width: 400 }}
                placeholder=""
                value={form.pages}
                id="pages"
                onChange={onChangeTextInput}
              />
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>ฐานข้อมูล :</Text>
              <Select
                style={{ width: 400 }}
                value={form.db}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "db")}
              >
                <Option value="Scopus">Scopus </Option>
                <Option value="ISI">ISI</Option>
                <Option value="WOS">Web of Science</Option>
                <Option value="SJR">SJR</Option>
                <Option value="ERIC">ERIC</Option>
                <Option value="MathSciNet">MathSciNet</Option>
                <Option value="Pubmed">Pubmed</Option>
                <Option value="JSTOR3">JSTOR</Option>
                <Option value="ProjectMuse">Project Muse</Option>
                <Option value="TCI">Thai Journal Citation Index - TCI</Option>
              </Select>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "16px" }}>Url :</Text>
              <Input
                placeholder="Url"
                style={{ width: 800 }}
                value={form.url}
                id="url"
                onChange={onChangeTextInput}
              />
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Checkbox
                style={{ fontSize: 16 }}
                checked={form.isStudentThesis}
                onChange={() => {
                  setForm({ ...form, isStudentThesis: !form.isStudentThesis });
                }}
              >
                เป็นผลงานวิทยานิพนธ์ของนิสิต
              </Checkbox>
            </div>
          </Row>

          <div style={{ marginTop: 24 }}>
            <Text style={{ fontSize: 18 }}>ตารางรายชื่อผู้แต่ง </Text>
            <Table
              footer={() => {
                return (
                  <Button
                    onClick={showModal3}
                    style={{ backgroundColor: "green", color: "#fff" }}
                  >
                    เพิ่มรายชื่อผู้แต่ง
                  </Button>
                );
              }}
              bordered
              size="middle"

              dataSource={dataTableListProfile.map((x, i) => ({
                ...x,
                onEdit: () => {
                  setAddModelPersonInTabel({
                    name: x.name,
                    title: x.title,
                    firstName: x.firstName,
                    lastName: x.lastName,
                    id: x.id,
                    positon: x.positon,
                    author: x.author,
                    checkNoDB: x.checkNoDB ? true : false,
                  })
                  showModal3()
                  setEdit({
                    index: i,
                    checked: true
                  })
                },
                onRemove: () => {
                  const cloneArray = dataTableListProfile.slice();
                  cloneArray.splice(i, 1)
                  setDateTableListProfile([...cloneArray])
                },
                key: i + 1,
              }))}

              // dataSource={dataTableListProfile.map((x, i) => ({
              //   ...x,
              //   key: i + 1,
              //   remove: () => {
              //     // setModalMessageNew({
              //     //   title: "แจ้ง",
              //     //   message: "คุณต้องการลบข้อมูลหรือไม่",
              //     //   show: true,
              //     //   ok: () => {
              //     //     dataTableListProfile.splice(i, 1)[0];
              //     //     setDateTableListProfile([...dataTableListProfile]);
              //     //     ClearModalMessageNew();
              //     //   },
              //     //   cancle: () => {
              //     //     ClearModalMessageNew();
              //     //   },
              //     // });
              //   },
              //   Edit: () => { },
              // }))}
              columns={columnsProfile}
              line
            />
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              height: 50,
              marginTop: 24,
              width: 200,
              fontSize: 24,
            }}
            size="large"
            onClick={() =>
              setModalMessage({ ...ModalMessage, show: true, title: "xxxxx2" })
            }
          >
            บันทึกข้อมูล
          </Button>
        </div>

        <div className="h-[300px]"></div>
      </div>
    </Layout>
  );
}
