import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Carousel, Result } from 'antd';
import Layout from '../../components/Layout';
import api from "../../util/api";
import axios from "axios";

const loadData = async (id) => {

  let res = await api.query("db/nunurse/post/query", {
    aggregate: [],
  });

  console.log("res", res)

  let result = []
  for (let i = 0; i < res.length; i++) {
    let x = res[i]
    let filename = `${x.name_image}.png`
    let url = `https://nursenu.pro/minio/getUrl/nurse-profile/${filename}`
    let resUrlImage = await axios.get(url)
    result.push({ ...x, image: resUrlImage.data.url })
  }

  return result;
};



export default function Home() {
  let history = useHistory();
  let [posts, setPost] = useState([]);
  useEffect(() => {
    loadData()
      .then(res => {
        setPost(res);
      });
  }, []);

  console.log("posts", posts)
  return (
    <Layout >

      <div class="w-full flex justify-center mx-auto ">
        <div class="grid grid-cols-6 lg:grid-cols-12 p-5 gap-4 flex justify-self-center	w-[1200px]">

          <div class="flex flex-col content-center shadow-lg bg-white p-4 rounded-lg col-span-6 md:col-span-12 row-span-3 mt-4">
            <p className="text-2xl">ข่าวประชาสัมพันธ์</p>
            <div className="bg-red-400 h-[4px] w-full mb-[12px]"></div>

            <Carousel autoplay >
              {
                posts.map(x => {
                  return (
                    <div className="w-full overflow-hidden shadow-lg h-[600px]">
                      <a
                        href={x.url ? x.url : null}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="object-cover"
                          style={{ borderRadius: 8 }}
                          alt={`description`}
                          // src={'http://www.nurse.nu.ac.th/webdpmnr/images/Person/nteacher/uraiwan.jpg'}
                          href={x.url ? x.url : null}
                          src={x.image ? x.image : null}
                          width="100%"
                        />
                      </a>

                    </div>
                  )
                })
              }

            </Carousel>

          </div>

        </div>
      </div>

    </Layout >)
}
