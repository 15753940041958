import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import { Select, Table, Typography, Row, Col, Progress, Space, Breadcrumb, Button } from "antd";
import AddressData from "../../util/AddressData";
import axios from "axios";
import api from "../../util/api";
import Box from "@mui/material/Box";
import { PlusOutlined, TableOutlined } from "@ant-design/icons";
import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";

const RederColRight = (amount) => {
  return (_, data) => {
    return {
      props: {
        style: { textAlign: "right" },
      },
      children: (
        <p style={{ fontSize: 20 }}>
          {/* <a href={`/profilePosition/table/all/all`} style={{ fontSize: 20 }}> */}
          {data?.amount}
          {/* </a> */}
        </p>
      ),
    };
  };
};

const MAP_GROUP = {
  คณะพยาบาลศาสตร์: "all",
  กลุ่มวิชาการพยาบาลมารดาและทารก: "mother_and_infant",
  กลุ่มวิชาการพยาบาลเด็ก: "child",
  กลุ่มวิชาการพยาบาลผู้ใหญ่: "adult",
  กลุ่มวิชาการพยาบาลผู้สูงอายุ: "geriatric",
  กลุ่มวิชาการพยาบาลชุมชน: "community",
  กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช: "psychiatric",
  กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ: "administartion_professional",
};

const MAP_NAME = {
  lecturer: "อาจารย์",
  assistant_professor: "ผู้ช่วยศาสตราจารย์",
  associate_professor: "รองศาสตราจารย์",
  professor: "ศาสตราจารย์",
  // assistant_professor_special: "ผู้ช่วยศาสตราจารย์(พิเศษ)",
  // associate_professor_special: "รองศาสตราจารย์(พิเศษ)",
  // professor_special: "ศาสตราจารย์(พิเศษ)",
  government_officer: "ข้าราชการ",
  academic: "พนักงานมหาวิทยาลัย(สายวิชาการ)",
  service: "พนักงานมหาวิทยาลัย(สายบริการ)",
  // "knowledgeable": 'ผู้มีความรู้ความสามารถ',
  // "nu_staff": 'พนักงานมหาวิทยาลัยนเรศวร',
  employee: "ลูกจ้างประจำ",
  government_employee: "พนักงานราชการ",
};

const { Title, Text } = Typography;
const columns = [
  {
    title: "ตำแหน่งทางวิชาการ",
    dataIndex: "name",
    key: "name",
    width: 600,
    render: (_, { name }) => (
      <Text style={{ fontWeight: "bold", fontSize: "16px" }}>{name}</Text>
      // < Space size="middle" >
      //   </Space >
    ),
  },
  {
    title: "ตำแหน่งทางวิชาการ",
    align: "center",
    children: [
      {
        title: "จำนวน",
        key: "จำนวน",
        width: 100,
        align: 'center',
        dataIndex: "amount",
        render: RederColRight(),
      },
      {
        title: "percentage",
        key: "จำนวน",
        // width: 100,
        align: 'center',
        dataIndex: "age",
        render: (_, { amount, total }) => (
          <>
            <Progress
              percent={(amount / total) * 100}
              showInfo={false}
              strokeColor={"#52C419"}
            />
          </>
        ),
      },
    ],
    // dataIndex: 'จำนวน',
    // key: 'age',
  },
];

const setDatatable = (data, selectData) => {
  console.log("selectData", selectData);
  // selectData
  let result_1 = {
    lecturer: 0,
    assistant_professor: 0,
    associate_professor: 0,
    professor: 0,
    // assistant_professor_special: 0,
    // associate_professor_special: 0,
    // professor_special: 0,
  };

  let result_2 = {
    government_officer: 0,
    academic: 0,
    service: 0,
    // "nu_staff": 0,
    // "knowledgeable": 0,
    employee: 0,
    government_employee: 0,
  };
  let total = 0;
  data
    .filter((x) => {
      return x.name === selectData || selectData === "คณะพยาบาลศาสตร์";
    })
    .forEach((element) => {
      result_1.lecturer += element.lecturer;
      result_1.assistant_professor += element.assistant_professor;
      result_1.associate_professor += element.associate_professor;
      result_1.professor += element.professor;
      // result_1.assistant_professor_special +=
      //   element.assistant_professor_special;
      // result_1.associate_professor_special +=
      //   element.associate_professor_special;
      // result_1.professor_special += element.professor_special;
      total +=
        element.lecturer +
        element.assistant_professor +
        element.associate_professor +
        element.professor +
        element.assistant_professor_special +
        element.associate_professor_special;

      result_2.government_officer += element.government_officer;
      result_2.academic += element.academic;
      result_2.service += element.service;
      // result_2.knowledgeable += element.knowledgeable
      // result_2.nu_staff += element.nu_staff
      result_2.employee += element.employee;
      result_2.government_employee += element.government_employee;
    });

  return {
    table1: Object.keys(result_1).map((x) => ({
      name: MAP_NAME[x],
      amount: result_1[x],
      total: total,
      key: x,
      group: MAP_GROUP[selectData],
    })),
    table2: Object.keys(result_2).map((x) => ({
      name: MAP_NAME[x],
      amount: result_2[x],
      total: total,
      key: x,
      group: MAP_GROUP[selectData],
    })),
  };
};

export default function Home(props) {
  let history = useHistory();
  let [data, setData] = useState({ table1: [], table2: [] });
  let [selectData, setSelectData] = useState("คณะพยาบาลศาสตร์");
  React.useEffect(async () => {
    // let role = await localStorage.getItem('role')
    // let user = localStorage.getItem('user_wecaremaesot')
    // role = JSON.parse(role)
    // user = JSON.parse(user)

    let aggregate = [
      { $match: { "active": true } },
      {
        $project: {
          item: 1,
          group: 1,

          lecturer: {
            $cond: {
              if: { $and: [{ $eq: ["$position", "อาจารย์"] }] },
              then: 1,
              else: 0,
            },
          },
          assistant_professor: {
            $cond: {
              if: { $and: [{ $eq: ["$position", "ผู้ช่วยศาสตราจารย์"] }] },
              then: 1,
              else: 0,
            },
          },
          associate_professor: {
            $cond: {
              if: { $and: [{ $eq: ["$position", "รองศาสตราจารย์"] }] },
              then: 1,
              else: 0,
            },
          },
          professor: {
            $cond: {
              if: { $and: [{ $eq: ["$position", "ศาสตราจารย์"] }] },
              then: 1,
              else: 0,
            },
          },
          assistant_professor_special: {
            $cond: {
              if: {
                $and: [{ $eq: ["$position", "ผู้ช่วยศาสตราจารย์(พิเศษ)"] }],
              },
              then: 1,
              else: 0,
            },
          },
          associate_professor_special: {
            $cond: {
              if: { $and: [{ $eq: ["$position", "รองศาสตราจารย์(พิเศษ)"] }] },
              then: 1,
              else: 0,
            },
          },
          professor_special: {
            $cond: {
              if: { $and: [{ $eq: ["$position", "ศาสตราจารย์(พิเศษ)"] }] },
              then: 1,
              else: 0,
            },
          },

          government_officer: {
            $cond: {
              if: { $and: [{ $eq: ["$personnel", "ข้าราชการ"] }] },
              then: 1,
              else: 0,
            },
          },
          academic: {
            $cond: {
              if: {
                $and: [
                  { $eq: ["$personnel", "พนักงานมหาวิทยาลัย(สายวิชาการ)"] },
                ],
              },
              then: 1,
              else: 0,
            },
          },
          service: {
            $cond: {
              if: {
                $and: [
                  { $eq: ["$personnel", "พนักงานมหาวิทยาลัย(สายบริการ)"] },
                ],
              },
              then: 1,
              else: 0,
            },
          },
          knowledgeable: {
            $cond: {
              if: { $and: [{ $eq: ["$personnel", "ผู้มีความรู้ความสามารถ"] }] },
              then: 1,
              else: 0,
            },
          },
          nu_staff: {
            $cond: {
              if: {
                $and: [{ $eq: ["$personnel", "พนักงานมหาวิทยาลัยนเรศวร"] }],
              },
              then: 1,
              else: 0,
            },
          },
          employee: {
            $cond: {
              if: { $and: [{ $eq: ["$personnel", "ลูกจ้างประจำ"] }] },
              then: 1,
              else: 0,
            },
          },
          government_employee: {
            $cond: {
              if: { $and: [{ $eq: ["$personnel", "พนักงานราชการ"] }] },
              then: 1,
              else: 0,
            },
          },
        },
      },
      {
        $group: {
          _id: { group: "$group" },
          name: { $first: "$group" },
          lecturer: { $sum: "$lecturer" },
          assistant_professor: { $sum: "$assistant_professor" },
          associate_professor: { $sum: "$associate_professor" },
          professor: { $sum: "$professor" },
          assistant_professor_special: { $sum: "$assistant_professor_special" },
          associate_professor_special: { $sum: "$associate_professor_special" },
          professor_special: { $sum: "$professor_special" },

          government_officer: { $sum: "$government_officer" },
          academic: { $sum: "$academic" },
          service: { $sum: "$service" },
          knowledgeable: { $sum: "$knowledgeable" },
          nu_staff: { $sum: "$nu_staff" },
          employee: { $sum: "$employee" },
          government_employee: { $sum: "$government_employee" },
        },
      },
    ];

    let _data = await api.query("db/nunurse/profile/query", {
      aggregate: aggregate,
    });

    setData(setDatatable(_data, selectData));
  }, []);

  return (
    <Layout>
      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          padding: 12,
          // padding: "18px",
          display: "flex",
          maxWidth: "1200px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: 40,
            background: "#fff",
            borderRadius: 12,
            padding: 18,
            display: "flex",
            width: "1200px",
            minHeight: "600px",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{ flexDirection: "column", display: "flex", width: "100%" }}
          >
            <div className="flex-row flex justify-between mb-4">
              {/* space-between */}
              <p class="text-center text-3xl"> บุคลากร</p>
              <div>
                <Button
                  shape="round"
                  style={{ backgroundColor: "#1677FF", color: "white" }}
                  icon={<TableOutlined />}
                  onClick={() =>
                    history.push("/profilePosition/table/all/all")
                  }
                  size="large"
                >
                  ดูตาราง
                </Button>

              </div>
            </div>

            {/* ตำแหน่งทางวิชาการ */}
            <div className="flex flex-row ">
              <div class="w-full ... ">
                <Table
                  pagination={false}
                  bordered
                  size="middle"
                  dataSource={data.table1}
                  columns={columns}
                  line
                />
                {/* data.table1 */}
              </div>
              {/* <div class="w-1/2 ... ">
                <Table
                  pagination={false}
                  bordered
                  size="middle"
                  dataSource={data.table2}
                  columns={columns}
                  line
                />
              </div> */}
            </div>
            <Divider class="mb-4 mt-2" />
            <div className="w-full m-auto">
              <Box sx={{ width: "80%", margin: "auto" }}></Box>
            </div>
            <Divider class="m-4" />
          </div>
        </div>
      </div>
    </Layout>
  );
}
