// import { configureStore } from '@reduxjs/toolkit'
// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './reducers/rootReducer';
// export default function configureStore() {
//     return createStore(
//         rootReducer,
//         applyMiddleware(thunk)
//     );
// }

import { configureStore } from '@reduxjs/toolkit'
import simpleReducer from './simpleReducer'


export const store = configureStore({
    reducer: {
        simpleReducer: simpleReducer,
    },
})