import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Progress,
  Space,
  Button,
  Breadcrumb,
} from "antd";
import api from "../../util/api";
import Box from "@mui/material/Box";
import "moment/locale/th";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const MAP_GROUP = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
};

const MAP = {
  journal: "วารสาร (Journal)",
  proceeding: "ผลงานที่นำเสนอในที่ประชุมวิชาการ (Proceeding)",
  thai: "ระดับชาติ",
  international: "ระดับนานาชาติ",
};


const rederColRight = (key) => {
  return (_, data) => (
    <Space size="middle">
      <a href={`/ResearchResults/detail/${data._id}`}>{data[key]}</a>
    </Space>
  );
};

const fetchData = async () => {
  try {
    let aggregate = [];

    let _data = await api.query("db/nunurse/form_research_result/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const { Title, Text } = Typography;

const onCreateDataTable = (data = [], year) => {
  return data.map((x, i) => {
    let name = "";
    let item = x?.list_person?.find((x) => x.positon === "ประธาน");
    if (item) {
      name = `${item.title}${item.firstName} ${item.lastName}`;
    }

    let ref = "";

    if (x.research_type === "journal") {
      if (x.year) ref = `${ref}(${x.year})`;
      // if (x.journal_title) ref = `${ref}. ${x.journal_title}`;
      if (x.journal_name) ref = `${ref}. ${x.journal_name}`;
      if (x.issue_number) ref = `${ref}, ${x.volume_number}(${x.issue_number})`;
      if (x.pages) ref = `${ref}, ${x.pages}.`;
    } else if (x.research_type === "proceeding") {
      if (x.year) ref = `${ref}(${x.year})`;
      // if (x.journal_title) ref = `${ref}. ${x.journal_title}`;
      if (x.journal_name) ref = `${ref}. ${x.journal_name}`;
      if (x.issue_number) ref = `${ref}, ${x.volume_number}(${x.issue_number})`;
      if (x.pages) ref = `${ref}, ${x.pages}.`;
    } else {

    }
    // ชื่อผู้แต่ง./(ปีพิมพ์,/วันที่/เดือน)./ชื่อบทควำมหรือชื่อเรื่องของบท/[กำรน ำเสนอ]./ชื่อกำรประชุม,
    // เมืองที่ประชุม.
    return {
      ...x,
      ref,
      key: 1 + i,
      nameHead: name,
    };
  });
};

const rederButton = (key) => {
  return (_, data) => (
    <Space size="middle">{data.url && <a href={`${data.url}`}>Link</a>}</Space>
  );
};

export default function Home(props) {
  let history = useHistory();

  // let year = props?.match?.params?.year;
  // let group = props?.match?.params?.group;
  // let budgetsource = props?.match?.params?.budgetsource;

  let [data, setData] = useState([]);
  let [selectYear, setSelectYear] = useState('2566');
  let [selectGroup, setSelectGroup] = useState('all');
  let [selectType, setSelectType] = useState('all');

  let [dateTable, setDateTable] = useState();

  // setSelectType setSelectYear setSelectGroup

  let [selectData, setSelectData] = useState("คณะพยาบาลศาสตร์");
  React.useEffect(async () => {
    let _data = await fetchData();
    setData(_data)
  }, []);
  // { label: "journal (ระดับชาติ)", value: "journal (ระดับชาติ)" },
  //             { label: "journal (นานาชาติ)", value: "journal (นานาชาติ)" },
  //             { label: "proceeding (ระดับชาติ)", value: "proceeding (ระดับชาติ)" },
  //             { label: "proceeding (นานาชาติ)", value: "proceeding (นานาชาติ)" },
  React.useEffect(() => {
    let functionfilter = () => { };
    if (selectGroup === "all" && selectType === "all") {
      functionfilter = (item) => item.year === selectYear;
    } else if (selectType === "all") {
      functionfilter = (item) =>
        item.year === selectYear && item.group === MAP_GROUP[selectGroup];
    } else if (selectGroup === "all") {
      functionfilter = (item) =>
        item.year === selectYear &&
        `${item.research_type} ${item.research_class ? "(" + MAP[item.research_class] + ")" : ''}` === selectType
    } else {
      functionfilter = (item) =>
        item.year === selectYear &&
        `${item.research_type} ${item.research_class ? "(" + MAP[item.research_class] + ")" : ''}` === selectType &&
        item.group === MAP_GROUP[selectGroup];
    }
    let _data = data.filter(functionfilter);
    setDateTable(onCreateDataTable(_data, selectYear));
  }, [data, selectYear, selectType, selectGroup]);

  return (
    <Layout>
      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          maxWidth: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>วิจัย</Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            [ตารางแสดงจำนวน ผลงานวิจัย/ตีพิมพ์/นำเสนอ]
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 24,
            display: "flex",
            width: "1350px",
            minHeight: "800px",
          }}
        >
          {/* detail */}

          <div
            style={{ flexDirection: "column", display: "flex", width: "100%" }}
          >
            <div className="flex-row flex justify-between ">
              {/* space-between */}
              <p class="text-center text-3xl">
                ตารางแสดงจำนวน ผลงานวิจัย/ตีพิมพ์/นำเสนอ
              </p>
            </div>
            <Space wrap style={{ marginTop: 16, marginBottom: 16 }}>
              <Select
                value={selectGroup}
                style={{ width: 300 }}
                options={[
                  { value: "all", label: "คณะพยาบาล" },
                  {
                    value: "mother_and_infant",
                    label: "กลุ่มวิชาการพยาบาลมารดาและทารก",
                  },
                  { value: "child", label: "กลุ่มวิชาการพยาบาลเด็ก" },
                  { value: "adult", label: "กลุ่มวิชาการพยาบาลผู้ใหญ่" },
                  {
                    value: "geriatric",
                    label: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
                  },
                  { value: "community", label: "กลุ่มวิชาการพยาบาลชุมชน" },
                  {
                    value: "psychiatric",
                    label: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
                  },
                  {
                    value: "administartion_professional",
                    label: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
                  },
                ]}
                onChange={(value) => {
                  setSelectGroup(value);
                }}
              />

              {/* proceeding (ระดับชาติ) */}
              <Select
                style={{ width: 220 }}
                defaultValue={"all"}
                options={[
                  { label: "ประเภทผลงานทั้งหมด", value: "all" },
                  { label: "journal (ระดับชาติ)", value: "journal (ระดับชาติ)" },
                  { label: "journal (ระดับนานาชาติ)", value: "journal (ระดับนานาชาติ)" },
                  { label: "proceeding (ระดับชาติ)", value: "proceeding (ระดับชาติ)" },
                  { label: "proceeding (ระดับนานาชาติ)", value: "proceeding (ระดับนานาชาติ)" },
                ]}
                onChange={(value) => {
                  setSelectType(value);
                }}
              />
              <Select
                style={{ width: 150 }}
                value={selectYear}
                options={[
                  { label: "2566", value: "2566" },
                  { label: "2565", value: "2565" },
                  { label: "2564", value: "2564" },
                  { label: "2563", value: "2563" },
                ]}
                onChange={(value) => {
                  setSelectYear(value);
                }}
              />
            </Space>

            {/* ตำแหน่งทางวิชาการ */}
            <div className="flex flex-row ">
              <div class="w-full ... ">
                <Table
                  style={{ fontFamily: "Kanit" }}
                  pagination={false}
                  bordered
                  size="middle"
                  dataSource={dateTable}
                  columns={[
                    {
                      title: "ลำดับ",
                      dataIndex: "key",
                      key: "key",
                      align: 'center',
                      width: 60,
                    },
                    {
                      title: "ประเภทผลงาน",
                      // dataIndex: "research_type",
                      // key: "research_type",
                      render: (_, { research_type, research_class }) => {
                        return (
                          <div>
                            <p>{`${research_type} ${research_class ? "(" + MAP[research_class] + ")" : ''}`}</p>
                          </div>
                        )
                      },
                      width: 180,
                    },
                    {
                      title: "ชื่อผู้แต่ง",
                      render: (_, { list_person }) => (
                        <div>
                          {
                            // http://localhost:3000/profile_position/detail/nunurse4
                            Array.isArray(list_person)
                              ? list_person?.map((x) => {
                                return (
                                  <a
                                    href={`/profilePosition/detail/${x.id}`}
                                    key={x.id}
                                  >{`${x.name}, `}</a>
                                );
                              })
                              : null
                          }
                        </div>
                      ),
                      width: 230,
                    },
                    {
                      title: "ชื่อวารสาร/ชื่อการประชุมวิชาการ",
                      render: rederColRight("journal_title"),
                    },
                    {
                      title: "อ้างอิง",
                      dataIndex: "ref",
                      key: "ref",
                      width: 300,
                    },
                    {
                      title: "ฐานข้อมูล",
                      dataIndex: "db",
                      key: "db",
                      width: 120,
                    },
                    {
                      title: "บทคัดย่อ",
                      render: rederButton("url"),
                      width: 40,
                    },
                  ]}
                  line
                />
                {/* data.table1 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
