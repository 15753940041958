import React, { useEffect, useState, useCallback } from "react";
import api from "../../util/api";
import Layout from '../../components/Layout';
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Space,
  Button,
  Input,
  Modal,
  Upload
} from "antd";

import SweetAlert from 'sweetalert2-react';
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import ImgCrop from 'antd-img-crop';
import img_default from '../../images/img_default.png';

const { Text } = Typography;
const { Option } = Select;

const rederButton = (key) => {
  return (_, data) => (
    <Space size="middle">{data.url && <a href={`${data.url}`}>Link</a>}</Space>
  );
};


const rederColRight = (key, keypage, textAlign = "right") => {
  return (_, data) => {
    return {
      props: {
        style: { textAlign: "right" },
      },
      children: (
        <p >
          {data[key]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p >
      ),
    };
  };
};


const MAP = {
  journal: "วารสาร (Journal)",
  proceeding: "ผลงานที่นำเสนอในที่ประชุมวิชาการ (Proceeding)",
  thai: "ระดับชาติ",
  international: "ระดับนานาชาติ",
};

const loadData = async (id) => {
  // let detail = await axios.post("/api/getProfileById", { _id: id });
  // let res = await axios.post("/api/getProfileAll", { staff_id: id });
  let profile = await api.query("db/nunurse/profile/query", {
    aggregate: [{
      $match: { _id: `${id}`, del: { $ne: true } },
    }],
  });


  let research = await api.query("db/nunurse/form_research/query", {
    aggregate: [
      {
        $match: { del: { $ne: true } },
      },
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $match: { 'list_person.id': `${id}` }
      }
    ],
  });

  let research_result = await api.query("db/nunurse/form_research_result/query", {
    aggregate: [
      {
        $match: { del: { $ne: true } },
      },
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $match: { 'list_person.id': `${id}` }
      }
    ],
  });


  let academic_service_project = await api.query("db/nunurse/form_academic_service_project/query", {
    aggregate: [
      {
        $match: { del: { $ne: true } },
      },
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $match: { 'list_person.id': `${id}` }
      }
    ]
  });

  let form_academic_service = await api.query("db/nunurse/form_academic_service/query", {
    aggregate: [
      { $unwind: { path: "$list_person", preserveNullAndEmptyArrays: false } },
      {
        $match: { 'list_person.id': `${id}` }
      }
    ]
  });


  let filename = `${id}.png`
  let url = `https://nursenu.pro/minio/getUrl/nurse-profile/${filename}`
  let resUrlImage = await axios.get(url)

  return {
    profile: profile[0],
    research: research,
    academic_service_project: academic_service_project,
    research_result: research_result,
    form_academic_service: form_academic_service,
    urlImage: resUrlImage.data.url
  };
};

const getBase64 = (img, callback) => {
  try {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  } catch (err) {
    console.log("err: ", err)
  }
};

const onCreateDataTable = (data = []) => {
  try {
    let result = data.map((x, i) => {
      let name = "";
      let ref = "";
      if (x.research_type === "journal") {
        if (x.year) ref = `${ref}(${x.year})`;
        // if (x.journal_title) ref = `${ref}. ${x.journal_title}`;
        if (x.journal_name) ref = `${ref}. ${x.journal_name}`;
        if (x.issue_number) ref = `${ref}, ${x.volume_number}(${x.issue_number})`;
        if (x.pages) ref = `${ref}, ${x.pages}.`;
      } else if (x.research_type === "proceeding") {
        if (x.year) ref = `${ref}(${x.year})`;
        // if (x.journal_title) ref = `${ref}. ${x.journal_title}`;
        if (x.journal_name) ref = `${ref}. ${x.journal_name}`;
        if (x.issue_number) ref = `${ref}, ${x.volume_number}(${x.issue_number})`;
        if (x.pages) ref = `${ref}, ${x.pages}.`;
      }

      return {
        ...x,
        ref,
        index: 1 + i,
        nameHead: name,
      };
    });
    return result
  } catch (err) {
    console.log('err', err)
  }
};

const ResearchDetail = (props) => {
  let id = localStorage.getItem("user");
  let [data, setData] = useState(null);
  let [dataTableAcademicServiceProject, setDataTableAcademicServiceProject] = useState([]);
  let [dataTableResearch, setDataTableResearch] = useState([]);
  let [dataTableResearchResult, setDataTableResearchResult] = useState([]);
  let [dataTableAcademicService, setDataTableAcademicService] = useState([]);
  let [isLoding, setLoding] = useState(true);
  let [image, setImage] = useState(img_default);
  let [imagePreview, setImagePreview] = useState(img_default);
  let [fileImage, setFileImage] = useState([]);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataEdit, setDataEdit] = useState({
    user: ''
  });

  const [dataShow, setDataShow] = useState({
    user: ''
  });

  const [pass, setPass] = useState({
    pass: '',
    confirmPass: ''
  });

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }

  useEffect(() => {
    if (id) {
      loadData(id).then(({ profile, research, academic_service_project, research_result, form_academic_service, urlImage }) => {
        setData(profile);
        setDataShow({ user: id })
        setDataTableResearch([...research]);
        setDataTableAcademicServiceProject([...academic_service_project]);
        setDataTableResearchResult(onCreateDataTable(research_result));
        setDataTableAcademicService([...form_academic_service]);
        setImage(urlImage)
        setImagePreview(urlImage)

        setLoding(false)
      });
    }
  }, []);

  // // upland image profile use andesing

  // useEffect(async () => {

  // }, []);


  if (isLoding) {
    return (<Layout name={"academic_service"}>
      <div className=" bordered-lx p-12 felx w-[1350px] self-center">
        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
          }}
        >
          <p> Loding...</p>
        </div>
      </div>
    </Layout>)
  }

  return (
    <Layout name={"academic_service"}>
      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />
      <Modal
        title="แก้ไขผู้ใช้งาน"
        centered
        visible={visibleEdit}
        onOk={async () => {
          for (let i = 0; i < fileImage.length; i++) {
            let url = 'https://nursenu.pro/minio/presignedUrl/' + 'nurse-profile' + '?name=' + dataEdit.user + '.png'
            console.log("url: [put] ", url)
            let urlForPut = await axios.get(url)
            let res = await axios.put(urlForPut.data, fileImage[i])
            console.log("res: ", res)
          }

          let profile = await api.query("db/nunurse/profile/query", {
            aggregate: [{
              $match: { _id: `${dataEdit.user}`, del: { $ne: true } },
            }],
          });

          profile[0].group = dataEdit.group
          profile[0].title = dataEdit.title
          profile[0].firstName = dataEdit.firstname
          profile[0].lastName = dataEdit.lastname
          profile[0].position = dataEdit.position
          profile[0].tel = dataEdit.tel

          let _dataHome = await api.query('db/cores/role/query', {
            "aggregate": [{
              $match: {
                user: dataEdit.user,
              }
            }]
          })

          profile[0].group = dataEdit.group
          profile[0].title = dataEdit.title
          profile[0].firstName = dataEdit.firstname
          profile[0].lastName = dataEdit.lastname
          profile[0].position = dataEdit.position
          profile[0].tel = dataEdit.tel

          _dataHome[0].profile.group = dataEdit.group
          _dataHome[0].profile.title = dataEdit.title
          _dataHome[0].profile.firstname = dataEdit.firstname
          _dataHome[0].profile.lastname = dataEdit.lastname
          _dataHome[0].profile.position = dataEdit.position
          _dataHome[0].profile.tel = dataEdit.tel

          await api.insert(`db/nunurse/profile/data/${profile[0]._id}`, profile[0])
          await api.insert(`db/cores/role/data/${_dataHome[0]._id}`, _dataHome[0])

          setData(profile[0])
          setVisibleEdit(false)
          setDataEdit({ user: '' })
          setImage(imagePreview)
          setImage(img_default)
          setAlert('แก้ไขผู้ใช้งานสำเร็จ')
        }}

        onCancel={() => {
          setImage(img_default)
          setVisibleEdit(false)
        }}
        width={800}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>

          <div className="flex flex-row">
            <div className="mt-2">
              {imagePreview ? <img
                src={imagePreview}
                alt="avatar"
                style={{ width: 120, margin: 4, borderRadius: '4px' }}
                onError={event => {
                  event.target.src = img_default
                  event.onerror = null
                }}
              /> : null}
              <div className="w-full">
                <ImgCrop
                  style={{ width: '100%' }}
                  modalTitle='เลือกรูปภาพ'
                  quality={1}
                  aspect={6 / 9}
                  modalOk='ตกลง'
                  modalCancel="ยกเลิก"
                  modalProps={{ okButtonProps: { style: { background: 'green' } } }}
                >
                  <Upload
                    name="avatar"
                    style={{ width: '100%' }}
                    className="avatar-uploader"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    showUploadList={false}
                    fileList={fileImage}
                    beforeUpload={(file) => {
                      getBase64(file, (url) => {
                        setImagePreview(url)
                        setFileImage([file])
                      });
                      return false;
                    }}
                  >
                    <div className="w-full p-2 flex justify-center flex-col">
                      <Button style={{ backgroundColor: '#038967', color: '#fff', width: 100, fontSize: '12px', marginLeft: 4 }} shape="round"  > เปลี่ยนรูป </Button>
                    </div>
                  </Upload>
                </ImgCrop>
              </div>
            </div>

            <div className="flex flex-col">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> คำนำหน้า: *</p>
                  <Select

                    placeholder="เลือกคำนำหน้า"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      setDataEdit({ ...dataEdit, ...{ title: e } })
                    }}
                    value={dataEdit?.title}
                  >

                    <Option value="ศ.">ศ.</Option>
                    <Option value="รศ.ดร.">รศ.ดร.</Option>
                    <Option value="ผศ.ดร.">ผศ.ดร.</Option>
                    <Option value="ผศ">ผศ.</Option>
                    <Option value="ดร.">ดร.</Option>
                    <Option value="อ.">อ.</Option>
                    <Option value="นาย">นาย</Option>
                    <Option value="นาง">นาง</Option>
                    <Option value="นางสาว">นางสาว</Option>
                  </Select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> ชื่อ: *</p>
                  <Input
                    placeholder="ชื่อ" style={{ width: 200, marginBottom: 12 }}
                    value={dataEdit?.firstname}
                    onChange={(event) => {
                      setDataEdit({ ...dataEdit, ...{ firstname: `${event.target.value}`.replace(/\s/g, '') } })
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> นามสกุล: *</p>
                  <Input
                    placeholder="นามสกุล"
                    style={{ width: 200, marginBottom: 12 }}
                    value={dataEdit?.lastname}
                    onChange={(event) => {
                      setDataEdit({ ...dataEdit, ...{ lastname: `${event.target.value}`.replace(/\s/g, '') } })
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> กลุ่มวิชาการ *</p>
                  <Select
                    placeholder="โปรดเลือกกลุ่มวิชาการ"
                    style={{ width: 300 }}
                    onChange={(e) => {
                      setDataEdit({ ...dataEdit, ...{ group: e } })
                    }}
                    value={dataEdit?.group}
                  >
                    <Option value={'กลุ่มวิชาการพยาบาลมารดาและทารก'}>{'กลุ่มวิชาการพยาบาลมารดาและทารก'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลเด็ก'}>{'กลุ่มวิชาการพยาบาลเด็ก'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลผู้ใหญ่'}>{'กลุ่มวิชาการพยาบาลผู้ใหญ่'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลผู้สูงอายุ'}>{'กลุ่มวิชาการพยาบาลผู้สูงอายุ'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลชุมชน'}>{'กลุ่มวิชาการพยาบาลชุมชน'}</Option>
                    <Option value={'กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช'}>{'กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช'}</Option>
                    <Option value={'กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ'}>{'กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ'}</Option>

                  </Select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> ตำแหน่ง *</p>
                  <Select
                    placeholder="โปรดเลือกตำแหน่ง"
                    style={{ width: 300 }}
                    onChange={(e) => {
                      setDataEdit({ ...dataEdit, ...{ position: e } })
                    }}
                    value={dataEdit?.position}
                  >
                    <Option value={'อาจารย์'}>{'อาจารย์'}</Option>
                    <Option value={'ผู้ช่วยศาสตราจารย์'}>{'ผู้ช่วยศาสตราจารย์'}</Option>
                    <Option value={'รองศาสตราจารย์'}>{'รองศาสตราจารย์'}</Option>
                    <Option value={'ศาสตราจารย์'}>{'ศาสตราจารย์'}</Option>

                  </Select>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
                  <p> โทรศัพท์: </p>
                  <Input
                    value={dataEdit?.tel}
                    placeholder="ระบุโทรศัพท์" style={{ width: 200, marginBottom: 12 }}
                    onChange={(event) => {
                      setDataEdit({ ...dataEdit, ...{ tel: event.target.value } })
                    }}
                  />
                </div>
              </div>

            </div>
          </div>

        </div>


      </Modal>

      <Modal
        title="รีเซ็ตพาสเวิร์ด"
        centered
        visible={visible}
        onOk={async () => {
          if (pass.pass.length < 8) return setAlert('โปรดใส่พาสเวิร์ดอย่างน้อย 8 ตัว')
          if (pass.pass !== pass.confirmPass) return setAlert('รหัสผ่านใหม่ไม่ตรงกัน')

          let _pass = await api.encryption(pass.pass)
          let pre_data = await api.query(`db/cores/user/query`, {
            query: { User: dataShow.user }
          })
          if (pre_data.length > 0) {
            pre_data[0].Pass_Salt = _pass.pass_salt
            pre_data[0].Pass_Hash = _pass.pass_hash
            console.log("pre_data", pre_data)
            await api.insert(`db/cores/user/data/${pre_data[0]._id}`, pre_data[0])
          }
          setVisible(false)
          setPass({
            pass: '',
            confirmPass: ''
          })
          setAlert('รีเซ็ตพาสเวิร์ดสำเร็จ')
        }}
        onCancel={() => {
          setVisible(false)
          setPass({
            pass: '',
            confirmPass: ''
          })
        }}
        width={800}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
      >
        <p>Username: {dataShow.user}</p>
        <p>Password: </p>
        <Input
          name="pass"
          type="password"
          placeholder="Password" style={{ width: 200, marginBottom: 12 }}
          value={pass.pass}
          onChange={(event) => {
            setPass({ ...pass, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
          }}
        />

        <p>Confirm Password: </p>
        <Input
          name="pass"
          type="password"
          placeholder="Password" style={{ width: 200, marginBottom: 12 }}
          value={pass.confirmPass}
          onChange={(event) => {
            setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
          }}
        />
        <p></p>
      </Modal>

      <div className=" bordered-lx p-12 felx w-[1350px] self-center">
        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
          }}
        >
          <div className="flex row">
            <div className="flex flex-1">
              <p className="text-2xl">ข้อมูลส่วนตัว</p>
            </div>

            <div style={{ fontSize: 20 }} level={2}>
              <Button style={{ backgroundColor: '#038967', marginRight: 12, color: '#fff' }} shape="round" size={12}
                onClick={() => {
                  setVisibleEdit(true)
                  setDataEdit({
                    user: data._id,
                    firstname: data.firstName,
                    group: data.group,
                    tel: data.tel,
                    position: data.position,
                    lastname: data.lastName,
                    title: data.title,
                    // email: data.email,
                  })

                }}
              >
                แก้ไขข้อมูลส่วนตัว
              </Button>
              <Button style={{ backgroundColor: '#038967', color: '#fff' }} shape="round" size={12}
                onClick={() => {
                  console.log("dataEdit ", data)
                  setVisible(true)
                }}
              >
                แก้ไขรหัสผ่าน
              </Button>
            </div>
          </div>
          <div className="w-full h-[2px] bg-gray-200 my-2"></div>
          <Row>
            <Col span={4}>
              <img
                style={{ borderRadius: 8 }}
                alt={`description`}
                src={image}
                width="150px"
                height="90px"
              />
            </Col>
            <Col span={20}>

              <div style={{ fontSize: 20 }} level={2}>
                ชื่อ - นามสกุล :
                {` ${data?.title}${data?.firstName} ${data?.lastName}`}
              </div>
              <div style={{ fontSize: 20 }} level={2}>
                สังกัด :{`${data?.group}`}
              </div>
              <div style={{ fontSize: 20 }} level={2}>
                ตำแหน่ง :{`${data?.position}`}
              </div>
              <div style={{ fontSize: 20 }} level={2}>
                โทรศัพท์ :{`${data?.tel}`}
              </div>
              <div style={{ fontSize: 20 }} level={2}>
                Email :{`${data?.email}`}
              </div>
            </Col>
          </Row>
          <div style={{ flex: 1, fontSize: 24, marginTop: 32 }}>โครงการวิจัย</div>
          <div
            style={{
              height: "4px",
              marginBottom: 4,
              width: "40%",
              backgroundColor: "#FF6600",
            }}
          ></div>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={dataTableResearch.
              sort(
                (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0)
              .map((x, i) => ({ ...x, index: i + 1 }))}
            columns={[
              {
                title: "ลำดับ",
                dataIndex: "index",
                key: "index",
                width: 20,
              },
              {
                title: "ปี",
                dataIndex: "year",
                key: "year",
                width: 20,
              },
              {
                dataIndex: "project_name_th",
                key: "project_name_th",
                title: "ชื่อโครงการวิจัย",
                width: 200,
              },
              {
                title: "งบประมาณ",
                render: rederColRight('total_budget_received'),
                width: 60,
              },
              {
                title: "แหล่งทุน",
                dataIndex: "budget_source",
                key: "budget_source",
                width: 100,
              },
            ]}
            line
          />
          <div style={{ flex: 1, fontSize: 24, marginTop: 48 }}>
            ผลงานวิจัย/ตีพิมพ์/นำเสนอ
          </div>
          <div
            style={{
              height: "4px",
              marginBottom: 4,
              width: "40%",
              backgroundColor: "#FF6600",
            }}
          ></div>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={dataTableResearchResult.
              sort(
                (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0)
              .map((x, i) => ({ ...x, index: i + 1 }))}
            columns={[
              {
                title: "ลำดับ",
                dataIndex: "index",
                key: "index",
                align: 'center',
                width: 60,
              },
              {
                title: "ปี",
                dataIndex: "year",
                key: "year",
                width: 20,
              },
              {
                title: "ประเภทผลงาน",
                render: (_, { research_type, research_class }) => {
                  return (
                    <div>
                      <p>{`${research_type} ${research_class ? "(" + MAP[research_class] + ")" : ''}`}</p>
                    </div>
                  )
                },
                width: 180,
              },
              {
                title: "ชื่อวารสาร/ชื่อการประชุมวิชาการ",
                dataIndex: "journal_title",
                key: "journal_title",
              },
              {
                title: "อ้างอิง",
                dataIndex: "ref",
                key: "ref",
                width: 300,
              },
              {
                title: "ฐานข้อมูล",
                dataIndex: "db",
                key: "db",
                width: 120,
              },
              {
                title: "บทคัดย่อ",
                render: rederButton("url"),
                width: 40,
              },
            ]}
            line
          />
          <div style={{ flex: 1, fontSize: 24, marginTop: 48 }}>
            โครงการบริการวิชาการ
          </div>
          <div
            style={{
              height: "4px",
              marginBottom: 4,
              width: "40%",
              backgroundColor: "#FF6600",
            }}
          ></div>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={dataTableAcademicServiceProject.
              sort(
                (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0)
              .map((x, i) => ({ ...x, index: i + 1 }))}
            columns={[
              {
                title: "ลำดับ",
                dataIndex: "index",
                key: "index",
                width: 20,
              },
              {
                title: "ปี",
                dataIndex: "year",
                key: "year",
                width: 20,
              },
              {
                title: "ชื่อโครงการบริการวิชาการ",
                render: (_, data) => (
                  <Space size="middle">
                    <a href={`/AcademicServiceProject/detail/${data._id}`}>{data.project_name_th}</a>
                  </Space>),
                width: 200,
              },
              {
                title: "งบประมาณ",
                render: rederColRight('total_budget_received'),
                width: 60,
              },
              {
                title: "แหล่งทุน",
                dataIndex: "budget_source",
                key: "budget_source",
                width: 100,
              },
            ]}
            line
          />
          <div style={{ flex: 1, fontSize: 24, marginTop: 48 }}>
            การให้บริการวิชาการ
          </div>
          <div
            style={{
              height: "4px",
              marginBottom: 4,
              width: "40%",
              backgroundColor: "#FF6600",
            }}
          ></div>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={dataTableAcademicService.
              sort(
                (p1, p2) => (p1.year < p2.year) ? 1 : (p1.year > p2.year) ? -1 : 0)
              .map((x, i) => ({ ...x, index: i + 1 }))}
            columns={[
              {
                title: "No.",
                dataIndex: "index",
                key: "index",
                width: 20,
              },
              {
                title: "หัวข้อเรื่อง",
                render: (_, data) => (
                  <Space size="middle">
                    <a href={`/AcademicService/detail/${data._id}`}>{data.topic}</a>
                  </Space>),
                width: 200,
              },
              {
                title: "ชื่อหน่วยงานที่จัด",
                dataIndex: "location",
                key: "location",
                width: 80,
              },
              {
                title: "ประเภทของผู้ให้บริการ",
                dataIndex: "service_type",
                key: "service_type",
                width: 60,
              },
            ]}
            line
          />
        </div>
      </div>
    </Layout >
  );
};
export default ResearchDetail;
