import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Table,
  Typography,
  Row,
  Progress,
  Space,
  Button,
  Breadcrumb,
  Modal
} from "antd";
import api from "../../util/api";
import { DownloadOutlined } from '@ant-design/icons';
import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const columnsProfile = [
  {
    title: "ลำดับ",
    dataIndex: "key",
    key: "key",
    align: "center",
    width: 10,
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "ตำแหน่ง",
    align: "center",
    dataIndex: "positon",
    key: "positon",
    width: 80,
  },
  // {
  //   title: "สัดส่วน",
  //   align: "center",
  //   dataIndex: "percent",
  //   key: "percent",
  //   width: 60,
  // },
];

const columnsFile = [
  {
    title: "ลำดับ",
    dataIndex: "index",
    key: "index",
    width: 20,
  },
  {
    title: "ชื่อไฟล์",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "ดาวน์โหลด",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          icon={<DownloadOutlined />}
          onClick={data.onDonwlaod}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "green",
            color: "white",
          }}
        >
          download
        </Button>
      </div >
    ),
    width: 140,
  },
];


const fetchData = async (id) => {
  try {
    let aggregate = [
      {
        $match: { _id: `${id}` },
      },
    ];

    let _data = await api.query(
      "db/nunurse/form_academic_service_project/query",
      {
        aggregate: aggregate,
      }
    );

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const { Text } = Typography;

export default function Home(props) {
  let history = useHistory();

  let id = props?.match?.params?.id;
  let [isLogin, setLogin] = useState(false);
  let [isEdit, setEdit] = useState(false);
  let [isDel, setDel] = useState(false);


  let [form, setForm] = useState({
    _id: null,
    group: null,
    year: null,
    budget_source: null,
    budget_outsource_name: null,
    project_type: null,
    project_code: null,
    project_status: null,
    project_name_th: null,
    project_name_en: null,
    list_person: [],
    project_nature: null,
    university_strategy: null,
    faculty_strategy: null,

    main_personnel_compensation: 0,
    support_personnel_compensation: 0,
    operation_cost: 0,
    fee: 0,
    total_budget_received: 0,
    project_status: null,

    start_date: null,
    end_date: null,

    person_responsible: null,
    joint_gency: null,
    coordinator: null,

    agency: null,

    create_date: "null",
    times: "null",
    timestamp: "",
    create_by: "",
  });

  const [ModalMessage, setModalMessage] = useState({
    show: false,
    title: "",
    message: "",
  });

  React.useEffect(async () => {
    let user_id = localStorage.getItem("user");
    let user = localStorage.getItem("user_nurse");
    let userdata = localStorage.getItem("user_data");
    let role = localStorage.getItem("role");

    let _data = await fetchData(id);
    if (_data.length > 0) {
      setForm(_data[0]);
      if (user && userdata) {
        let item = _data[0].list_person.find(x => x.id === user_id)
        if (item) setEdit(true)
        if (role === '["admin"]') {
          setEdit(true)
          setDel(true)
        }
        setLogin(true);
      }
    }

    // setDateTable(onCreateDataTable(_data, year))
  }, []);

  return (
    <Layout>
      <Modal
        title={null}
        centered
        width={400}
        okText={"ลบ"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "red" } }}
        open={ModalMessage.show}
        onOk={async () => {
          let _form = { ...form }
          _form['del'] = true;
          await api.insert(
            `db/nunurse/form_academic_service_project/data/${_form._id}`,
            _form
          );
          setModalMessage({ ...ModalMessage, show: false, title: "" });
          history.goBack()
        }}
        onCancel={() => {
          setModalMessage({ ...ModalMessage, show: false, title: "" });
        }}
      >
        <div
          style={{ width: "100%", flex: 1, display: "flex", marginBottom: 12 }}
        >
          <p className="text-2xl">คุณต้องการลบข้อมูลนี้หรือไม่</p>
        </div>
      </Modal>
      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          width: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            การบริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            ตารางแสดงโครงการบริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            {form.project_name_th}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ backgroundColor: "#fff", padding: 24, borderRadius: 12 }}>
          <div className="flex-row flex justify-between mb-4">
            {/* space-between */}
            <p class="text-center text-2xl">{form.project_name_th}</p>
            <div class="flex flex-row">
              {
                isDel &&
                <div>
                  <Button
                    shape="round"
                    style={{ backgroundColor: "#ff4030", color: "white", marginRight: "10px" }}
                    // icon={<TableOutlined />}
                    onClick={() => {
                      setModalMessage({ ...ModalMessage, show: true, title: "" });
                    }}
                    size="large"
                  >
                    ลบ
                  </Button>
                </div>
              }
              {
                isEdit &&
                <div>
                  <Button
                    shape="round"
                    style={{ backgroundColor: "#1677FF", color: "white" }}
                    // icon={<TableOutlined />}
                    onClick={() => history.push(`/AcademicServiceProject/edit/${form._id}`)}
                    size="large"
                  >
                    แก้ไข
                  </Button>
                </div>
              }
            </div>
          </div>

          <Divider />
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ปีงบประมาณ :</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {form.year}
                </Text>
              </Space>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>แหล่งเงินงบประมาณ :</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {form.budget_source}
                </Text>
              </Space>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>รหัสโครงการ :</Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.project_code}
                </Text>
              </Space>
            </div>
          </Row>

          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 8,
                flex: 1,
              }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ชื่อโครงการ (ไทย) :</Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.project_name_th}
                </Text>
              </Space>
            </div>
          </Row>

          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 8,
                flex: 1,
              }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ชื่อโครงการ (Eng) :</Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.project_name_en}
                </Text>
              </Space>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>สถานะโครงการ :</Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.project_status}
                </Text>
              </Space>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ประเภทโครงการ :</Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.project_type}
                </Text>
              </Space>
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>กลุ่มวิชาการ :</Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.group}
                </Text>
              </Space>
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>หน่วยงานร่วม :</Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.agency}
                </Text>
              </Space>
            </div>
          </Row>
          {form.budget_source === "งบประมาณภายนอก" && (
            <Row>
              <div
                style={{ display: "flex", flexDirection: "column", margin: 8 }}
              >
                <Space>
                  <Text style={{ fontSize: 16 }}>หน่วยงานให้ทุน :</Text>
                  <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {form.budget_outsource_name}
                  </Text>
                </Space>
              </div>
            </Row>
          )}

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ลักษณะโครงการ :</Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.project_nature}
                </Text>
              </Space>
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>
                  ความสอดคล้องกับยุทธศาสตร์ของมหาวิทยาลัย :
                </Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.university_strategy}
                </Text>
              </Space>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>
                  ความสอดคล้องกับยุทธศาสตร์ของคณะ :
                </Text>
                <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {form.faculty_strategy}
                </Text>
              </Space>
            </div>
          </Row>
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
            marginTop: 32,
          }}
        >
          <Text style={{ fontSize: 24 }}>คณะกรรมการ</Text>
          <Divider />
          {/* <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "18px" }}>
                ผู้รับผิดชอบโครงการ :{form.person_responsible}
              </Text>
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text style={{ fontSize: "18px" }}>
                ผู้ประสานงาน :{form.coordinator}
              </Text>
            </div>
          </Row> */}
          <div style={{ marginTop: 24 }}>
            <Table
              pagination={false}
              bordered
              size="middle"
              dataSource={
                form?.list_person
                  ? form.list_person.map((x, i) => ({ ...x, key: i + 1 }))
                  : []
              }
              columns={columnsProfile}
              line
            />
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
            marginTop: 32,
          }}
        >
          <Text style={{ fontSize: 24 }}>
            งบประมาณที่ได้รับ :{" "}
            {/* {(
              (form.main_personnel_compensation ?
                form.main_personnel_compensation : 0) +
              (form.support_personnel_compensation
                ? form.support_personnel_compensation
                : 0) +
              (form.operation_cost ? form.operation_cost : 0) +
              (form.fee ? form.fee : 0)
            ) */}
            {(
              (form.main_personnel_compensation
                ? form.main_personnel_compensation
                : 0) +
              (form.fee_university ? form.fee_university : 0) +
              (form.operation_cost ? form.operation_cost : 0) +
              (form.fee_faculty ? form.fee_faculty : 0)
            )
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " บาท"}
          </Text>
          <Divider />
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>ค่าดำเนินงาน :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {" "}
                {(form.operation_cost ? form.operation_cost : 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " บาท"}
              </Text>
            </Space>
          </Row>
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>ค่าตอบแทนบุคลากรหลัก :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {" "}
                {(form.main_personnel_compensation
                  ? form.main_personnel_compensation
                  : 0
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " บาท"}
              </Text>
            </Space>
          </Row>
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>ค่าธรรมเนียมมหาลัย :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {" "}
                {(form.fee_university ? form.fee_university : 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " บาท"}
              </Text>
            </Space>
          </Row>
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>ค่าธรรมเนียมคณะ :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {" "}
                {(form.fee_faculty ? form.fee_faculty : 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " บาท"}
              </Text>
            </Space>
          </Row>
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
            marginTop: 32,
          }}
        >
          <Text style={{ fontSize: 24 }}>ไฟล์</Text>
          <Divider />
          <Row>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Table
                bordered
                size="middle"
                // dataSource={form?.fileList}
                columns={columnsFile}
                dataSource={form?.fileList?.map((x, i) => ({
                  ...x, index: i + 1, onDonwlaod: (d) => {
                    fetch(`https://nursenu.pro/minio/download/test/${x.nameMinio}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/pdf',
                      },
                    })
                      .then((response) => response.blob())
                      .then((blob) => {
                        const url = window.URL.createObjectURL(
                          new Blob([blob], { type: 'application/pdf' }),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = x.name;
                        link.click();
                        link.parentNode.removeChild(link);
                      });
                  }
                }))}

                line
              />
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
}
