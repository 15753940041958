import * as React from 'react';
import Header from './Header'


export default function Layout({ children, ispadding }) {
  return (
    <div style={{ backgroundColor: "#F3F4F9" }}>
      <Header />
      {ispadding && <div style={{ height: '80px' }} />}
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', justifyItems: 'center' }}>
        {children}
      </div >
    </div >
  )
}
