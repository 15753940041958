import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import {
  DatePicker,
  Divider,
  Form,
  Spin,
  Input,
  Select,
  Typography,
  Button,
  Upload,
  InputNumber,
  Modal,
  Space,
  Alert,
  Row,
  Table,
  Breadcrumb,
  Checkbox
} from "antd";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import api from "../../util/api";
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { v4 } from "uuid";
import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";
const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const default_modal_person = {
  name: null,
  title: null,
  firstName: null,
  lastName: null,
  id: null,
  positon: null,
  percent: null,
  hour: null,
  checkNoDB: false,
}

const fecthData = async (id) => {
  try {
    let profileform = await api.query("db/nunurse/profile/query", {
      aggregate: [],
    });

    let agency = await api.query("db/nunurse/agency/query", {
      aggregate: [],
    });
    let form = null
    if (id) {
      form = await api.query("db/nunurse/form_research/query", {
        aggregate: [
          {
            $match: { _id: `${id}` },
          },
        ],
      });
    }

    return {
      agency,
      profileform,
      form
    };
  } catch (err) {
    console.log("error: " + err);
  }
};


const columnsProfile = [
  {
    title: "No.",
    dataIndex: "key",
    key: "key",
    align: "center",
    width: 10,
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "ตำแหน่ง",
    align: "center",
    dataIndex: "positon",
    key: "positon",
    width: 80,
  },
  {
    title: "สัดส่วน",
    align: "center",
    dataIndex: "percent",
    key: "percent",
    width: 60,
  },
  {
    title: "แก้ไข/ลบ",
    align: "center",
    dataIndex: "budget_source",
    key: "budget_source",
    render: (_, data) => (
      <div
        style={{
          width: 30,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          onClick={data.onEdit}
          style={{
            width: 100,
            margin: 2,
            backgroundColor: "yellow",
            color: "black",
          }}
        >
          แก้ไข
        </Button>
        <Button
          onClick={data.onRemove}
          style={{
            width: 100,
            margin: 2,
            backgroundColor: "red",
            color: "white",
          }}
        >
          ลบ
        </Button>
      </div >
    ),
    width: 30,
  },
];


const columnsFile = [
  {
    title: "ลำดับ",
    dataIndex: "index",
    key: "index",
    width: 20,
  },
  {
    title: "ชื่อไฟล์",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "โหลด",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          icon={<DownloadOutlined />}
          onClick={data.onDonwlaod}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "green",
            color: "white",
          }}
        >
          download
        </Button>
      </div >
    ),
    width: 140,
  },
  {
    title: "ลบ",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          onClick={data.onRemove}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "red",
            color: "white",
          }}
        >
          ลบ
        </Button>
      </div >
    ),
    width: 140,
  },
];


export default function Research(props) {
  let history = useHistory();
  let form_id = props?.match?.params?.id;
  let [jointAgency, setJointAgency] = useState([]);
  let [profiles, setProfiles] = useState([]);
  let [addAgency, setAddAgency] = useState("");

  let [addModelPersonInTabel, setAddModelPersonInTabel] = useState({
    name: null,
    title: null,
    firstName: null,
    lastName: null,
    id: null,
    positon: null,
    percent: null,
    hour: null,
    checkNoDB: false,
  });

  let [addPerson, setAddPerson] = useState({
    title: null,
    firstName: null,
    lastName: null,
  });

  //
  let [dataTable, setDateTable] = useState([]);
  let [dataTableListProfile, setDateTableListProfile] = useState([]);
  let [errMessagesModal1, setErrorsModal1] = useState(null);
  const [isModalOpenFile, setIsModalOpenFile] = useState(false);
  let [form, setForm] = useState({
    _id: null,
    group: null,
    year: null,
    budget_source: null,
    budget_outsource_name: null,
    project_type: null,
    project_code: null,
    project_status: null,
    project_name_th: null,
    project_name_en: null,
    list_person: [],
    project_nature: null,
    university_strategy: null,
    faculty_strategy: null,

    main_personnel_compensation: 0,
    fee_faculty: 0,
    operation_cost: 0,
    fee_university: 0,
    total_budget_received: 0,
    project_status: null,

    start_date: null,
    end_date: null,

    person_responsible: null,
    joint_gency: null,
    coordinator: null,

    agency: null,

    create_date: "null",
    times: "null",
    timestamp: "",
    create_by: "",
  });

  const onChangeSelect = (value, id) => {
    setForm({
      ...form,
      [`${id}`]: value,
    });
  };

  const onChangeTextInputNumber = (value, id) => {
    setForm({
      ...form,
      [`${id}`]: value,
    });
  };

  const onChangeTextInput = (e) => {
    let { id, value } = e.target;
    if (id !== undefined && value !== undefined) {
      setForm({
        ...form,
        [`${id}`]: value,
      });
    }
  };

  const [fileList, setFileList] = useState([]);
  const [fileListUpload, setFileListUpload] = useState([]);
  const [uploading, setUploading] = useState(false);
  const handleUpload = async () => {
    let listDocFile = []
    const formData = new FormData();
    fileListUpload.forEach((file) => {
      formData.append('files[]', file);
      console.log("formData >>: ", formData)
    });
    setUploading(true);

    for (let i = 0; i < fileListUpload.length; i++) {
      let filename = v4().toString().replace(/-/g, "");
      let url = 'https://nursenu.pro/minio/presignedUrl/' + 'test' + '?name=' + filename
      listDocFile.push({
        path: '',
        bucket: 'test',
        name: fileListUpload[i].name,
        nameMinio: filename,
      })
      let urlForPut = await axios.get(url)
      let res = await axios.put(urlForPut.data, fileListUpload[i])
      console.log("res", res)
    }
    setFileList([...fileList, ...listDocFile])
    setUploading(false)
    setIsModalOpenFile()
    setIsModalOpenFile(false)
    setFileListUpload([])
  };

  const onFinish = async (message) => {
    try {

      if (!form.year)
        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณากรอบข้อมูลปีงบประมาณ' } });

      if (!form.budget_source)
        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณากรอบข้อมูลแหล่งเงินงบประมาณ' } });

      // if (!form.project_code)
      //   return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณากรอบข้อมูลรหัสโครงการ' } });

      if (!form.project_name_th)
        return setShow({
          ...show, ...{
            show: true, title: 'แจ้งเตือน', message: 'กรุณากรอบข้อมูลชื่อโครงการ (ไทย)'
          }
        });


      if (!form?._id) {
        form._id = v4().toString().replace(/-/g, "");
      }
      form.list_person = [...dataTableListProfile];
      form.fileList = [...fileList];
      let headProject = dataTableListProfile.find(
        (x) => x.positon === "หัวหน้าวิจัย"
      );
      if (headProject) {
        if (headProject.group) {
          form.group = headProject.group;
        }
      }

      await api.insert(`db/nunurse/form_research/data/${form._id}`, form);

      message(form._id);
    } catch (err) {
      console.log(">> err", err);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);


  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const showModal3 = () => {
    setIsModalOpen3(true);
  };

  const [ModalMessage, setModalMessage] = useState({
    show: false,
    title: "",
    message: "",
  });

  const [show, setShow] = useState({
    show: false,
    title: "",
    message: "",
  });

  const [Edit, setEdit] = useState({
    index: null,
    checked: false,
  });

  useEffect(() => {
    fecthData(form_id).then(({ agency, profileform, form }) => {
      // console.log("data: >", data)
      setJointAgency(agency);
      setProfiles(profileform);
      if (form.length > 0) {
        setForm({ ...form[0] });
        setDateTableListProfile([...form[0].list_person])
        setFileList([...form[0].fileList])
      }
    });
  }, []);

  let onGenDataTable = (_data = []) => {
    let result = _data.map((x, i) => ({
      ...x, index: i + 1,
      onRemove: () => {
        const newFileList = fileList.slice();
        newFileList.splice(i, 1)
        setFileList([...newFileList])
      },
      onDonwlaod: (d) => {
        fetch(`https://nursenu.pro/minio/download/test/${x.nameMinio}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(
              new Blob([blob], { type: 'application/pdf' }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.download = x.name;
            link.click();
            link.parentNode.removeChild(link);
          });
      }
    }))
    return result
  }

  return (
    <Layout>
      {/*  เพิ่มหน่วยงาน */}

      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } });
        }}
      />

      <Modal
        title="อัพโหลดไฟล์"
        centered
        okText={"เพิ่ม"}
        cancelText={"ปิด"}
        width={800}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpenFile}
        maskClosable={false}
        onOk={async () => {
          try {
            if (!uploading) {
              handleUpload()
            } else {
              alert('กำลังอัพโหลดข้อมูลอยู่')
            }
          } catch (err) { }
        }}
        onCancel={() => {
          if (!uploading) {
            setIsModalOpenFile(false)
          } else {
            alert('กำลังอัพโหลดข้อมูลอยู่')
          }
        }}
      >
        <Spin tip="upload..." spinning={uploading}>
          <Upload
            onRemove={(file) => {
              const index = fileListUpload.indexOf(file);
              const newFileList = fileListUpload.slice();
              newFileList.splice(index, 1);
              setFileListUpload(newFileList);
            }}
            beforeUpload={(file) => {
              setFileListUpload([...fileListUpload, file])
              return false;
            }}
            fileList={fileListUpload}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Spin>
      </Modal>

      <Modal
        title="เพิ่มหน่วยงาน"
        centered
        okText={"เพิ่ม"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpen}
        onOk={async () => {
          try {
            let item = jointAgency.find((x) => x._id === addAgency);
            if (!addAgency) {
              setErrorsModal1("โปรดใส่ข้อมูล");
            } else if (item) {
              setErrorsModal1(`${addAgency} มีอยู่ในระบบแล้ว`);
            } else {
              let doc = {
                _id: addAgency,
                name: addAgency,
                crete: new Date().getTime(),
              };
              await axios.post("/api/addAgency", { doc });
              setJointAgency([doc, ...jointAgency]);
            }
          } catch (err) { }
        }}
        onCancel={() => {
          setAddAgency("");
          setIsModalOpen(false);
        }}
      >
        <p>หน่วยงานร่วม :</p>
      </Modal>

      {/*  เพิ่มหน่วยงาน */}
      <Modal
        title="เพิ่มหน่วยงาน"
        centered
        okText={"เพิ่ม"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpen}
        onOk={async () => {
          try {
            let item = jointAgency.find((x) => x._id === addAgency);
            if (!addAgency) {
              setErrorsModal1("โปรดใส่ข้อมูล");
            } else if (item) {
              setErrorsModal1(`${addAgency} มีอยู่ในระบบแล้ว`);
            } else {
              let doc = {
                _id: addAgency,
                name: addAgency,
                crete: new Date().getTime(),
              };
              await axios.post("/api/addAgency", { doc });
              setJointAgency([doc, ...jointAgency]);
            }
          } catch (err) { }
        }}
        onCancel={() => {
          setAddAgency("");
          setIsModalOpen(false);
        }}
      >
        {/* <p>หน่วยงานร่วม :</p> */}
        <Input
          id="addAgency"
          placeholder="โปรดใส่หน่วยงานร่วม"
          maxLength={50}
          value={addAgency}
          onChange={(e) => {
            let { id, value } = e.target;
            setAddAgency(value);
          }}
        />
        {/* addAgency, setAddAgenc */}
        {errMessagesModal1 && (
          <Alert
            message={errMessagesModal1}
            type="error"
            style={{ marginTop: 12 }}
          />
        )}
        <Divider style={{ margin: 12 }} />
      </Modal>

      {/*  เพิ่มรายชื่อ */}
      <Modal
        title="เพิ่มรายชื่อ"
        centered
        width={1000}
        okText={"เพิ่ม"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpen2}
        onOk={async () => {
          try {
            await axios.post("/api/addPerson", {
              doc: {
                ...addPerson,
                ...{
                  _id: (form._id = v4().toString().replace(/-/g, "")),
                  crete: new Date().getTime(),
                },
              },
            });
          } catch (err) {
            console.log("err", err);
          }
        }}
        onCancel={() => {
          setAddPerson({
            title: null,
            firstName: null,
            lastName: null,
          });
          setErrorsModal1(null);
          setIsModalOpen2(false);
        }}
      >
        {/* <p>หน่วยงานร่วม :</p> */}
        <Space>
          <Select
            style={{ width: 100 }}
            value={addPerson.title}
            placeholder=""
            allowClear
            onChange={(value) => {
              setAddPerson({ ...addPerson, title: value });
              // onChangeSelect(value, 'budget_source')
            }}
          >
            <Option value="ศ.">ศ.</Option>
            <Option value="รศ.ดร.">รศ.ดร.</Option>
            <Option value="ผศ.ดร.">ผศ.ดร.</Option>
            <Option value="ผศ">ผศ.</Option>
            <Option value="ดร.">ดร.</Option>
            <Option value="นาย">นาย</Option>
            <Option value="นาง">นาง</Option>
            <Option value="นางสาว">นางสาว</Option>
          </Select>
          <Input
            style={{ width: 200 }}
            id="addAgency"
            placeholder="โปรดใส่หน่วยงานร่วม"
            maxLength={50}
            value={addPerson.firstName}
            onChange={(e) => {
              let { id, value } = e.target;
              setAddPerson({ ...addPerson, firstName: value });
            }}
          />
          <Input
            style={{ width: 200 }}
            id="addAgency"
            placeholder="โปรดใส่หน่วยงานร่วม"
            maxLength={50}
            value={addPerson.lastName}
            onChange={(e) => {
              let { id, value } = e.target;
              setAddPerson({ ...addPerson, lastName: value });
            }}
          />
        </Space>

        {/* addAgency, setAddAgenc */}
        {errMessagesModal1 && (
          <Alert
            message={errMessagesModal1}
            type="error"
            style={{ marginTop: 12 }}
          />
        )}
        <Divider style={{ margin: 12 }} />
      </Modal>

      <Modal
        title="เพิ่มรายชื่อคณะผู้วิจัย"
        centered
        width={800}
        okText={"บันทึก"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpen3}
        onOk={async () => {
          try {
            let person = { ...addModelPersonInTabel }

            if (
              person.title === null ||
              person.firstName === null ||
              person.lastName === null
            ) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ ชื่อ-นามสกุล' } });
            }


            if (person.positon === null) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ตำแหน่ง' } });
            }


            if (
              person.percent === null ||
              person.percent === 0
            ) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่สัดส่วน' } });
            }

            if (person.checkNoDB) {
              person['name'] = `${person.title}${person.firstName} ${person.lastName} `
              person['id'] = null
              person['group'] = null
            }

            if (Edit.checked) {
              dataTableListProfile[Edit.index] = person
              setDateTableListProfile([...dataTableListProfile])
            } else {
              setDateTableListProfile([
                ...dataTableListProfile,
                person
              ]);
            }

            setAddModelPersonInTabel({ ...default_modal_person });
            setEdit({
              index: null,
              checked: false
            })
            setIsModalOpen3(false);
          } catch (err) {
            console.log("err", err);
          }
        }}
        onCancel={() => {
          setAddModelPersonInTabel({ ...default_modal_person });
          setErrorsModal1(null);
          setIsModalOpen3(false);
          setEdit({
            index: null,
            checked: false
          })
        }}
      >
        <div className="flex flex-col">
          {
            false === addModelPersonInTabel.checkNoDB ?
              (
                <Space >
                  <p>ชื่อ-นามสกุล :</p>
                  <Select
                    style={{ width: 300 }}
                    showSearch
                    value={addModelPersonInTabel.name}
                    placeholder=""
                    onChange={(value) => {
                      let item = profiles.find(
                        (x) => `${x.title}${x.firstName} ${x.lastName} ` === value
                      );
                      if (item) {
                        setAddModelPersonInTabel({
                          ...addModelPersonInTabel,
                          name: `${item.title}${item.firstName} ${item.lastName} `,
                          title: item.title,
                          firstName: item.firstName,
                          lastName: item.lastName,
                          id: item._id,
                          group: item.group,
                        });
                      }
                    }}
                  >
                    {profiles.map((x) => (
                      <Option
                        key={`${x.title}${x.firstName} ${x.lastName} `}
                        value={`${x.title}${x.firstName} ${x.lastName} `}
                      >{`${x.title}${x.firstName} ${x.lastName} `}</Option>
                    ))}
                  </Select>
                </Space>
              ) :
              (
                <Space style={{ marginBottom: 12 }}>
                  <p>ชื่อ-นามสกุล</p>
                  <Select
                    style={{ width: 120 }}
                    showSearch
                    value={addModelPersonInTabel.title}
                    placeholder="คำนำหน้า"
                    onChange={(value) => {
                      setAddModelPersonInTabel({
                        ...addModelPersonInTabel,
                        name: ``,
                        title: value,
                        id: null,
                        group: null,
                      });
                    }}
                  >
                    <Option value="ศ.">ศ.</Option>
                    <Option value="รศ.ดร.">รศ.ดร.</Option>
                    <Option value="ผศ.ดร.">ผศ.ดร.</Option>
                    <Option value="ผศ">ผศ.</Option>
                    <Option value="ดร.">ดร.</Option>
                    <Option value="อ.">อ.</Option>
                    <Option value="นาย">นาย</Option>
                    <Option value="นาง">นาง</Option>
                    <Option value="นางสาว">นางสาว</Option>

                  </Select>
                  <Input
                    style={{ width: 200 }}
                    id="addAgency"
                    placeholder="ชื่อ"
                    maxLength={50}
                    value={addModelPersonInTabel.firstName}
                    onChange={(e) => {
                      let { id, value } = e.target;
                      setAddModelPersonInTabel({
                        ...addModelPersonInTabel,
                        firstName: value,
                        id: null,
                        group: null,
                      });
                    }}
                  />
                  <Input
                    style={{ width: 200 }}
                    id="addAgency"
                    placeholder="นามสกุล"
                    maxLength={50}
                    value={addModelPersonInTabel.lastName}
                    onChange={(e) => {
                      let { id, value } = e.target;
                      setAddModelPersonInTabel({
                        ...addModelPersonInTabel,
                        lastName: value,
                        id: null,
                        group: null,
                      });
                    }}
                  />

                </Space>
              )
          }
          {/* setAddModelPersonInTabel({ ...default_modal_person }); */}
          <Space style={{ marginLeft: 84, marginBottom: 12 }}>
            <Checkbox checked={addModelPersonInTabel.checkNoDB}
              onChange={() => setAddModelPersonInTabel({
                ...default_modal_person,
                checkNoDB: !addModelPersonInTabel.checkNoDB
              })} >
              ไม่มีรายชื่อ-นามสกุลในฐานข้อมูล
            </Checkbox>
          </Space>
          <Space style={{ marginBottom: 12 }}>
            <p>ตำแหน่ง :</p>
            <Select
              style={{ width: 200 }}
              value={addModelPersonInTabel.positon}
              placeholder="ตำแหน่ง"
              onChange={(value) => {
                setAddModelPersonInTabel({
                  ...addModelPersonInTabel,
                  positon: value,
                });
              }}
            >
              <Option key={`หัวหน้าวิจัย`}>หัวหน้าวิจัย</Option>
              <Option key={`ผู้ร่วมวิจัย`}>ผู้ร่วมวิจัย</Option>

              {/* <Option key={`กรรมการ`}>กรรมการ</Option> */}
            </Select>

            <p>สัดส่วน :</p>
            <InputNumber
              style={{ width: 100 }}
              value={addModelPersonInTabel.percent}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(value) => {
                setAddModelPersonInTabel({
                  ...addModelPersonInTabel,
                  percent: value >= 0 && value <= 100 ? value : 0,
                });
              }}
            />

          </Space>
        </div>

      </Modal>

      {/*  Confirm */}
      <Modal
        title={null}
        centered
        width={500}
        okText={"บันทึก"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "green" } }}
        open={ModalMessage.show}
        onOk={() => {
          onFinish((_id) => {
            history.push("/research/detail/" + _id);
          });
          setModalMessage({ ...ModalMessage, show: false, title: "" });
        }}
        onCancel={() => {
          setModalMessage({ ...ModalMessage, show: false, title: "" });
        }}
      >
        <div
          style={{ width: "100%", flex: 1, display: "flex", marginBottom: 12 }}
        >
          <p className="text-2xl">คุณต้องการบันทึกข้อมูลหรือไม่</p>
        </div>

      </Modal>

      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          marginTop: 24,
          padding: "18px",
          display: "flex",
          width: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            วิจัย
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            แบบฟอร์มโครงการวิจัย
          </Breadcrumb.Item>
        </Breadcrumb>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
          }}
        >
          <Text style={{ fontSize: 24, fontWeight: "normal" }}>
            แบบฟอร์มโครงการวิจัย
          </Text>
          <Divider />
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>*ปีงบประมาณ :</Text>
              <Select
                style={{ width: 250 }}
                value={form.year}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "year")}
              >
                <Option value="2566">2566</Option>
                <Option value="2565">2565</Option>
                <Option value="2564">2564</Option>
                <Option value="2563">2563</Option>
                <Option value="2562">2562</Option>
              </Select>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>*แหล่งเงินงบประมาณ :</Text>
              <Select
                style={{ width: 250 }}
                value={form.budget_source}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "budget_source")}
              >
                <Option value="งบประมาณรายได้">งบประมาณรายได้</Option>
                <Option value="งบประมาณแผ่นดิน">งบประมาณแผ่นดิน</Option>
                <Option value="งบประมาณภายนอก">งบประมาณภายนอก</Option>
                <Option value="งบประมาณส่วนตัว">งบประมาณส่วนตัว</Option>
                <Option value="งบประมาณส่วนตัว">งบประมาณรายได้คณะพยาบาลศาสตร</Option>
                <Option value="งบประมาณส่วนตัว">งบปรพมาณรายได้มหาวิทยาลัยนเรศวร</Option>
              </Select>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>*รหัสโครงการ :</Text>
              <Input
                value={form.project_code}
                placeholder=""
                id="project_code"
                maxLength={50}
                style={{ width: 250 }}
                onChange={onChangeTextInput}
              />
            </div>
          </Row>

          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 8,
                flex: 1,
              }}
            >
              <Text>*ชื่อโครงการ (ไทย) :</Text>
              <Input
                placeholder=""
                id="project_name_th"
                value={form.project_name_th}
                maxLength={200}
                style={{ width: "80%" }}
                onChange={onChangeTextInput}
              />
            </div>
          </Row>

          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 8,
                flex: 1,
              }}
            >
              <Text>ชื่อโครงการ (Eng) :</Text>
              <Input
                placeholder=""
                id="project_name_en"
                value={form.project_name_en}
                maxLength={200}
                style={{ width: "80%" }}
                onChange={onChangeTextInput}
              />
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>สถานะโครงการ : </Text>
              <Select
                style={{ width: 220 }}
                value={form.project_status}
                placeholder=""
                onChange={(value) => onChangeSelect(value, "project_status")}
              >
                <Option value="ส่งข้อเสนอโครงการ">ส่งข้อเสนอโครงการ</Option>
                <Option value="อยู่ระหว่างทำสัญญา">อยู่ระหว่างทำสัญญา</Option>
                <Option value="อยู่ระหว่างดำเนินการ">
                  อยู่ระหว่างดำเนินการ
                </Option>
                <Option value="ปิดโครงการ">ปิดโครงการ</Option>
                <Option value="ยุติโครงการ">ยุติโครงการ</Option>
              </Select>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Text>วันที่เริ่มต้น - วันที่สิ้นสุด</Text>
              <RangePicker
                format="YYYY-MM-DD"
                onChange={(_date, dateString) => {
                  if (Array.isArray(_date) && _date.length >= 2) {
                    _date[0].set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    });
                    _date[1].set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    });
                    _date[0].toISOString();
                    _date[1].toISOString();
                    setForm({
                      ...form,
                      end_date: Number(_date[0].format("x")),
                      start_date: Number(_date[1].format("x")),
                    });
                  } else {
                    setForm({ ...form, end_date: null, start_date: null });
                    // setDate(null)
                    // setDate2(null)
                  }
                }}
              />
            </div>

          </Row>
          {form.budget_source === "งบประมาณภายนอก" && (
            <Row>
              <div
                style={{ display: "flex", flexDirection: "column", margin: 8 }}
              >
                <Text>หน่วยงานให้ทุน :</Text>
                <Select
                  style={{ width: 400 }}
                  value={form.budget_outsource_name}
                  placeholder=""
                  onChange={(value) =>
                    onChangeSelect(value, "budget_outsource_name")
                  }
                >
                  {jointAgency.map((x) => (
                    <Option key={`${x.name}`} value={x.name}>
                      {x.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Row>
          )}
          <Divider />
          {
            form.budget_source === "งบประมาณภายนอก" && <Button onClick={showModal}>เพิ่มหน่วยงาน</Button>
          }
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
            marginTop: 32,
          }}
        >
          <Text style={{ fontSize: 24 }}>คณะผู้วิจัย</Text>
          <Divider />
          <div style={{ marginTop: 24 }}>
            <Table
              footer={() => {
                return (
                  <Button
                    onClick={showModal3}
                    style={{ backgroundColor: "green", color: "#fff" }}
                  >
                    เพิ่มรายชื่อคณะผู้วิจัย
                  </Button>
                );
              }}
              bordered
              size="middle"
              dataSource={dataTableListProfile.map((x, i) => ({
                ...x,
                key: i + 1,
                onEdit: () => {
                  setAddModelPersonInTabel({
                    name: x.name,
                    title: x.title,
                    firstName: x.firstName,
                    lastName: x.lastName,
                    id: x.id,
                    positon: x.positon,
                    percent: x.percent,
                    hour: x.hour,
                    group: x.group,
                    checkNoDB: x.checkNoDB ? true : false,
                  })
                  showModal3()
                  setEdit({
                    index: i,
                    checked: true
                  })
                },
                onRemove: () => {
                  const newDataTableListProfile = dataTableListProfile.slice();
                  newDataTableListProfile.splice(i, 1)
                  setDateTableListProfile([...newDataTableListProfile])
                },
              }))}
              columns={columnsProfile}
              line
            />
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
            marginTop: 32,
          }}
        >
          <Text style={{ fontSize: 24 }}>
            งบประมาณที่ได้รับ  :{" "}
            {
              (
                form.total_budget_received
                  ? form.total_budget_received
                  : 0
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " บาท"}
          </Text>
          <Divider />
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 8,
                flex: 1,
              }}
            >
              <Text>งบประมาณที่ได้รับ  :</Text>
              <InputNumber
                style={{ width: "100%" }}
                value={form.total_budget_received}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) =>
                  onChangeTextInputNumber(
                    value >= 0 ? value : 0,
                    "total_budget_received"
                  )
                }
              />
            </div>

          </Row>
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
            marginTop: 32,
          }}
        >
          <Text style={{ fontSize: 24 }}>ไฟล์</Text>
          <Divider />
          <Row>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Table
                footer={() => {
                  return (
                    <Button
                      onClick={() => setIsModalOpenFile(true)}
                      style={{ backgroundColor: "green", color: "#fff" }}>
                      เพิ่มไฟล์
                    </Button>
                  );
                }}
                bordered
                size="middle"
                dataSource={onGenDataTable(fileList)}
                columns={columnsFile}
                line
              />
            </div>
          </Row>
        </div>
        <div className="flex justify-end">
          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              height: 50,
              marginTop: 24,
              width: 200,
              fontSize: 24,
            }}
            size="large"
            onClick={() =>
              setModalMessage({ ...ModalMessage, show: true, title: "xxxxx2" })
            }
          >
            บันทึกข้อมูล
          </Button>
        </div>
      </div>
      <div className="h-[300px]"></div>
    </Layout>
  );
}
