import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Table,
  Typography,
  Row,
  Space,
  Button,
  Breadcrumb,
  Modal
} from "antd";
import api from "../../util/api";

import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const columnsProfile = [
  {
    title: "No.",
    dataIndex: "key",
    key: "key",
    align: "center",
    width: 10,
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "จำนวนชั่วโมงที่ให้บริการ",
    align: "center",
    dataIndex: "hour",
    key: "hour",
    width: 80,
  },
];


const MAP_GROUP = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
};

const MAP_BUDGET = {
  income_budget: "งบประมาณรายได้",
  national_budget: "งบประมาณแผ่นดิน",
  external_budget: "งบประมาณภายนอก",
  personal_budget: "งบประมาณส่วนตัว",
};

let DateShow = ({ start_date, end_date }) => {
  if (start_date && end_date) {
    return <>
      {
        `${moment(start_date, "x").format(
          "DD/MM/YYYY"
        )} - ${moment(end_date, "x").format("DD/MM/YYYY")}`
      }
    </>
  } else {
    return <>-</>
  }
}

const fetchData = async (id) => {
  try {
    let aggregate = [
      {
        $match: { _id: `${id}` },
      },
    ];

    let _data = await api.query("db/nunurse/form_academic_service/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const { Title, Text } = Typography;

export default function Home(props) {
  let history = useHistory();

  let id = props?.match?.params?.id;
  let [data, setData] = useState(null);
  let [isLogin, setLogin] = useState(false);
  let [isEdit, setEdit] = useState(false);
  let [isDel, setDel] = useState(false);

  let [form, setForm] = useState({
    _id: null,
    year: null,
    list_person: [],
    group: null,
    location: null,
    location_type: null,
    service_type: null,
    start_date: null,
    end_date: null,
    topic: null,
    create_date: null,
    times: null,
    timestamp: null,
    create_by: null,
  });

  let [dataTable, setDateTable] = useState([]);

  const [ModalMessage, setModalMessage] = useState({
    show: false,
    title: "",
    message: "",
  });

  React.useEffect(async () => {
    let user_id = localStorage.getItem("user");
    let user = localStorage.getItem("user_nurse");
    let userdata = localStorage.getItem("user_data");
    let role = localStorage.getItem("role");
    let _data = await fetchData(id);
    if (_data.length > 0) {
      setForm(_data[0]);
      if (user && userdata) {
        let item = _data[0].list_person.find(x => x.id === user_id)
        if (item) setEdit(true)
        if (role === '["admin"]') {
          setEdit(true)
          setDel(true)
        }
        setLogin(true);
      }
    }
  }, []);


  return (
    <Layout>
      <Modal
        title={null}
        centered
        width={400}
        okText={"ลบ"}
        cancelText={"ปิด"}
        okButtonProps={{ style: { background: "red" } }}
        open={ModalMessage.show}
        onOk={async () => {
          let _form = { ...form }
          _form['del'] = true;
          await api.insert(
            `db/nunurse/form_academic_service/data/${_form._id}`,
            _form
          );
          setModalMessage({ ...ModalMessage, show: false, title: "" });
          history.goBack()
        }}
        onCancel={() => {
          setModalMessage({ ...ModalMessage, show: false, title: "" });
        }}
      >
        <div
          style={{ width: "100%", flex: 1, display: "flex", marginBottom: 12 }}
        >
          <p className="text-2xl">คุณต้องการลบข้อมูลนี้หรือไม่</p>
        </div>
      </Modal>
      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          width: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            การบริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            ตารางแสดงการให้บริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            {form.topic}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
          }}
        >
          <div className="flex-row flex justify-between mb-4">
            {/* space-between */}
            <p class="text-center text-2xl">{form.topic}</p>
            <div class="flex flex-row">
              {
                isDel &&
                <div>

                  <Button
                    shape="round"
                    style={{ backgroundColor: "#ff4030", color: "white", marginRight: "10px" }}
                    // icon={<TableOutlined />}
                    onClick={() => {
                      setModalMessage({ ...ModalMessage, show: true, title: "" });
                    }}
                    size="large"
                  >
                    ลบ
                  </Button>
                </div>
              }

              {
                isEdit &&
                <div>
                  <Button
                    shape="round"
                    style={{ backgroundColor: "#1677FF", color: "white" }}
                    // icon={<TableOutlined />}
                    onClick={() => history.push(`/AcademicService/edit/${form._id}`)}
                    size="large"
                  >
                    แก้ไข
                  </Button>
                </div>
              }
            </div>
          </div>
          <Divider />
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ปีงบประมาณ :</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {form?.year}
                </Text>
              </Space>
            </div>
          </Row>
          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ชื่อหน่วยงานที่จัด :</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {form?.location}
                </Text>
              </Space>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ประเภทหน่วยงานที่จัด :</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {form?.location_type}
                </Text>
              </Space>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>ประเภทของผู้ให้บริการ :</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {form?.service_type}
                </Text>
              </Space>
            </div>
          </Row>

          <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>วันเวลาที่เชิญ : </Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  <DateShow
                    start_date={form?.start_date}
                    end_date={form?.end_date}
                  />
                </Text>
              </Space>
            </div>
          </Row>

          {/* <Row>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <Space>
                <Text style={{ fontSize: 16 }}>หัวข้อเรื่อง :</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {form?.topic}
                </Text>
              </Space>
            </div>
          </Row> */}

          <div style={{ marginTop: 24 }}>
            <Text style={{ fontSize: 18 }}>
              ตารางรายชื่อผู้ให้บริการวิชาการ
            </Text>
            <Table
              bordered
              size="middle"
              dataSource={form?.list_person.map((x, i) => ({
                ...x,
                key: i + 1,
              }))}
              columns={columnsProfile}
              line
            />
            <Divider />
          </div>
        </div>
      </div>
    </Layout>
  );
}
