import * as React from "react";
// import Logo from '../images/logo/iseelogosmall.png'
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Logo from "../images/logo-nurse.webp";
import {
  LogoutOutlined,
  DownOutlined,
  HomeFilled,
  ProfileFilled,
  UserOutlined
} from "@ant-design/icons";
import { Menu, Button, Popover, Divider, Avatar, Space } from "antd";
const ROLE_MAP = {
  SHPH: "รพสต",
  doctor: "โรงพยาบาลแม่สอด",
  patient: "ผู้ป่วย",
  admin: "ผู้ดูแลระบบ",
  // โรงพยาบาล
};

const menu = (
  <Menu>
    {/* <Menu.ItemGroup title="Group title"> */}
    <Menu.Item>
      <a
        style={{ alignSelf: "center", marginLeft: 12 }}
        href="/login"
        onClick={() => {
          localStorage.removeItem("user_data");
          localStorage.removeItem("user_wecaremaesot");
          localStorage.removeItem("img_profile");
          localStorage.removeItem("user");
          localStorage.removeItem("userdata");
          localStorage.removeItem("key");
          localStorage.removeItem("role");
        }}
      >
        ออกจากระบบ
      </a>
    </Menu.Item>

    <a href="https://ant.design" target="_blank" rel="noopener noreferrer"></a>
  </Menu>
);

export default function Header({ isScreenSizeGL, closeD }) {
  let history = useHistory();
  const [isLogin, setLogin] = React.useState(false);
  const [img_profile, setImageProfile] = React.useState(null);
  const [user_info, setUserInfo] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [Porfile, setPorfile] = React.useState("-");
  const [roleUser, setRoleUser] = React.useState(null);
  React.useEffect(() => {
    let user = localStorage.getItem("user_nurse");
    let userdata = localStorage.getItem("user_data");
    let role = localStorage.getItem("role");

    if (user && userdata) {
      setRoleUser(role)
      setLogin(true);
      let _data = JSON.parse(userdata);
      let _role = JSON.parse(role);
      setUserInfo({
        user: user,
        name: _data.firstname + " " + _data.lastname,
        key_role: _role[0],
        role: _data.hospital,
        rolename: ROLE_MAP[_role[0]],
      });
    }
    if (window?.location?.pathname) {
    }
  }, []);


  return (
    <div
      style={{
        height: 140,
        width: "100%",
        alignItems: "center",
        backgroundColor: "#5A5A5A",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        backdropFilter: "blur(8px)",
        display: "flex",
        flexDirection: "column",
        // justifyContent: 'center'
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          height: 110,
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <div className="mt-2 mb-2">
          <a href="/main">
            <img
              alt={`description`}
              src={Logo}
              width="90px"
              height="90px"
            ></img>
          </a>
        </div>
        <div className="flex flex-col ml-2 mt-4">
          <p className="text-white text-3xl">
            คณะพยาบาลศาสตร์ มหาวิทยาลัยนเรศวร
          </p>
          <p className="text-white text-xl">
            Faculty of Nursing , Naresuan University
          </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: 50,
          backgroundColor: "#fff",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", width: 1300 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              height: 48,
              backgroundColor: "#fff",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="link"
                size={"large"}
                style={{ fontSize: 20 }}
                onClick={() => history.push("/")}
              >
                <div className="flex flex-row items-center">
                  <HomeFilled style={{ fontSize: 18, marginRight: 4 }} />
                  <p style={{ fontSize: 20, color: "black" }}>หน้าหลัก</p>
                </div>
              </Button>

              <Button
                type="link"
                size={"large"}
                style={{ fontSize: 20 }}
                onClick={() => history.push("/profilePosition")}
              >
                <div className="flex flex-row items-center">
                  <ProfileFilled style={{ fontSize: 18, marginRight: 4 }} />
                  <p style={{ fontSize: 20, color: "black" }}>บุคลากร</p>
                </div>
              </Button>

              <Popover
                content={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      type="link"
                      style={{ color: "black" }}
                      size={"large"}
                      onClick={() => history.push("/research/yearbang/2566")}
                    >
                      โครงการวิจัย
                    </Button>
                    <Divider style={{ margin: 0 }} />
                    <Button
                      type="link"
                      style={{ color: "black" }}
                      size={"large"}
                      onClick={() =>
                        history.push("/ResearchResults/yearbang/2566")
                      }
                    >
                      ผลงานวิจัย/ตีพิมพ์/นำเสนอ
                      {/* <Link href="/research_results/yearbang/2566" style={{ fontSize: 20, margin: 4, color: 'black' }} >ผลงานวิจัย/ตีพิมพ์/นำเสนอ</Link> */}
                    </Button>
                  </div>
                }
                placement="bottom"
                title={null}
              >
                <Button type="link" size={"large"} style={{ fontSize: 20 }}>
                  <div className="flex flex-row items-center">
                    <p style={{ fontSize: 20, color: "black" }}>วิจัย</p>
                    <DownOutlined
                      style={{ fontSize: 18, marginLeft: 4, color: "black" }}
                    />
                  </div>
                </Button>
              </Popover>

              <Popover
                content={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      type="link"
                      size={"large"}
                      style={{ color: "black" }}
                      onClick={() =>
                        history.push("/AcademicServiceProject/yearbang/2566")
                      }
                    >
                      โครงการบริการวิชาการ
                      {/* <Link href="/research_results/yearbang/2566" style={{ fontSize: 20, margin: 4, color: 'black' }} >ผลงานวิจัย/ตีพิมพ์/นำเสนอ</Link> */}
                    </Button>

                    <Divider style={{ margin: 0 }} />

                    <Button
                      type="link"
                      size={"large"}
                      style={{ color: "black" }}
                      onClick={() =>
                        history.push("/AcademicService/yearbang/2566")
                      }
                    >
                      การให้บริการวิชาการ
                      {/* <Link href="/research_results/yearbang/2566" style={{ fontSize: 20, margin: 4, color: 'black' }} >ผลงานวิจัย/ตีพิมพ์/นำเสนอ</Link> */}
                    </Button>
                  </div>
                }
                placement="bottom"
                title={null}
              >
                <Button type="link" size={"large"} style={{ fontSize: 20 }}>
                  <div className="flex flex-row items-center">
                    <p style={{ fontSize: 20, color: "black" }}>
                      การบริการวิชาการ
                    </p>
                    <DownOutlined
                      style={{ fontSize: 18, marginLeft: 4, color: "black" }}
                    />
                  </div>
                </Button>
              </Popover>
            </div>
            <div style={{ flex: 1 }}>{user}</div>
            {
              isLogin ?
                <Popover
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {
                        roleUser === '["doctor"]' &&
                        <Button
                          type="link"
                          size={"large"}
                          style={{ color: "black" }}
                          onClick={() =>
                            history.push("/profileDeatil")
                          }
                        >
                          ข้อมูลส่วนตัว
                        </Button>

                      }
                      {
                        roleUser === '["admin"]' &&
                        <Button
                          type="link"
                          size={"large"}
                          style={{ color: "black" }}
                          onClick={() =>
                            history.push("/manageUser")
                          }
                        >
                          การจัดการผู้ใช้งาน
                        </Button>

                      }
                      <Divider style={{ margin: 0 }} />
                      {
                        roleUser === '["admin"]' &&
                        <Button
                          type="link"
                          size={"large"}
                          style={{ color: "black" }}
                          onClick={() =>
                            history.push("/managePosts")
                          }
                        >
                          การจัดข่าวประชาสัมพันธ์
                        </Button>

                      }
                      <Divider style={{ margin: 0 }} />

                      <Button
                        type="link"
                        size={"large"}
                        style={{ color: "black" }}
                        onClick={() => {
                          localStorage.removeItem('user_data')
                          localStorage.removeItem('img_profile')
                          localStorage.removeItem('user')
                          localStorage.removeItem('userdata')
                          localStorage.removeItem('key')
                          localStorage.removeItem('role')
                          history.push('/login')
                        }}
                      >
                        ออกจากระบบ
                        {/* <Link href="/research_results/yearbang/2566" style={{ fontSize: 20, margin: 4, color: 'black' }} >ผลงานวิจัย/ตีพิมพ์/นำเสนอ</Link> */}
                      </Button>
                    </div>
                  }
                  placement="bottom"
                  title={null}
                >
                  <Button type="link" size={"large"} style={{ fontSize: 20 }}>
                    <div className="flex flex-row items-center">
                      <UserOutlined
                        style={{ fontSize: 12, marginLeft: 4, color: "black" }}
                      />
                      <p style={{ fontSize: 16, color: "black", marginLeft: 4 }}>
                        {user_info.name}
                      </p>
                    </div>
                  </Button>
                </Popover>
                :
                <Button type="link" size={"large"} style={{ fontSize: 20 }}
                  onClick={() =>
                    history.push("/login")
                  }
                >
                  <div className="flex flex-row items-center">
                    <LogoutOutlined
                      style={{ fontSize: 12, marginLeft: 4, color: "black" }}
                    />
                    <p style={{ fontSize: 16, color: "black", marginLeft: 4 }}>
                      เข้าสู่ระบบ
                    </p>
                  </div>
                </Button>
            }
          </div>
        </div>
      </div>

      {/*  */}
    </div>
  );
}
