import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import './index.css';
import Root from './pages/root'
import ProfilePosition from './pages/profilePosition'
import profilePositionTable from './pages/profilePositionTable'
import profilePositionDeatil from './pages/profilePositionDeatil'

import Research from './pages/research'
import ResearchTable from './pages/researchTable'
import ResearchDetail from './pages/researchDetail'
import ResearchCreate from './pages/researchCreate'

import ResearchResultsCreate from './pages/researchResultsCreate'
import ResearchResults from './pages/researchResults'
import ResearchResultsTable from './pages/researchResultsTable'
import ResearchResultsDetail from './pages/researchResultsDetail'

import AcademicServiceProjectCreate from './pages/academicServiceProjectCreate'
import AcademicServiceProjectDetail from './pages/academicServiceProjectDetail'
import AcademicServiceProjectTable from './pages/academicServiceProjectTable'
import AcademicServiceProject from './pages/academicServiceProject'

import AcademicServiceCreate from './pages/academicServiceCreate'
import AcademicServiceDetail from './pages/academicServiceDetail'
import AcademicServiceTable from './pages/academicServiceTable'
import AcademicService from './pages/academicService'

import Login from './pages/login'

import ProfileDeatil from './pages/profileDeatil'
import ManageUser from './pages/manageUser'
import PostNews from './pages/postNews'
import ManagePosts from './pages/managePosts'


import {
  createTheme,
  ThemeProvider
} from '@mui/material/styles';

export default function App() {
  let theme = createTheme({
    typography: {
      'fontFamily': `'Kanit', sans-serif`,
    },
    palette: {
      primary: {
        main: '#048967',
      },
      secondary: {
        main: '#E8FDF0',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Switch>

            <Route path="/manageUser">
              <ManageUser />
            </Route>

            <Route path="/postNews">
              <PostNews />
            </Route>
            
            <Route path="/managePosts">
              <ManagePosts />
            </Route>

            <Route path="/AcademicService/create" component={AcademicServiceCreate} />
            <Route path="/AcademicService/edit/:id" component={AcademicServiceCreate} />
            <Route path="/AcademicService/detail/:id" component={AcademicServiceDetail} />
            <Route path="/AcademicService/table/:group/:type/:year" component={AcademicServiceTable} />
            <Route path="/AcademicService/:typeyear/:year" component={AcademicService} />

            <Route path="/AcademicServiceProject/create" component={AcademicServiceProjectCreate} />
            <Route path="/AcademicServiceProject/edit/:id" component={AcademicServiceProjectCreate} />
            <Route path="/AcademicServiceProject/detail/:id" component={AcademicServiceProjectDetail} />
            <Route path="/AcademicServiceProject/table/:group/:budgetsource/:year" component={AcademicServiceProjectTable} />
            <Route path="/AcademicServiceProject/:typeyear/:year" component={AcademicServiceProject} />


            <Route path="/ResearchResults/create" component={ResearchResultsCreate} />
            <Route path="/ResearchResults/edit/:id" component={ResearchResultsCreate} />
            <Route path="/ResearchResults/detail/:id" component={ResearchResultsDetail} />
            <Route path="/ResearchResults/table/:group/:budgetsource/:year" component={ResearchResultsTable} />
            <Route path="/ResearchResults/:typeyear/:year" component={ResearchResults} />

            <Route path="/research/create" component={ResearchCreate} />
            <Route path="/research/edit/:id" component={ResearchCreate} />
            <Route path="/research/detail/:id" component={ResearchDetail} />
            <Route path="/research/table/:group/:budgetsource/:year" component={ResearchTable} />
            <Route path="/research/:typeyear/:year" component={Research} />


            <Route path="/profileDeatil" component={ProfileDeatil} />

            <Route path="/profilePosition/detail/:pid" component={profilePositionDeatil} />
            <Route path="/profilePosition/table/:group/:position" component={profilePositionTable} />

            <Route path="/login" component={Login} />

            <Route path="/profilePosition">
              <ProfilePosition />
            </Route>



            <Route path="/">
              <Root />
            </Route>

          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}
