import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Progress,
  Space,
  Button,
  Breadcrumb
} from "antd";
import api from "../../util/api";
import Box from "@mui/material/Box";
import "moment/locale/th";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const MAP_GROUP = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
};

const MAP_BUDGET = {
  income_budget: "งบประมาณรายได้",
  national_budget: "งบประมาณแผ่นดิน",
  external_budget: "งบประมาณภายนอก",
  personal_budget: "งบประมาณส่วนตัว",
};

const rederColRight = (key) => {
  return (_, data) => (
    <Space size="middle">
      <a href={`/AcademicServiceProject/detail/${data._id}`}>{data[key]}</a>
    </Space>
  );
};

const rederColRightProfile = (key) => {
  return (_, data) => (
    <Space size="middle">
      <a href={`/profilePosition/detail/${data.name_link}`}>{data[key]}</a>
    </Space>
  );
};

const fetchData = async () => {
  try {
    let aggregate = [
      {
        $match: { del: { $ne: true } }
      }
    ];
    let _data = await api.query("db/nunurse/form_academic_service_project/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const onCreateDataTable = (data = [], year) => {
  return data.map((x, i) => {
    let name = "";
    let name_link = "";

    let item = x?.list_person?.find(
      (x) => x.positon === "หัวหน้าวิจัย" || x.positon === "ประธาน" || "ประธาน/ผู้รับผิดชอบโครงการ"
    );
    if (item) {
      name = `${item.title}${item.firstName} ${item.lastName}`;
      name_link = `${item.id}`;
    }

    return {
      ...x,
      key: 1 + i,
      nameHead: name,
      name_link: name_link
    };
  });
};

export default function Home(props) {
  let history = useHistory();

  let year = props?.match?.params?.year;
  let group = props?.match?.params?.group;
  let budgetsource = props?.match?.params?.budgetsource;

  let [data, setData] = useState([]);
  let [selectYear, setSelectYear] = useState(year);
  let [selectGroup, setSelectGroup] = useState(group);
  let [selectBudget, setSelectBudget] = useState(budgetsource);
  let [dateTable, setDateTable] = useState();


  React.useEffect(() => {
    let functionfilter = () => { };
    if (selectGroup === "all" && selectBudget === "all") {
      functionfilter = (item) => item.year === selectYear;
    } else if (selectBudget === "all") {
      functionfilter = (item) =>
        item.year === selectYear && item.group === MAP_GROUP[selectGroup];
    } else if (selectGroup === "all") {
      functionfilter = (item) =>
        item.year === selectYear &&
        item.budget_source === MAP_BUDGET[selectBudget];
    } else {
      functionfilter = (item) =>
        item.year === selectYear &&
        item.budget_source === MAP_BUDGET[selectBudget] &&
        item.group === MAP_GROUP[selectGroup];
    }
    console.log("data", data);
    console.log("functionfilter", functionfilter);
    let _data = data.filter(functionfilter);
    console.log("_data", _data);

    setDateTable(onCreateDataTable(_data, selectYear));
  }, [data, selectYear, selectBudget, selectGroup]);

  React.useEffect(() => {
    fetchData().then((res) => {
      setData(res);
    });
  }, []);




  return (
    <Layout>
      <div
        style={{
          borderRadius: 12,
          padding: 12,
          display: "flex",
          maxWidth: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>การบริการวิชาการ</Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>ตารางแสดงโครงการบริการวิชาการ</Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 12,
            display: "flex",
            width: "1350px",
            minHeight: "600px",
          }}
        >
          {/* detail */}

          <div
            style={{ flexDirection: "column", display: "flex", width: "100%" }}
          >
            <div className="flex-row flex justify-between ">
              {/* space-between */}
              <p class="text-center text-3xl"> ตารางแสดงโครงการบริการวิชาการ</p>
            </div>

            <Space wrap style={{ marginTop: 16, marginBottom: 16 }}>
              <Select
                value={selectGroup}
                style={{ width: 300 }}
                options={[
                  { value: "all", label: "คณะพยาบาลศาสตร์" },
                  {
                    value: "mother_and_infant",
                    label: "กลุ่มวิชาการพยาบาลมารดาและทารก",
                  },
                  { value: "child", label: "กลุ่มวิชาการพยาบาลเด็ก" },
                  { value: "adult", label: "กลุ่มวิชาการพยาบาลผู้ใหญ่" },
                  {
                    value: "geriatric",
                    label: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
                  },
                  { value: "community", label: "กลุ่มวิชาการพยาบาลชุมชน" },
                  {
                    value: "psychiatric",
                    label: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
                  },
                  {
                    value: "administartion_professional",
                    label: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
                  },
                ]}
                onChange={(value) => {
                  setSelectGroup(value);
                }}
              />
              <Select
                style={{ width: 200 }}
                value={selectBudget}
                options={[
                  { value: "all", label: "งบประมาณทั้งหมด" },
                  { label: "งบประมาณรายได้", value: "income_budget" },
                  { label: "งบประมาณแผ่นดิน", value: "national_budget" },
                  { label: "งบประมาณภายนอก", value: "external_budget" },
                  { label: "งบประมาณส่วนตัว", value: "personal_budg" },
                ]}
                onChange={(value) => {
                  setSelectBudget(value);
                }}
              />

              {/* <Select
                style={{ width: 120 }}
                defaultValue={"ปีงบประมาณ"}
                options={[
                  { label: "ปีงบประมาณ", value: "ปีงบประมาณ" },
                  { label: "ปีปฏิทิน", value: "ปีปฏิทิน" },
                ]}
              /> */}
              <Select
                style={{ width: 80 }}
                value={selectYear}
                options={[
                  { label: "2566", value: "2566" },
                  { label: "2565", value: "2565" },
                  { label: "2564", value: "2564" },
                  { label: "2563", value: "2563" },
                  { label: "2562", value: "2562" },
                ]}
                onChange={(value) => {
                  setSelectYear(value);
                }}
              />
            </Space>

            {/* ตำแหน่งทางวิชาการ */}
            <div className="flex flex-row ">
              <div class="w-full ... ">
                <Table
                  style={{ fontFamily: "Kanit" }}
                  pagination={false}
                  bordered
                  size="middle"
                  dataSource={dateTable}
                  columns={[
                    {
                      title: "ลำดับ",
                      dataIndex: "key",
                      key: "key",
                      onHeaderCell: (column) => {
                        console.log("column", column);
                        return "1";
                      },
                      width: 20,
                    },
                    {
                      title: "ชื่อโครงการบริการวิชาการ",
                      render: rederColRight("project_name_th"),
                      width: 200,
                    },
                    {
                      title: "ประธาน/ผู้รับผิดชอบโครงการ",
                      render: rederColRightProfile("nameHead"),
                      // dataIndex: "nameHead",
                      // key: "nameHead",
                      width: 80,
                    },
                    {
                      title: "งบประมาณ",
                      dataIndex: "total_budget_received",
                      key: "total_budget_received",
                      width: 60,
                    },
                    {
                      title: "แหล่งทุน",
                      dataIndex: "budget_source",
                      key: "budget_source",
                      width: 100,
                    },
                  ]}
                  line
                />
                {/* data.table1 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
