import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { Divider } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  Row,
  Col,
  Progress,
  Space,
  Button,
  Checkbox,
  Breadcrumb
} from "antd";
import AddressData from "../../util/AddressData";
import axios from "axios";
import api from "../../util/api";
import Box from "@mui/material/Box";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import locale from "antd/es/date-picker/locale/th_TH";
import "moment/locale/th";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const MAP = {
  journal: "วารสาร (Journal)",
  proceeding: "ผลงานที่นำเสนอในที่ประชุมวิชาการ (Proceeding)",
  thai: "ระดับชาติ",
  international: "ระดับนานาชาติ",
};

const columnsProfile = [
  {
    title: "No.",
    dataIndex: "key",
    key: "key",
    align: "center",
    width: 10,
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "ตำแหน่ง",
    align: "center",
    dataIndex: "author",
    key: "author",
    width: 80,
  },
  // {
  //   title: "สัดส่วน",
  //   align: "center",
  //   dataIndex: "percent",
  //   key: "percent",
  //   width: 60,
  // },
];

const columnsFile = [
  {
    title: "No.",
    dataIndex: "key",
    key: "key",
    width: 20,
  },
  {
    title: "ชื่อไฟล์",
    // render: rederColRight('project_name_th'),
    width: 300,
  },
  {
    title: "โหลด",
    align: "center",
    dataIndex: "nameHead",
    key: "nameHead",
    width: 40,
  },
];

const MAP_GROUP = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
};

const MAP_BUDGET = {
  income_budget: "งบประมาณรายได้",
  national_budget: "งบประมาณแผ่นดิน",
  external_budget: "งบประมาณภายนอก",
  personal_budget: "งบประมาณส่วนตัว",
};

const rederColRight = (key) => {
  return (_, data) => (
    <Space size="middle">
      <a href={`/research/detail/${data._id}`}>{data[key]}</a>
    </Space>
  );
};

const fetchData = async (id) => {
  try {
    let aggregate = [
      {
        $match: { _id: `${id}` },
      },
    ];

    let _data = await api.query("db/nunurse/form_research_result/query", {
      aggregate: aggregate,
    });

    return _data;
  } catch (err) {
    console.log("err", err);
  }
};

const { Title, Text } = Typography;


export default function Home(props) {
  let history = useHistory();

  let id = props?.match?.params?.id;
  let [data, setData] = useState(null);

  let [form, setForm] = useState(null);

  let [dataTable, setDateTable] = useState([]);
  let [isLogin, setLogin] = useState(false);
  let [isEdit, setEdit] = useState(false);
  let [isDel, setDel] = useState(false);



  React.useEffect(async () => {

    let user_id = localStorage.getItem("user");
    let user = localStorage.getItem("user_nurse");
    let userdata = localStorage.getItem("user_data");
    let role = localStorage.getItem("role");

    let _data = await fetchData(id);

    if (_data.length > 0) {
      setForm(_data[0]);
      if (user && userdata) {
        let item = _data[0].list_person.find(x => x.id === user_id)
        if (item) setEdit(true)
        if (role === '["admin"]') {
          setEdit(true)
          setDel(true)
        }
        setLogin(true);
      }
    }


  }, []);

  console.log("form", form);

  return (
    <Layout>
      <div
        style={{
          // background: "#fff",
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          width: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
            marginTop: 40,
          }}
        >
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            การบริการวิชาการ
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            [แบบฟอร์มผลงานวิจัย/ตีพิมพ์/นำเสน]
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ fontSize: "18px" }}>
            {form?.journal_title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            backgroundColor: "#fff",
            padding: 24,
            borderRadius: 12,
          }}
        >
          <div className="flex-row flex justify-between mb-4">
            {/* space-between */}
            <p class="text-center text-2xl">{form?.journal_title}</p>

            {
              isEdit &&
              <div>
                <Button
                  shape="round"
                  style={{ backgroundColor: "#1677FF", color: "white" }}
                  // icon={<TableOutlined />}
                  onClick={() => history.push(`/ResearchResults/edit/${form._id}`)}
                  size="large"
                >
                  แก้ไข
                </Button>
              </div>
            }


          </div>

          {/* <Text style={{ fontSize: 24 }}>แบบฟอร์มผลงานวิจัย/ตีพิมพ์/นำเสนอ</Text> */}
          <Divider />
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 8 }}
          >
            <Row>
              <Space>
                <Text style={{ fontSize: 16 }}>ประเภทผลงาน :</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {MAP[form?.research_type] ? MAP[form?.research_type] : ""}
                </Text>
              </Space>
            </Row>
          </div>
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>ระดับ :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {MAP[form?.research_class] ? MAP[form?.research_class] : ""}
              </Text>
            </Space>
          </Row>

          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>กลุ่มวิชาการ :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {form?.group}
              </Text>
            </Space>
          </Row>

          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>ชื่อผลงาน (Title) :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {form?.journal_title}
              </Text>
            </Space>
          </Row>
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>
                ปีที่ตีพิมพ์ (Publication Date) :
              </Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {form?.year}
              </Text>
            </Space>
          </Row>
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}>
                ชื่อวารสาร/ชื่อการประชุมวิชาการ :
              </Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {form?.journal_name}
              </Text>
            </Space>
          </Row>
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}> ฉบับที่ (Issue Number) :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {form?.issue_number}
              </Text>
            </Space>
          </Row>
          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}> หน้าที่ตีพิมพ์ (Pages) :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {form?.pages}
              </Text>
            </Space>
          </Row>

          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}> ฐานข้อมูล :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>{form?.db}</Text>
            </Space>
          </Row>

          <Row>
            <Space>
              <Text style={{ fontSize: 16 }}> Url :</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                {form?.url && <a href={form?.url}> Link</a>}
              </Text>
            </Space>
          </Row>

          <Row>
            <Space>
              <Checkbox style={{ fontSize: 16 }} checked={form?.isStudentThesis}>
                เป็นผลงานวิทยานิพนธ์ของนิสิต
              </Checkbox>
            </Space>
          </Row>

          <div style={{ marginTop: 24 }}>
            <Text style={{ fontSize: 18 }}>ตารางรายชื่อชื่อผู้แต่ง </Text>
            <Table
              bordered
              size="middle"
              dataSource={
                Array.isArray(form?.list_person)
                  ? form.list_person.map((x, i) => ({
                    ...x,
                    key: i + 1,
                  }))
                  : []
              }
              columns={columnsProfile}
              line
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
