import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from '../../components/Layout';
import { Divider } from "@mui/material";
import { Select, Table, Typography, Row, Col, Progress, Space } from 'antd'
import AddressData from "../../util/AddressData";
import axios from "axios";
import api from "../../util/api";
import Box from '@mui/material/Box';


import moment from 'moment';
import locale from 'antd/es/date-picker/locale/th_TH';
import 'moment/locale/th';


const { Title, Text } = Typography;


const MAP_GROUP = {
  mother_and_infant: "กลุ่มวิชาการพยาบาลมารดาและทารก",
  child: "กลุ่มวิชาการพยาบาลเด็ก",
  adult: "กลุ่มวิชาการพยาบาลผู้ใหญ่",
  geriatric: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
  community: "กลุ่มวิชาการพยาบาลชุมชน",
  psychiatric: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
  administartion_professional: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
}

const MAP_NAME = {
  "lecturer": 'อาจารย์',
  "assistant_professor": 'ผู้ช่วยศาสตราจารย์',
  "associate_professor": 'รองศาสตราจารย์',
  "professor": 'ศาสตราจารย์',
  // "assistant_professor_special": 'ผู้ช่วยศาสตราจารย์(พิเศษ)',
  // "associate_professor_special": 'รองศาสตราจารย์(พิเศษ)',
  // "professor_special": 'ศาสตราจารย์(พิเศษ)',
  "government_officer": "ข้าราชการ",
  "academic": 'พนักงานมหาวิทยาลัย(สายวิชาการ)',
  "service": 'พนักงานมหาวิทยาลัย(สายบริการ)',
  "knowledgeable": 'ผู้มีความรู้ความสามารถ',
  "nu_staff": 'พนักงานมหาวิทยาลัยนเรศวร',
  "employee": 'ลูกจ้างประจำ',
  "government_employee": 'พนักงานราชการ',
}


const rederColRight = (key, history) => {
  return (_, data) => (
    <Space size="middle">
      <a onClick={() => { history.push(`/profilePosition/detail/${data._id}`) }}
      >
        {/* <Link href={`/profile_position/detail/${data._id}`} > */}
        {data[key]}
        {/* </Link> */}
      </a>
    </Space>
  )
}




const onCreateDataTable = (res, year) => {
  return res.map((x, i) => ({
    ...x,
    index: i + 1,
    // room: '-',
    name: `${x.title}${x.firstName} ${x.lastName}`
  }))
}


let fetchData = async (group, position) => {
  try {
    let aggregate = [{ $match: { "active": true } }]

    if (position !== 'all' && MAP_NAME[position]) {
      aggregate.push({ $match: { position: MAP_NAME[position] } })
    }

    if (group !== 'all' && MAP_GROUP[group]) {
      aggregate.push({ $match: { group: MAP_GROUP[group] } })
    }

    let _data = await api.query(
      'db/nunurse/profile/query',
      {
        "aggregate": aggregate
      }
    )
    return _data
  } catch (err) {

  }
}




export default function ProfilePositionTable(props) {
  let history = useHistory();
  let group = props?.match?.params?.group
  let position = props?.match?.params?.position
  let [data, setData] = useState([])
  let [dataTable, setDataTable] = useState([])
  let [selectData, setSelectData] = useState('คณะพยาบาลศาสตร์')

  let [selectYear, setSelectYear] = useState('2566');
  let [selectGroup, setSelectGroup] = useState('all');
  // let [selectBudget, setSelectBudget] = useState(budgetsource);

  React.useEffect(async () => {
    fetchData(group, position)
      .then(res => {
        setData(res)
      })
  }, []);

  React.useEffect(async () => {
    let functionfilter = (item) => item;
    if (selectGroup !== "all") {
      functionfilter = (item) => item.group === selectGroup;
    }
    let _data = data.filter(functionfilter);
    setDataTable(onCreateDataTable(_data))
  }, [data, selectGroup]);

  const columns = [
    {
      title: 'ที่',
      dataIndex: 'index',
      key: 'index',
      width: 20,
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'name',
      key: 'name',
      render: rederColRight('name', history),
      width: 100,
    },
    {
      title: 'อีเมล์',
      dataIndex: 'email',
      key: 'name',
      width: 100,
    },
    {
      title: 'ห้อง',
      dataIndex: 'room',
      key: 'room',
      width: 100,
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'tel',
      key: 'tel',
      width: 100,
    },
  ];


  return <Layout >
    <div className=" bordered-lx p-12 felx w-[1350px] self-center">
      <div
        style={{
          background: "#fff",
          borderRadius: 12,
          padding: 12,
          padding: "18px",
          display: "flex",
          width: "1350px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ flexDirection: 'column', display: 'flex', width: '100%' }}>

          <div className="flex-row flex justify-around">
          </div>
          <Text style={{ fontSize: 24, fontWeight: "normal" }}>
            ตารางแสดงรายชื่อบุคลากร
          </Text>
          <Space wrap style={{ marginTop: 16, marginBottom: 16 }}>
            <Select
              value={selectGroup}
              style={{ width: 300 }}
              options={[
                { value: "all", label: "คณะพยาบาลศาสตร์" },
                {
                  value: "กลุ่มวิชาการพยาบาลมารดาและทารก",
                  label: "กลุ่มวิชาการพยาบาลมารดาและทารก",
                },
                { value: "กลุ่มวิชาการพยาบาลเด็ก", label: "กลุ่มวิชาการพยาบาลเด็ก" },
                { value: "กลุ่มวิชาการพยาบาลผู้ใหญ่", label: "กลุ่มวิชาการพยาบาลผู้ใหญ่" },
                {
                  value: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
                  label: "กลุ่มวิชาการพยาบาลผู้สูงอายุ",
                },
                { value: "กลุ่มวิชาการพยาบาลชุมชน", label: "กลุ่มวิชาการพยาบาลชุมชน" },
                {
                  value: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
                  label: "กลุ่มวิชาการพยาบาลสุขภาพจิตและจิตเวช",
                },
                {
                  value: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
                  label: "กลุ่มวิชาการบริหารการพยาบาลและพัฒนาวิชาชีพ",
                },
              ]}
              onChange={(value) => {
                setSelectGroup(value);
              }}
            />
            {/* <Select
              style={{ width: 200 }}
              value={selectBudget}
              options={[
                { value: "all", label: "งบประมาณทั้งหมด" },
                { label: "งบประมาณรายได้", value: "income_budget" },
                { label: "งบประมาณแผ่นดิน", value: "national_budget" },
                { label: "งบประมาณภายนอก", value: "external_budget" },
                { label: "งบประมาณส่วนตัว", value: "personal_budg" },
              ]}
              onChange={(value) => {
                setSelectBudget(value);
              }}
            /> */}

            {/* <Select
                style={{ width: 120 }}
                defaultValue={"ปีงบประมาณ"}
                options={[
                  { label: "ปีงบประมาณ", value: "ปีงบประมาณ" },
                  { label: "ปีปฏิทิน", value: "ปีปฏิทิน" },
                ]}
              /> */}
            {/* <Select
              style={{ width: 80 }}
              value={selectYear}
              options={[
                { label: "2566", value: "2566" },
                { label: "2565", value: "2565" },
                { label: "2564", value: "2564" },
                { label: "2563", value: "2563" },
                { label: "2562", value: "2562" },
              ]}
              onChange={(value) => {
                setSelectYear(value);
              }}
            /> */}
          </Space>

          {/* ตำแหน่งทางวิชาการ */}
          <div className="flex flex-row ">
            <div class="w-full">
              <Table
                pagination={false}
                bordered
                size="middle"
                dataSource={dataTable}
                columns={columns}
                line
              />
              {/* data.table1 */}
            </div>

          </div>
          < Divider class="mb-4 mt-2" />
          <div className="w-full m-auto">
            <Box sx={{ width: '80%', margin: 'auto' }}>
            </Box>
          </div>

        </div>
      </div>
    </div>
  </Layout>
}
